import styled from "styled-components";

export const DescriptionContainer = styled.div`
  color: ${({ theme }) => theme.colors.blueGray};
`;

export const PatternPreviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;
  .preview-section {
    display: flex;
    flex-direction: column;
  }
`;
