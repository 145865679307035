import { isEqual } from "lodash";
import { createContext, ReactNode, useEffect, useRef, useState } from "react";

interface HistoryProviderProps {
  children: ReactNode;
}

interface HistoryContextType<T> {
  currentRevision: T;
  undo: () => T;
  redo: () => T;
  addRevision: (revision: T) => T;
  setRevisionIndex: (index: number) => T;
  isRedoAvaliable: boolean;
  isUndoAvaliable: boolean;
  clearHistory: () => void;
}

export const HistoryContext = createContext<HistoryContextType<any>>({
  currentRevision: {},
  undo: () => {},
  redo: () => {},
  addRevision: (_revision) => {},
  setRevisionIndex: (_index) => {},
  isRedoAvaliable: false,
  isUndoAvaliable: false,
  clearHistory: () => {},
});

export const HistoryProvider = <T,>({ children }: HistoryProviderProps) => {
  const [revisionHistory, setRevisionHistory] = useState<T[]>([]);
  // -1 because the first time something gets added it will increment to 1;
  const [revisionIndex, setRevisionIndex] = useState(-1);
  // Used when adding revision to prevent stale reference to the current index
  const revisonIndexRef = useRef({ currentIndex: -1 });
  useEffect(() => {
    revisonIndexRef.current.currentIndex = revisionIndex;
  }, [revisionIndex]);

  const undo = () => {
    setRevisionIndex((prevIndex) => {
      const newIndex = Math.max(0, prevIndex - 1);
      return newIndex;
    });
    return revisionHistory[Math.max(0, revisionIndex - 1)];
  };

  const redo = () => {
    setRevisionIndex((prevIndex) => {
      const newIndex = Math.min(revisionHistory.length - 1, prevIndex + 1);
      return newIndex;
    });
    return revisionHistory[Math.min(revisionHistory.length - 1, revisionIndex + 1)];
  };

  const addRevision = (revision: T | ((latestRevision: T) => T)) => {
    setRevisionHistory((prevHistory) => {
      const index = revisonIndexRef.current.currentIndex;
      const newRevision = typeof revision === "function" ? (revision as Function)(prevHistory[index]) : revision;
      if (isEqual(newRevision, prevHistory[index])) return [...prevHistory];
      const newHistory = [...prevHistory.slice(0, index + 1), newRevision];
      setRevisionIndex(newHistory.length - 1);
      return newHistory;
    });
  };

  const safelySetRevisionIndex = (index: number) => {
    const safeIndex = Math.max(0, Math.min(revisionHistory.length - 1, index));
    setRevisionIndex(safeIndex);
    return revisionHistory[safeIndex];
  };

  const clearHistory = () => {
    setRevisionHistory([]);
    setRevisionIndex(-1);
  };

  return (
    <HistoryContext.Provider
      value={{
        undo,
        isUndoAvaliable: revisionIndex > 0,
        redo,
        isRedoAvaliable: !(revisionIndex === revisionHistory.length - 1),
        addRevision,
        currentRevision: revisionHistory[revisionIndex],
        setRevisionIndex: safelySetRevisionIndex,
        clearHistory,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
