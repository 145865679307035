import { notification } from "antd";
import { EChartsInstance } from "echarts-for-react";
import { useContext, useMemo, useRef, useState } from "react";

import { FaultTimeline, TypeRange, useFaultTimelinePvinQuery, useFaultTimelineQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { ClusterModal } from "@/features/cluster/clusterModal/ClusterModal";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import { BaseEChart } from "../ui/BaseEChart";
import { buildFaultTimeLineChartConfig } from "./ChartConfigHelper";

type FaultTimeLineProps = {
  clusterId?: string;
  faultCodeId?: string;
  range: number;
  rangeUnits: "kms" | "days";
};

const defaultSelectedChartElement = { clickedFaultCode: "", clickedECU: "", clickedXAxis: 0 };

export const FaultTimeLineChart = ({
  faultCodeId,
  range,
  rangeUnits,
  clusterId: argsClusterId,
}: FaultTimeLineProps) => {
  const axisLabelRef = useRef<string>();
  const { customerIdentifier } = useCustomerIdentifier();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [selectedChartElement, setSelectedChartElement] = useState(defaultSelectedChartElement);
  const [api, context] = notification.useNotification();

  const queryParams = new URLSearchParams(window.location.search);

  const ecu = queryParams.get("ecu");
  const softwareVersion = queryParams.get("softwareVersion");
  const hardwareVersion = queryParams.get("hardwareVersion");
  const softwarePartNumber = queryParams.get("softwarePartNumber");
  const hardwarePartNumber = queryParams.get("hardwarePartNumber");
  const troubleCode = queryParams.get("troubleCode");
  const queryClusterId = queryParams.get("clusterId");
  const clusterId = argsClusterId ?? queryClusterId;

  const { data, isLoading } = useFaultTimelineQuery({
    input: {
      faultCode: faultCodeId,
      ecu,
      softwareVersion,
      softwarePartNumber,
      hardwareVersion,
      hardwarePartNumber,
      troubleCode,
      clusterId,
      range,
      typeRange: rangeUnits === "kms" ? TypeRange.Odometer : TypeRange.Time,
    },
    filter: globalFilter,
  });

  const isTimelinePvinQueryActive = !!selectedChartElement.clickedFaultCode;

  const { data: faultTimelinePvinData, isLoading: faultTimelinePvinLoading } = useFaultTimelinePvinQuery(
    {
      input: {
        faultCode: faultCodeId,
        ecu,
        softwareVersion,
        softwarePartNumber,
        hardwareVersion,
        hardwarePartNumber,
        troubleCode,
        clusterId,
        range,
        typeRange: rangeUnits === "kms" ? TypeRange.Odometer : TypeRange.Time,
        ...selectedChartElement,
      },
      filter: globalFilter,
    },
    { enabled: isTimelinePvinQueryActive }
  );

  const getLabel = (data: FaultTimeline) =>
    `${data.faultCode} + ${data.ecu} ${data.troubleCode ? `+ ${data.troubleCode}` : ""}`;

  const { yAxis, xAxis, seriesData } = useMemo(() => {
    const actualData = data?.filteredQuery.faultTimeline;
    // gets the labels for the yAxis and orders them by fault count appearance
    const yAxis = actualData
      ?.map((d) => ({
        label: getLabel(d),
        count: d.series.reduce((a, c) => a + c.faultCount, 0),
      }))
      .sort((a, b) => a.count - b.count);
    // gets the series data with reference to its labels
    const series = actualData?.map((d) => {
      return { label: getLabel(d), series: d.series };
    });
    // flattens series
    const seriesData = series?.map((s) => s.series.map((sd) => ({ label: s.label, ...sd }))).flat();
    // gets all x values
    const xAxis = series
      ?.map((s) => s.series)
      .flat()
      .map((s) => s.xAxis);

    return { yAxis, xAxis, seriesData };
  }, [data]);

  const chartOptions = isLoading
    ? { option: {} }
    : buildFaultTimeLineChartConfig({
        seriesData,
        yAxis,
        xAxis,
        rangeUnits,
        axisLabelRef,
      });
  return (
    <>
      {context}
      <BaseEChart
        style={{ height: 600, padding: 24 }}
        option={chartOptions.option}
        onEvents={{
          click: (e: {
            componentType: string;
            data: { data: { label: string; faultCount: number; pvinList: string[]; xAxis: number } };
          }) => {
            if (e.componentType === "series") {
              const clicked = e.data.data;
              const [clickedFaultCode, clickedECU] = clicked.label.split(" + ");
              setSelectedChartElement({
                clickedFaultCode: clickedFaultCode.trim(),
                clickedECU: clickedECU.trim(),
                clickedXAxis: clicked.xAxis,
              });
            }
          },
          mousemove: (e: { componentType: string; value: string; event: MouseEvent }, c: EChartsInstance) => {
            if (e.componentType === "yAxis" && e.value) {
              const attributes = e.value.split(" ");
              axisLabelRef.current = getFaultDescription(
                customerIdentifier,
                attributes[0],
                attributes[2],
                attributes[4]
              )?.name;
              c.dispatchAction({
                type: "showTip",
                seriesIndex: 0,
                dataIndex: 0,
                position: [e.event.offsetX, e.event.offsetY - 55],
              });
            } else {
              axisLabelRef.current = undefined;
            }
          },
          mouseout: () => (axisLabelRef.current = undefined),
        }}
        showLoading={isLoading}
      />
      <ClusterModal
        //prettier-ignore
        handleSuccess={() => { }}
        initialView={"vehiclesAffected"}
        pvins={faultTimelinePvinData?.filteredQuery.faultTimelinePvins}
        isOpen={isTimelinePvinQueryActive}
        onCancel={() => setSelectedChartElement(defaultSelectedChartElement)}
        isLoading={faultTimelinePvinLoading}
        api={api}
      />
    </>
  );
};
