import { COOKIES } from "@/constants";

export const getCookie = (cookie: keyof typeof COOKIES): string | null => {
  // FIXME: Make this better
  const name = `${COOKIES[cookie]}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

export function setCookie(cookie: keyof typeof COOKIES, value: any) {
  document.cookie = `${COOKIES[cookie]}=${encodeURIComponent(value)};`;
}

export function removeCookie(cookie: keyof typeof COOKIES) {
  document.cookie = `${COOKIES[cookie]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
