import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import {
  EventTimeSeriesHistoryQuery,
  EventTimeSeriesHistoryQueryVariables,
  useEventTimeSeriesHistoryQuery,
} from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedEventTimeSeriesHistoryQuery = (
  variables: EventTimeSeriesHistoryQueryVariables,
  options?: UseQueryOptions<EventTimeSeriesHistoryQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useEventTimeSeriesHistoryQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.TelemetryComponent) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.TelemetryComponent)) {
    return results;
  }

  return { data: fakeEventTimeSeriesData, isLoading: false, isError: false, error: undefined, isSuccess: true };
};

// TODO: Meet with product to determine good fake data to show. Then we can create functions to reduce lines of code.
const fakeEventTimeSeriesData: EventTimeSeriesHistoryQuery = {
  filteredQuery: {
    eventTimeSeriesHistory: {
      count: [
        [
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
          36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36, 36,
        ],
      ],
      high: [
        [
          699.851, 699.809, 699.82, 699.801, 699.797, 699.773, 699.792, 699.776, 699.757, 699.754, 699.72, 699.766,
          699.73275, 699.747, 699.706, 699.654, 699.64, 699.652, 699.676, 699.676, 699.69, 699.717, 699.747, 699.783,
          699.77875, 699.768, 699.768, 699.771, 699.808, 699.803, 699.762, 699.741, 699.736, 699.6475, 699.728, 699.728,
          699.728, 699.717, 699.713, 699.754, 699.792, 699.805, 699.817, 699.816, 699.808, 699.801, 699.825, 699.785,
          699.791, 699.782, 699.811, 699.813, 699.821, 699.853, 699.843, 699.843, 699.826, 699.851, 699.875, 699.86175,
          699.877, 699.879, 699.869, 699.868, 699.854, 699.866, 699.872, 699.863, 699.866, 699.885, 699.883, 699.857,
          699.836, 699.798, 699.787, 699.803, 699.789, 699.773, 699.73, 699.727, 699.755, 699.778, 699.755, 699.753,
          699.621, 699.693, 699.735, 699.718, 699.71375, 699.657, 699.72075, 699.708, 699.682, 699.744, 699.733,
          699.671, 699.679, 699.749, 699.7, 699.743, 699.71, 699.713, 699.766, 699.757, 699.752, 699.753, 699.727,
          699.745, 699.669, 699.653, 699.675, 699.649, 699.714, 699.715, 699.741, 699.745, 699.714, 699.745, 699.737,
          699.741, 699.755, 699.811, 699.818, 699.827, 699.84, 699.812, 699.832, 699.837, 699.844, 699.80475, 699.779,
          699.75475, 699.765, 699.787, 699.821, 699.777, 699.762, 699.736, 699.718, 699.777, 699.735, 699.746, 699.786,
          699.746, 699.741, 699.741, 699.698, 699.606, 699.67, 699.648, 699.75325, 699.751, 699.67, 699.621, 699.637,
          699.661, 699.695, 699.602, 699.614, 699.672, 699.624, 699.609, 699.547, 699.542, 699.521, 699.549, 699.682,
          699.698, 699.625, 699.58, 699.62625, 699.403, 699.406, 699.485, 699.406, 699.476, 699.38275, 699.419, 699.491,
          699.564, 699.65, 699.694, 699.686, 699.683, 699.709, 699.683, 699.64, 699.666, 699.733, 699.654, 699.618,
          699.628, 699.60175, 699.648, 699.657, 699.665, 699.67125, 699.708, 699.682, 699.62475, 699.463, 699.50975,
          699.492, 699.599, 699.663, 699.674, 699.613, 699.718, 699.708, 699.731, 699.724, 699.686, 699.716, 699.737,
          699.76, 699.772, 699.723, 699.697, 699.71175, 699.737, 699.68475, 699.787, 699.807, 699.813, 699.7975, 699.82,
          699.844, 699.84525, 699.843, 699.85, 699.826, 699.807, 699.795, 699.805, 699.771, 699.8, 699.824, 699.837,
          699.798, 699.8, 699.7845, 699.775, 699.731, 699.663, 699.6985, 699.736, 699.756, 699.753, 699.775, 699.804,
          699.774, 699.774, 699.783, 699.727, 699.71, 699.705, 699.747, 699.691, 699.706, 699.734, 699.773, 699.8,
          699.792, 699.807, 699.79, 699.758, 699.744, 699.749, 699.791, 699.809, 699.827, 699.769, 699.769, 699.725,
          699.739, 699.734, 699.703, 699.689, 699.684, 699.712, 699.706, 699.749, 699.793, 699.771, 699.8175, 699.821,
          699.802, 699.802, 699.835, 699.875, 699.883, 699.882, 699.894, 699.907, 699.906, 699.917, 699.913, 699.916,
          699.914, 699.92, 699.921, 699.933, 699.934, 699.945, 699.939, 699.936, 699.945, 699.943, 699.941, 699.93,
          699.923, 699.926, 699.927, 699.921, 699.926, 699.929, 699.933, 699.926, 699.921, 699.909, 699.90775, 699.913,
          699.907, 699.919, 699.92, 699.912, 699.907, 699.899, 699.887, 699.868, 699.883, 699.878, 699.873, 699.877,
          699.847, 699.852, 699.892, 699.884, 699.855, 699.84, 699.84525, 699.865, 699.852, 699.848, 699.844, 699.814,
          699.842, 699.836, 699.851, 699.842, 699.841, 699.808, 699.801, 699.782, 699.798, 699.83, 699.803, 699.811,
          699.832, 699.826, 699.839, 699.834, 699.81, 699.821, 699.83, 699.824, 699.849, 699.866, 699.858, 699.862,
          699.84, 699.841, 699.861, 699.812, 699.821, 699.824, 699.838, 699.857, 699.878, 699.895, 699.892, 699.88,
          699.869, 699.873, 699.869, 699.867, 699.832, 699.848, 699.871, 699.852, 699.835, 699.82175, 699.858, 699.856,
          699.878, 699.877, 699.859, 699.868, 699.867, 699.864, 699.875,
        ],
      ],
      low: [
        [
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 562.348, 599.039, 624.372, 614.098,
          613.633, 626.968, 627.254, 625.4205, 611.049, 554.763, 592.71, 595.5305, 535.569, 602.237, 612.812, 618.201,
          598.0695, 584.139, 500, 500, 500, 500, 576.23, 538.953, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
        ],
        [
          499.975, 499.973, 499.973, 499.973, 499.973, 499.973, 499.975, 499.974, 499.974, 499.973, 499.972, 499.973,
          499.972, 499.971, 499.97, 499.968, 499.968, 499.968, 499.97, 499.969, 499.969, 499.971, 499.972, 499.973,
          499.973, 499.972, 499.971, 499.97, 499.972, 499.97, 499.969, 499.967, 499.967, 499.964, 499.965, 499.964,
          499.96325, 499.962, 499.961, 499.963, 499.965, 499.965, 499.965, 499.963, 499.963, 499.963, 499.963, 499.961,
          499.963, 499.963, 499.963, 499.963, 499.963, 499.964, 499.963, 499.961, 499.96, 499.963, 499.964, 499.965,
          499.968, 499.966, 499.966, 499.966, 499.965, 499.965, 499.966, 499.966, 499.966, 499.967, 499.966, 499.963,
          499.96, 499.958, 499.957, 499.958, 499.958, 499.958, 499.955, 499.956, 499.955, 499.957, 499.955, 499.954,
          499.948, 499.952, 499.955, 499.953, 499.954, 499.953, 499.958, 499.958, 499.959, 499.963, 499.962, 499.958,
          499.959, 499.961, 499.957, 499.958, 499.958, 499.958, 499.961, 499.961, 499.96, 499.962, 499.961, 499.961,
          499.958, 499.957, 499.957, 499.957, 499.958, 499.96, 499.96, 499.962, 499.96, 499.96, 499.959, 499.96,
          499.961, 499.964, 499.963, 499.964, 499.964, 499.961, 499.962, 499.961, 499.96, 499.959, 499.959, 499.957,
          499.959, 499.959, 499.963, 499.959, 499.956, 499.953, 499.954, 499.956, 499.952, 499.951, 499.954, 499.948,
          499.949, 499.948, 499.944, 499.94, 499.939, 499.938, 499.94675, 499.94425, 499.938, 499.933, 499.934, 499.934,
          499.934, 499.925, 499.926, 499.924, 499.921, 499.918, 499.918, 499.915, 499.917, 499.913, 499.918, 499.92,
          499.913, 499.912, 499.916, 499.909, 499.906, 499.907, 499.908, 499.915, 499.917, 499.919, 499.922, 499.927,
          499.934, 499.936, 499.937, 499.937, 499.936, 499.937, 499.936, 499.941, 499.943, 499.939, 499.938, 499.937,
          499.934, 499.936, 499.936, 499.938, 499.94, 499.94, 499.941, 499.938, 499.926, 499.932, 499.934, 499.939,
          499.941, 499.941, 499.938, 499.946, 499.946, 499.949, 499.949, 499.948, 499.951, 499.952, 499.953, 499.955,
          499.95, 499.951, 499.95, 499.952, 499.95, 499.955, 499.959, 499.96, 499.959, 499.958, 499.958, 499.959,
          499.959, 499.957, 499.954, 499.951, 499.948, 499.949, 499.949, 499.95, 499.951, 499.949, 499.945, 499.946,
          499.947, 499.95, 499.949, 499.945, 499.947, 499.949, 499.949, 499.952, 499.952, 499.955, 499.956, 499.954,
          499.9535, 499.951, 499.951, 499.951, 499.955, 499.952, 499.952, 499.955, 499.956, 499.957, 499.956, 499.957,
          499.955, 499.954, 499.955, 499.955, 499.96, 499.96, 499.959, 499.956, 499.956, 499.955, 499.956, 499.957,
          499.957, 499.957, 499.958, 499.959, 499.959, 499.961, 499.965, 499.965, 499.967, 499.968, 499.969, 499.969,
          499.971, 499.973, 499.973, 499.971, 499.973, 499.974, 499.975, 499.977, 499.975, 499.976, 499.976, 499.976,
          499.975, 499.977, 499.978, 499.98, 499.979, 499.978, 499.979, 499.979, 499.978, 499.976, 499.975, 499.976,
          499.976, 499.975, 499.976, 499.977, 499.978, 499.977, 499.976, 499.974, 499.975, 499.975, 499.974, 499.976,
          499.975, 499.975, 499.974, 499.973, 499.971, 499.971, 499.972, 499.974, 499.973, 499.972, 499.97, 499.971,
          499.973, 499.972, 499.97, 499.97, 499.97, 499.972, 499.971, 499.972, 499.971, 499.971, 499.973, 499.973,
          499.974, 499.974, 499.974, 499.973, 499.971, 499.97, 499.971, 499.973, 499.973, 499.973, 499.974, 499.975,
          499.976, 499.976, 499.974, 499.974, 499.975, 499.975, 499.976, 499.977, 499.977, 499.978, 499.976, 499.977,
          499.978, 499.977, 499.978, 499.977, 499.977, 499.978, 499.979, 499.98, 499.98, 499.978, 499.976, 499.977,
          499.977, 499.977, 499.975, 499.976, 499.978, 499.976, 499.977, 499.976, 499.978, 499.979, 499.979, 499.978,
          499.977, 499.978, 499.977, 499.978, 499.978,
        ],
      ],
      x: [
        -2000, -1990, -1980, -1970, -1960, -1950, -1940, -1930, -1920, -1910, -1900, -1890, -1880, -1870, -1860, -1850,
        -1840, -1830, -1820, -1810, -1800, -1790, -1780, -1770, -1760, -1750, -1740, -1730, -1720, -1710, -1700, -1690,
        -1680, -1670, -1660, -1650, -1640, -1630, -1620, -1610, -1600, -1590, -1580, -1570, -1560, -1550, -1540, -1530,
        -1520, -1510, -1500, -1490, -1480, -1470, -1460, -1450, -1440, -1430, -1420, -1410, -1400, -1390, -1380, -1370,
        -1360, -1350, -1340, -1330, -1320, -1310, -1300, -1290, -1280, -1270, -1260, -1250, -1240, -1230, -1220, -1210,
        -1200, -1190, -1180, -1170, -1160, -1150, -1140, -1130, -1120, -1110, -1100, -1090, -1080, -1070, -1060, -1050,
        -1040, -1030, -1020, -1010, -1000, -990, -980, -970, -960, -950, -940, -930, -920, -910, -900, -890, -880, -870,
        -860, -850, -840, -830, -820, -810, -800, -790, -780, -770, -760, -750, -740, -730, -720, -710, -700, -690,
        -680, -670, -660, -650, -640, -630, -620, -610, -600, -590, -580, -570, -560, -550, -540, -530, -520, -510,
        -500, -490, -480, -470, -460, -450, -440, -430, -420, -410, -400, -390, -380, -370, -360, -350, -340, -330,
        -320, -310, -300, -290, -280, -270, -260, -250, -240, -230, -220, -210, -200, -190, -180, -170, -160, -150,
        -140, -130, -120, -110, -100, -90, -80, -70, -60, -50, -40, -30, -20, -10, 0, 10, 20, 30, 40, 50, 60, 70, 80,
        90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300,
        310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520,
        530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740,
        750, 760, 770, 780, 790, 800, 810, 820, 830, 840, 850, 860, 870, 880, 890, 900, 910, 920, 930, 940, 950, 960,
        970, 980, 990, 1000, 1010, 1020, 1030, 1040, 1050, 1060, 1070, 1080, 1090, 1100, 1110, 1120, 1130, 1140, 1150,
        1160, 1170, 1180, 1190, 1200, 1210, 1220, 1230, 1240, 1250, 1260, 1270, 1280, 1290, 1300, 1310, 1320, 1330,
        1340, 1350, 1360, 1370, 1380, 1390, 1400, 1410, 1420, 1430, 1440, 1450, 1460, 1470, 1480, 1490, 1500, 1510,
        1520, 1530, 1540, 1550, 1560, 1570, 1580, 1590, 1600, 1610, 1620, 1630, 1640, 1650, 1660, 1670, 1680, 1690,
        1700, 1710, 1720, 1730, 1740, 1750, 1760, 1770, 1780, 1790, 1800, 1810, 1820, 1830, 1840, 1850, 1860, 1870,
        1880, 1890, 1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000,
      ],
      y: [
        [
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 562.348, 599.039, 624.372, 614.098,
          613.633, 626.968, 627.254, 625.4205, 611.049, 554.763, 592.71, 595.5305, 535.569, 602.237, 612.812, 618.201,
          598.0695, 584.139, 500, 500, 500, 500, 576.23, 538.953, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
          500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500,
        ],
      ],
    },
    vehicleCohortTimeSeriesSummary: [
      {
        bottomQuartile: 499.982,
        bottomDecile: 475.161,
        bottomVigintile: 462.168,
        min: 1.134,
        max: 700,
        mean: 585.769327687477,
        median: 500,
        standardDeviation: 101.0252912845,
        topQuartile: 699.92,
        topDecile: 700,
        topVigintile: 700,
      },
    ],
  },
};
