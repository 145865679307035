import { useSelector } from "react-redux";

import EventsSummaryChart from "@/components/charts/EventsSummaryChart";
import EventOccurrencesTable from "@/components/event/EventOccurrencesTable";
import BlockingLoading from "@/components/ui/BlockLoading";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { selectEventFilter } from "@/contexts/EventAnalysisChartStore";
import { toTitleCase } from "@/utils/toTitleCase";

export const EventOccurrences = () => {
  const { selectedEventFilter: eventFilter } = useSelector(selectEventFilter);

  if (!eventFilter) {
    return <BlockingLoading className="line" />;
  }

  return (
    <BasicWidget
      className="table event-occurrences-title-card"
      title={toTitleCase("Vehicles that experienced this event")}
    >
      <EventOccurrencesTable eventFilter={eventFilter} />
    </BasicWidget>
  );
};

const EventSummary = () => {
  const { selectedEventFilter: eventFilter } = useSelector(selectEventFilter);
  if (!eventFilter) {
    return <BlockingLoading className="line" />;
  }

  return (
    <BasicWidget title="Occurrences Summary" className="summary-chart-container">
      <EventsSummaryChart eventFilter={eventFilter} />
    </BasicWidget>
  );
};

export default EventSummary;
