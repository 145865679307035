//TODO create vite config with aliases and a new generated graphql schema
//TODO: avoid lib depending on backend
import { ExpressionIntervalType } from "../generated/graphql";

export enum NumericLiteralType {
  LiteralNumeric = "LiteralNumeric",
}

export enum NumericUnaryExpressionType {
  Abs = "Abs",
}

export enum NumericBinaryExpressionType {
  Add = "Add",
  Multiply = "Multiply",
  Subtract = "Subtract",
  Divide = "Divide",
}

export const NumericExpressionType = {
  ...NumericLiteralType,
  ...NumericUnaryExpressionType,
  ...NumericBinaryExpressionType,
};
// eslint-disable-next-line no-redeclare
export type NumericExpressionType = typeof NumericExpressionType;

export enum BooleanUnaryExpressionType {
  IsNull = "IsNull",
  IsNotNull = "IsNotNull",
  Not = "Not",
}

export enum BooleanBinaryExpressionType {
  Neq = "Neq",
  Gt = "Gt",
  Gte = "Gte",
  Lt = "Lt",
  Lte = "Lte",
  Eq = "Eq",
  And = "And",
  Or = "Or",
}

export const BinaryExpressionType = {
  ...NumericBinaryExpressionType,
  ...BooleanBinaryExpressionType,
};
// eslint-disable-next-line no-redeclare
export type BinaryExpressionType = typeof BinaryExpressionType;

export const BooleanExpressionType = {
  ...BooleanUnaryExpressionType,
  ...BooleanBinaryExpressionType,
};
// eslint-disable-next-line no-redeclare
export type BooleanExpressionType = typeof BooleanExpressionType;

export enum AnyExpressionType {
  If = "If",
  Case = "Case",
  Null = "Null",
}

export enum TimeSeriesExpressionType {
  Signal = "Signal",
  AnomalyScore = "AnomalyScore",
  Pattern = "Pattern",
}

export enum AggregationType {
  Average = "Average",
  Std = "Std",
  Min = "Min",
  Max = "Max",
  Median = "Median",
  Count = "Count",
  Percentile = "Percentile",
}

export enum ExpressionEventType {
  ExpressionEvent = "ExpressionEvent",
}

export const ExpressionType = {
  ...NumericExpressionType,
  ...BooleanExpressionType,
  ...AnyExpressionType,
  ...TimeSeriesExpressionType,
  Aggregation: "Aggregation" as const,
  // ...AggregationType,
};

// eslint-disable-next-line no-redeclare
type ExpressionType = typeof ExpressionType;

export type Expression =
  | BooleanExpression
  | NumericExpression
  | TimeSeriesFieldExpression
  // | AggregationExpression
  | AnyExpressions;

/***                     ***/
/*** Boolean expressions ***/
/***                     ***/
export type NotExpression = {
  type: BooleanExpressionType["Not"];
  values: [Expression];
};

export type IsNullExpression = {
  type: BooleanExpressionType["IsNull"];
  values: [Expression];
};

export type IsNotNullExpression = {
  type: BooleanExpressionType["IsNotNull"];
  values: [Expression];
};

export type GtExpression = {
  type: BooleanExpressionType["Gt"];
  values: [Expression, Expression];
};

export type GteExpression = {
  type: BooleanExpressionType["Gte"];
  values: [Expression, Expression];
};

export type LtExpression = {
  type: BooleanExpressionType["Lt"];
  values: [Expression, Expression];
};

export type LteExpression = {
  type: BooleanExpressionType["Lte"];
  values: [Expression, Expression];
};

export type EqExpression = {
  type: BooleanExpressionType["Eq"];
  values: [Expression, Expression];
};

export type NeqExpression = {
  type: BooleanExpressionType["Neq"];
  values: [Expression, Expression];
};

export type AndExpression = {
  type: BooleanExpressionType["And"];
  values: [Expression, Expression];
};

export type OrExpression = {
  type: BooleanExpressionType["Or"];
  values: [Expression, Expression];
};

export type BooleanExpression =
  | AndExpression
  | OrExpression
  | IsNotNullExpression
  | NotExpression
  | IsNullExpression
  | GtExpression
  | GteExpression
  | LtExpression
  | LteExpression
  | EqExpression
  | NeqExpression;

/***                     ***/
/*** Numeric expressions ***/
/***                     ***/
export type AddExpression = {
  type: NumericExpressionType["Add"];
  values: Expression[];
};
export type SubtractExpression = {
  type: NumericExpressionType["Subtract"];
  values: [Expression, Expression];
};
export type MultiplyExpression = {
  type: NumericExpressionType["Multiply"];
  values: Expression[];
};
export type DivideExpression = {
  type: NumericExpressionType["Divide"];
  values: [Expression, Expression];
};
export type AbsExpression = {
  type: NumericExpressionType["Abs"];
  values: [Expression];
};
export type LiteralNumericExpression = {
  type: NumericExpressionType["LiteralNumeric"];
  value: number;
};

export type NumericExpression =
  | AddExpression
  | SubtractExpression
  | MultiplyExpression
  | DivideExpression
  | AbsExpression
  | LiteralNumericExpression
  | AggregationExpression;

/***                     ***/
/*** Any expressions ***/
/***                     ***/
export type IfExpression = {
  type: AnyExpressionType.If;
  values: [Expression, Expression, Expression];
};

export type TimeSeriesFieldExpression = SignalExpression | PatternExpression | AnomalyScoreExpression;

export type SignalExpression = {
  type: TimeSeriesExpressionType.Signal;
  value: string;
};

export type PatternExpression = {
  type: TimeSeriesExpressionType.Pattern;
  value: string;
};

export type AnomalyScoreExpression = {
  type: TimeSeriesExpressionType.AnomalyScore;
  value: string;
};

export type CaseExpression = {
  type: AnyExpressionType.Case;
  when: Expression[];
  then: Expression[];
  else: Expression;
};

export type NullExpression = {
  type: AnyExpressionType.Null;
  value: null | undefined;
};

export type AnyExpressions =
  | IfExpression
  | TimeSeriesFieldExpression
  | CaseExpression
  | NullExpression
  | AggregationExpression;

/***                     ***/
/*** Aggregation expressions ***/
/***                     ***/
export type PercentileAggregationExpression = AggregationExpressionBase & {
  aggregationType: AggregationType.Percentile;
  percentile: number;
};

type AggregationExpressionBase = {
  // type: AggregationType;
  type: typeof ExpressionType.Aggregation;
  intervalType: ExpressionIntervalType;
  intervalDuration: number;
  values: [Expression];
};

type NonPercentileAggregationExpression = AggregationExpressionBase & {
  aggregationType: Exclude<AggregationType, AggregationType.Percentile>;
};

export type AggregationExpression = NonPercentileAggregationExpression | PercentileAggregationExpression;

export enum ExpressionValueType {
  TimeSeries = "TimeSeries",
  Comparison = "Comparison",
  Mathematical = "Mathematical",
  Literal = "Literal",
  Conditional = "Conditional",
  Aggregation = "Aggregation",
}

export type BinaryExpression =
  | AddExpression
  | MultiplyExpression
  | AndExpression
  | OrExpression
  | SubtractExpression
  | DivideExpression
  | GtExpression
  | GteExpression
  | LtExpression
  | LteExpression
  | EqExpression
  | NeqExpression;

export type UnaryExpression = IsNullExpression | IsNotNullExpression | NotExpression;

export type ArrayExpression = AddExpression | MultiplyExpression | AndExpression | OrExpression;

export { ExpressionIntervalType };
