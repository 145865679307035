import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Dropdown, MenuProps, Modal, notification, Spin } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";

import {
  CalculatedStatus,
  SavedExpression,
  SortOrder,
  useDeleteExpressionEventMutation,
  useRecentEventsQuery,
  UserEventsSortField,
  UserEventsSortInput,
  useUserEventsQuery,
} from "@/api";
import Loading from "@/components/loading";
import { PatternLink } from "@/components/pattern/PatternLink";
import { BasicTable } from "@/components/tables/BasicTable";
import { useRemoteSorting } from "@/components/tables/useRemoteSorting";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { Route } from "@/routes/event-analysis";
import { getCurrentCustomerId, requireCurrentCustomerId } from "@/utils/customers";
import { humanFriendlyStatuses } from "@/utils/patterns";

const { confirm } = Modal;

type RecordType = {
  id: string;
  name: string;
  lastModified: string;
  version: number;
  status: CalculatedStatus;
  expression: SavedExpression;
};

export const UserEventsTable = () => {
  const queryClient = useQueryClient();

  const currentPage = Route.useSearch({ select: (search) => search.eventsPage }) || 1;
  const eventsNavigate = Route.useNavigate();

  const setCurrentPage = (page: number) => {
    eventsNavigate({ search: (prev) => ({ ...prev, eventsPage: page }) });
  };

  const { sorting, handleTableChangeForSorting, toMaybeColumnWithInitialSorting } = useRemoteSorting({
    fieldsEnumOptions: Object.values(UserEventsSortField),
    initialValue: { field: UserEventsSortField.LastModified, order: "descend" as SortOrder },
  });

  const { data, refetch, isLoading, isRefetching } = useUserEventsQuery({
    customerId: getCurrentCustomerId() ?? "",
    pagination: { pageSize: DEFAULT_PAGE_SIZE, currentPage },
    sorting: sorting as UserEventsSortInput,
  });
  const tableData = data?.userEvents?.expressionEvents;
  const totalDataCount = data?.userEvents?.pagination?.totalCount ?? 0;

  const deleteExpressionEventMutation = useDeleteExpressionEventMutation({ onSettled: () => setCurrentPage(1) });
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  if (isLoading) {
    return <Loading height={256} />;
  }

  const isDeleting = deleteExpressionEventMutation.isLoading;

  function onClickDelete({ id, version }: RecordType) {
    confirm({
      title: "Confirm Delete",
      icon: <ExclamationCircleFilled />,
      content: "Deleting this user event cannot be undone. Confirm?",
      onOk() {
        const options = {
          onSuccess() {
            notificationApi.success({ message: `Event deleted.` });
            queryClient.invalidateQueries({
              queryKey: useRecentEventsQuery.getKey({}),
            });
            refetch();
          },
        };
        deleteExpressionEventMutation.mutate(
          {
            customerId: requireCurrentCustomerId(),
            id,
            version,
          },
          options
        );
      },
    });
  }

  const columns = [
    {
      key: "name",
      title: "Name",
      sorter: true,
      render: (_: string, row: RecordType) => (
        <Link to="/pattern-event/$patternEventId" params={{ patternEventId: row.id }}>
          {row.name}
        </Link>
      ),
    },
    {
      key: "lastModified",
      title: "Last Modified",
      width: 180,
      sorter: true,
      render: (_: string, row: RecordType) => {
        return <FromNowFormatter value={new Date(row.lastModified)} />;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (status: string) => humanFriendlyStatuses(status as CalculatedStatus),
    },
    {
      key: "pattern",
      title: "Pattern",
      dataIndex: "expression",
      render: (_: string, row: RecordType) => {
        return <PatternLink name={row.expression.name} id={row.expression.id} />;
      },
    },
    {
      width: 44,
      render: (_: string, row: RecordType) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "delete",
            label: "Delete",
            onClick: () => onClickDelete(row),
          },
        ];
        deleteExpressionEventMutation;
        return (
          <Dropdown menu={{ items: menuItems }} placement="bottomRight" trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ].map(toMaybeColumnWithInitialSorting);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorterResult: SorterResult<any> | SorterResult<any>[]
  ) => {
    handleTableChangeForSorting(pagination, filters, sorterResult as SorterResult<unknown>);
  };

  return (
    <>
      <BasicTable
        dataSource={tableData}
        columns={columns}
        onChange={handleTableChange}
        rowKey={"name"}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: totalDataCount,
          current: currentPage,
          onChange: setCurrentPage,
          showSizeChanger: false,
        }}
        loading={isLoading || isRefetching}
      />
      {notificationContextHolder}
      {isDeleting && <Spin fullscreen />}
    </>
  );
};
