import { AggregationExpression, AggregationType } from "@lib/src/expression/types";
import { Flex, Input, Select } from "antd";

import { ExpressionIntervalType } from "@/api";
import { stopPropagation } from "@/utils/stopPropagation";

import { ExpressionInput, ExpressionInputProps } from "./ExpressionInput";

export const AggregationExpressionInput = (props: ExpressionInputProps<AggregationExpression>) => {
  const { expression, path, onChange, disabled } = props;

  const aggregationTypeOptions = Object.values(AggregationType).map((v) => ({ value: v, label: v }));
  const intervalTypeOptions = Object.values(ExpressionIntervalType).map((v) => ({ value: v, label: v }));

  const return0ifNaN = (value: number) => {
    if (isNaN(value)) return 0;
    return value;
  };
  return (
    <Flex vertical>
      <Flex gap={8}>
        <Select
          options={aggregationTypeOptions}
          value={expression.aggregationType}
          placeholder="Aggregation Type"
          onSelect={(value) => onChange?.({ ...expression, aggregationType: value }, [...path])}
          disabled={!onChange || disabled}
          onKeyDown={(e) => stopPropagation(e, "Enter")}
        />
        {expression.aggregationType == AggregationType.Percentile && (
          <Input
            value={expression.percentile}
            placeholder="Percentile"
            addonAfter="%"
            onChange={(e) => onChange?.({ ...expression, percentile: return0ifNaN(Number(e.target.value)) }, [...path])}
            disabled={!onChange || disabled}
          />
        )}
        <Input
          value={expression.intervalDuration}
          placeholder="Interval Duration"
          onChange={(e) =>
            onChange?.({ ...expression, intervalDuration: return0ifNaN(Number(e.target.value)) }, [...path])
          }
          disabled={!onChange || disabled}
          minLength={1}
          style={{ minWidth: "5em" }}
        />
        <Select
          options={intervalTypeOptions}
          value={expression.intervalType}
          placeholder="Interval Type"
          onSelect={(value) => onChange?.({ ...expression, intervalType: value }, [...path])}
          disabled={!onChange || disabled}
        />
      </Flex>
      <ExpressionInput
        expression={expression.values![0]!}
        disabled={!onChange || disabled}
        onChange={onChange}
        path={[...path, "values", "0"]}
      />
    </Flex>
  );
};
