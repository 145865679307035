import { TimeSeriesFieldFamily } from "@/api";
import useTimeSeriesOptionsForCharting from "@/hooks/useTimeSeriesOptionsForCharting";

export const useTimeSeriesForTypeahead = () => {
  const { isLoading: signalsLoading, timeSeriesOptions: availableSignals } = useTimeSeriesOptionsForCharting({
    typeFilter: TimeSeriesFieldFamily.Signal,
  });
  const { isLoading: patternsLoading, timeSeriesOptions: availablePatterns } = useTimeSeriesOptionsForCharting({
    typeFilter: TimeSeriesFieldFamily.Expression,
  });
  const { isLoading: anomaliesLoading, timeSeriesOptions: availableAnomalies } = useTimeSeriesOptionsForCharting({
    typeFilter: TimeSeriesFieldFamily.AnomalyScore,
  });

  return {
    isLoading: signalsLoading || patternsLoading || anomaliesLoading,
    availableSignals,
    availablePatterns,
    availableAnomalies,
  };
};
