import { PropsWithChildren } from "react";

import { NodeWrapperContainer } from "./NodeWrapper.styled";

type NodeWrapperProps = {
  hidden?: boolean;
};

const NodeWrapper = ({ children, hidden }: PropsWithChildren<NodeWrapperProps>) => {
  if (hidden) return <>{children}</>;
  return <NodeWrapperContainer>{children}</NodeWrapperContainer>;
};

export default NodeWrapper;
