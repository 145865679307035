import { Card } from "antd";
import styled from "styled-components";

export const WidgetStyled = styled(Card)`
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  background-color: #f9fafe;
  &.ant-card-bordered {
    .ant-card-head {
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }
  }

  .ant-card-body {
    padding: 0;
    background-color: #f9fafe;
  }

  .ant-card-head {
    display: flex;
    align-items: center;
    height: 78px;
    margin-bottom: 0;
    padding: 10px 24px;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    font-family: "Hubot Sans";
    background-color: #fff;
  }

  &.no-header-styling {
    .ant-card-head {
      padding: 0;
      margin: 0;
      height: inherit;
      line-height: inherit;
      .ant-card-head-wrapper {
        .ant-card-head-title {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  &.hidden-header {
    .ant-card-head {
      display: none;
    }
  }

  &.fallback-container {
    .ant-card-body {
      padding: 1.5rem;
      background-color: ${({ theme }) => theme.colors.smoothGray};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .reset-icon {
        font-size: 1.5rem;
        color: ${({ theme }) => theme.colors.backgroundHover};
      }
    }
  }

  &.chart {
    height: 450px;
    .ant-card-body {
      height: 260px;
    }
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleWithTooltipContainer = styled(TitleContainer)`
  width: min-content;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const RightElementContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubtitleText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.backgroundHover};
  margin-top: 0.25rem;
`;
