import { useParams } from "@tanstack/react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { EventType } from "@/api";
import { EventFilter } from "@/api/customerApi";
import { EventAnalysisChartActions } from "@/contexts/EventAnalysisChartSlice";
import { FaultEventAnalysisSearch, useEventAnalysisSearch } from "@/hooks/useEventAnalysisSearch";

import { EventAnalyzerProps } from "./EventAnalyzer";

interface EventAnalyzerParams extends FaultEventAnalysisSearch {
  faultCodeId: string;
  patternEventId: string;
}

const getEventFilterData = (props: EventAnalyzerProps, params: EventAnalyzerParams): EventFilter => {
  const { eventType, repairType } = props;
  const {
    faultCodeId,
    patternEventId,
    ecu,
    softwareVersion,
    softwarePartNumber,
    hardwarePartNumber,
    hardwareVersion,
    troubleCode,
    clusterId,
  } = params;

  return {
    repairEventType: eventType === EventType.Repair ? repairType : undefined,
    expressionEventId: eventType === EventType.Expression ? patternEventId : undefined,
    fault:
      eventType === EventType.Fault
        ? {
            faultCode: faultCodeId,
            ecu,
            softwareVersion,
            softwarePartNumber,
            hardwarePartNumber,
            hardwareVersion,
            troubleCode,
            clusterId,
          }
        : undefined,
  };
};

// This exists with the sole purpose of avoid useParams re-renders on any change to the URL
const EventFilterManager = (props: EventAnalyzerProps) => {
  const dispatch = useDispatch();

  const { faultCodeId, patternEventId } = useParams({ strict: false });
  const search = useEventAnalysisSearch();

  const eventFilter = getEventFilterData(props, {
    faultCodeId: faultCodeId ?? "",
    patternEventId: patternEventId ?? "",
    ...search,
  });

  useEffect(() => {
    dispatch(EventAnalysisChartActions.setEventFilter({ eventFilter }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultCodeId, patternEventId]);

  return <></>;
};

export default EventFilterManager;
