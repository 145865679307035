import { Col, Row, Typography } from "antd";
import { useLayoutEffect, useState } from "react";

import { SingleVehicleAnomalyDetector, SingleVehicleAnomalyDetectorHeaders } from "@/api/customerApi";
import { SectionModal } from "@/components/sectionModal/SectionModal";

import { AnomalyDetectorPreviewCard } from "./AnomalyDetectorPreviewCard";
import { AnomalyDetectorEmptyList, AnomalyDetectorPreviewCardsContainer } from "./AnomalyDetectorPreviewCards.styled";
import { SelectedAnomaly } from "./SelectedAnomaly";

type AnomalyDetectorPreviewCardsProps = {
  detectors: SingleVehicleAnomalyDetectorHeaders[];
  pvin: string;
  onAnomalySelected: (anomaly: SingleVehicleAnomalyDetector) => void;
  onFieldSelected: (fieldId: string) => void;
};

const ANOMALY_CARD_HEIGHT = 550;

export const AnomalyDetectorPreviewCards = ({
  detectors,
  pvin,
  onAnomalySelected,
  onFieldSelected,
}: AnomalyDetectorPreviewCardsProps) => {
  const [selectedAnomalyDetector, setSelectedAnomalyDetector] = useState<SingleVehicleAnomalyDetector>();
  const [anomalyHeight, setAnomalyHeight] = useState<number>();

  const anomalySection = document.getElementById("AnomalyCardsAndPagination-id");
  const selectedAnomalybiggerThanParent = (anomalySection?.clientHeight ?? 0) < ANOMALY_CARD_HEIGHT;
  useLayoutEffect(() => {
    if (selectedAnomalyDetector && anomalySection) {
      setAnomalyHeight(Math.max(anomalySection.clientHeight, ANOMALY_CARD_HEIGHT));
    } else {
      setAnomalyHeight(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnomalyDetector]);

  const containerClassname = `anomaly-detector-preview-cards${selectedAnomalyDetector ? " selected-anomaly" : ""}`;
  const containerId = "anomaly-detetor-preview-cards-container-id";

  if (detectors.length == 0)
    return (
      <AnomalyDetectorEmptyList>
        <Typography.Text>No Anomaly Detectors found</Typography.Text>
      </AnomalyDetectorEmptyList>
    );
  return (
    <AnomalyDetectorPreviewCardsContainer
      className={containerClassname}
      id={containerId}
      style={selectedAnomalyDetector && selectedAnomalybiggerThanParent ? { height: anomalyHeight } : {}}
    >
      <Row gutter={[24, 24]}>
        {detectors.map((detector) => (
          <Col span={6} className="anomaly-preview-column" key={detector.id}>
            <AnomalyDetectorPreviewCard pvin={pvin} detector={detector} onClick={setSelectedAnomalyDetector} />
          </Col>
        ))}
      </Row>

      {selectedAnomalyDetector && (
        <SectionModal
          parentId={containerId}
          component={
            <SelectedAnomaly
              anomalyHeight={anomalyHeight}
              selectedAnomalyDetector={selectedAnomalyDetector}
              setSelectedAnomalyDetector={setSelectedAnomalyDetector}
              onAnomalySelected={onAnomalySelected}
              onFieldSelected={onFieldSelected}
            />
          }
        />
      )}
    </AnomalyDetectorPreviewCardsContainer>
  );
};
