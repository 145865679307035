import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { ColumnType } from "antd/lib/table";
import { useContext, useMemo } from "react";
import { ValuesType } from "utility-types";

import { FeatureId, Pagination } from "@/api";
import {
  EventDescriptor,
  EventFilter,
  FaultFingerprintType,
  RepairEventType,
  useRelatedEventsQuery,
} from "@/api/generated/graphql.customer";
import { RepairEventNames } from "@/components/event/eventHeader/EventHeaderConstants";
import { BasicTable } from "@/components/tables/BasicTable";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { useFeatureFlags } from "@/utils/features";

interface RelatedEventTableProps {
  eventFilter: EventFilter;
}

type RecordType = {
  key: string;
  link: string;
  type: string;
  name: string;
  totalOccurrences: number;
  uniqueVehicles: number;
  faultFingerPrint?: FaultFingerprintType;
};

export default function RelatedEventTable({ eventFilter }: RelatedEventTableProps) {
  const { customerIdentifier } = useCustomerIdentifier();
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalFilter } = useContext(GlobalFilterContext);

  const { sortersInput, onChange } = useTablePagination<RecordType>();

  const currentPage = useSearch({ strict: false, select: (search) => search.relatedEventsPage });
  const navigate = useNavigate();

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, relatedEventsPage: page }) });
  };

  const { data, isFetching } = useRelatedEventsQuery(
    {
      filter: globalFilter,
      eventFilter,
      mileageWindowSize: 500,
      pagination,
      sort: sortersInput,
      telematicsless: !isFeatureEnabled(FeatureId.TelemetryComponent),
    },
    {
      staleTime: QUERY_SETTINGS.LONG_STALE_TIME,
    }
  );

  function toType(ed: EventDescriptor): string {
    if (ed.repairEventType) {
      return "Repair";
    }
    if (ed.fault) {
      return "Fault";
    }
    if (ed.expressionEventId) {
      return "Pattern";
    }
    return "Unknown";
  }

  function toName(ed: EventDescriptor): string {
    if (ed.repairEventType) {
      return RepairEventNames[ed.repairEventType];
    } else if (ed.fault) {
      return `${ed.fault.faultCode} ${ed.fault.ecu} ${ed.fault.troubleCode}`;
    }
    return ed.name;
  }

  function toAnalysisLink(ed: EventDescriptor): string {
    if (ed.repairEventType) {
      if (ed.repairEventType == RepairEventType.BatteryFullReplacement) {
        return "/repair/full";
      }
      if (ed.repairEventType == RepairEventType.BatteryModuleReplacement) {
        return "/repair/partial";
      }
    }
    if (ed.fault) {
      return `/fault/${ed.fault.faultCode}?ecu=${ed.fault.ecu}`;
    }
    if (ed.expressionEventId) {
      return `/pattern-event/${ed.expressionEventId}`;
    }
    return "";
  }

  const displayData: RecordType[] = useMemo(
    () =>
      data?.filteredQuery.relatedEvents.events.map((event) => ({
        key: event.event.expressionEventId ?? `${event.event.fault?.faultCode}` ?? event.event.repairEventType ?? "",
        link: toAnalysisLink(event.event),
        type: toType(event.event),
        name: toName(event.event),
        totalOccurrences: event.totalOccurrences,
        uniqueVehicles: event.uniqueVehicles,
        faultFingerPrint: event.event.fault ?? undefined,
      })) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const columns: ColumnType<ValuesType<typeof displayData>>[] = [
    {
      key: "key",
      title: "Event ID",
      sorter: true,
      render: (_, relatedEvent: RecordType) => {
        return <Link to={relatedEvent.link}>{relatedEvent.name}</Link>;
      },
      tooltipMessage: (_name, relatedEvent: RecordType) => {
        if (relatedEvent.faultFingerPrint) {
          return (
            getFaultDescription(
              customerIdentifier,
              relatedEvent.faultFingerPrint.faultCode,
              relatedEvent.faultFingerPrint.ecu ?? "",
              relatedEvent.faultFingerPrint.troubleCode ?? ""
            )?.name ?? ""
          );
        }
        return relatedEvent.name;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 128,
    },
    {
      key: "totalOccurrences",
      title: "Occurrences",
      dataIndex: "totalOccurrences",
      sorter: true,
      width: 128,
    },
    {
      key: "uniqueVehicles",
      title: "Vehicles",
      dataIndex: "uniqueVehicles",
      sorter: true,
      defaultSortOrder: "descend",
      width: 128,
    },
  ];

  return (
    <BasicTable
      loading={isFetching}
      columns={columns}
      dataSource={displayData}
      onChange={(pagination, _filter, sorter) => {
        onChange(pagination, {}, sorter);
        handlePaginationChange(pagination.current || 1);
      }}
      rowKey={(row) => row.name}
      pagination={{
        current: pagination.currentPage,
        total: data?.filteredQuery.relatedEvents.pagination.totalCount,
        showSizeChanger: false,
      }}
    />
  );
}
