import FunctionIcon from "../icons/Function.svg";
import { PatternLinkContainer } from "./PatternLink.styled";

type PatternLinkProps = {
  name: string;
  id: string;
};

export const PatternLink = ({ name, id }: PatternLinkProps) => {
  return (
    <PatternLinkContainer to={`/pattern/${id}`}>
      {name}
      <FunctionIcon />
    </PatternLinkContainer>
  );
};
