import { createFileRoute } from "@tanstack/react-router";

import { ClusterView } from "@/features/cluster/clusterView/ClusterView";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type ClusterEntitySearch = {
  diagnosticChecksPage?: number;
  faultFingerPrintsPage?: number;
};

export const Route = createFileRoute("/cluster/$clusterId")({
  component: () => (
    <AppMainLayout>
      <ClusterView />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Cluster"),
  validateSearch: (search: Record<string, unknown>): ClusterEntitySearch => {
    return {
      diagnosticChecksPage: +(search.diagnosticChecksPage as number) || undefined,
      faultFingerPrintsPage: +(search.faultFingerPrintsPage as number) || undefined,
    };
  },
});
