import { useNavigate } from "@tanstack/react-router";
import { PropsWithChildren } from "react";

import { FeatureId } from "@/api";
import { useFeatureFlags } from "@/utils/features";

type ProtectedRouteProps = {
  featureId: FeatureId;
};
export const ProtectedRoute = ({ featureId, children }: PropsWithChildren<ProtectedRouteProps>) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  if (isFeatureEnabled(featureId)) {
    return children;
  } else {
    navigate({ to: "/" });
    return;
  }
};
