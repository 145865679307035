import { Button } from "antd";
import dayjs from "dayjs";
import { EChartsInstance } from "echarts-for-react";
import React, { Dispatch, useContext, useEffect, useMemo, useState } from "react";

import {
  FailureByMonthsInServiceSeries,
  MonthsInService,
  useFailureCountByMonthsInServicePvinsQuery,
  useFailureCountByMonthsInServiceQuery,
  VehicleIdentifier,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { Route } from "@/routes/cluster.$clusterId";
import { toTitleCase } from "@/utils/toTitleCase";

import { ClusterViewGraphHeader } from "../../../features/cluster/clusterView/ClusterView.styled";
import { ClusterChartButtonsContainer } from "./ClusterChart.styled";
import { getClusterChartLayoutConfig } from "./clusterChartHelpers";

type ClusterDateVsFailureCountProps = {
  setAffectedPvins: Dispatch<React.SetStateAction<VehicleIdentifier[] | undefined>>;
  setModalView: Dispatch<
    React.SetStateAction<"vehiclesAffected" | "addVehiclesToCluster" | "viewMissingPvins" | "copyCluster" | undefined>
  >;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
};

const MonthsInServicesMap: Record<string, MonthsInService> = {
  "0-1Mis": MonthsInService.One,
  "1-3Mis": MonthsInService.Three,
  "3-6Mis": MonthsInService.Six,
  "6-12Mis": MonthsInService.Twelve,
  "12-24Mis": MonthsInService.TwentyFour,
  "24-48Mis": MonthsInService.FourtyEight,
  ">48Mis": MonthsInService.MoreThanFourtyEight,
};

const buildChartConfig = (data: FailureByMonthsInServiceSeries[], xAxisData: string[]) => {
  const { xNameGap, yNameGap, toolbox, dataZoom, grid, position } = getClusterChartLayoutConfig();

  return {
    animation: false,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      position,
      formatter: (
        params: [{ componentIndex: number; seriesIndex: number; data: number | null; dataIndex: number }]
      ) => {
        const misHeader = `<th style='color: rgb(41.2%, 16.1%, 77.3%'); padding: 8px;'>Months in Service</th>`;
        const failureCountHeader = "<th style='padding: 8px'>Fault Count</th>";
        const totalVehicleCountHeader = "<th style='padding: 8px'>Total Vehicle Count</th>";
        const vehiclesIncludedHeader = "<th style='padding: 8px'>Vehicles with Faults</th>";
        const tableHead = `<tr>${misHeader}${failureCountHeader}${totalVehicleCountHeader}${vehiclesIncludedHeader}</tr>`;
        const tableRows = params
          .map(({ componentIndex, seriesIndex, data: correspondentData }) => {
            if (!data[seriesIndex] || !data[seriesIndex].data[componentIndex] || !correspondentData) {
              return "";
            }
            const { totalVehicleCount, vehiclesWithFailure } = data[seriesIndex].data[seriesIndex];
            return `
          <tr style="text-align: center">
            <td style='padding: 8px'>${data[seriesIndex].label}</td>
            <td style='padding: 8px'>${correspondentData}</td>
            <td style='padding: 8px'>${totalVehicleCount}</td>
            <td style='padding: 8px'>${vehiclesWithFailure}</td>
          </tr>
          `;
          })
          .filter((data) => !!data);
        return `
        <table>${tableHead}${tableRows}</table>
        `;
      },
    },
    legend: {
      data: data.map(({ label }) => label),
    },
    toolbox,
    dataZoom,
    grid,
    xAxis: {
      nameGap: xNameGap,
      name: "Production Date",
      nameLocation: "middle",
      type: "category",
      data: xAxisData.map((month) => dayjs(month).format("MM/YYYY")).flat(),
      axisLabel: {
        show: true,
        margin: 5,
      },
      triggerEvent: true,
    },
    yAxis: {
      type: "value",
      name: "Fault Count",
      nameLocation: "middle",
      nameGap: yNameGap,
      axisLabel: {
        show: true,
      },
    },
    series: data.map((serie) => ({
      type: "line",
      data: xAxisData.map((x) => {
        const correspondentDataForMonth = serie.data.find(({ month }) => month === x);
        return correspondentDataForMonth?.failureCount || null;
      }),
      name: serie.label,
      stack: "Total",
    })),
  };
};

export const ClusterDateVsFailureCount = ({
  setAffectedPvins,
  setLoading,
  setModalView,
}: ClusterDateVsFailureCountProps) => {
  const { clusterId } = Route.useParams();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [zoomRange, setZoomRange] = useState<{ from: string; to: string }>();
  const [clickedData, setClickedData] = useState<{
    monthsInService: MonthsInService | undefined;
    month: string | undefined;
  }>();

  const { data, isLoading, isFetching } = useFailureCountByMonthsInServiceQuery(
    {
      clusterId,
      filter: globalFilter,
      dateRange: zoomRange,
    },
    {
      enabled: !!clusterId,
    }
  );
  const { data: pvins } = useFailureCountByMonthsInServicePvinsQuery(
    {
      clusterId,
      filter: globalFilter,
      monthsInService: clickedData?.monthsInService,
      selectedMonth: clickedData?.month,
      dateRange: zoomRange,
    },
    {
      enabled: !!clusterId && !!clickedData,
    }
  );

  useEffect(() => {
    if (pvins) {
      setLoading(false);
      setClickedData(undefined);
      setAffectedPvins(pvins.filteredQuery.failureCountByMonthsInServicePvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const deliveryDates = useMemo(
    () => data?.filteredQuery.failureCountByMonthsInService.map((d) => d.data.map(({ month }) => month)).flat() || [],
    [data]
  );

  const handleBackgroundClick = (params: any, c: EChartsInstance) => {
    let pointInPixel = [params.offsetX, params.offsetY];
    let pointInGrid = c.convertFromPixel("grid", pointInPixel);
    let category = c.getModel().get("xAxis")[0].data[pointInGrid[0]];
    const categoryData = data?.filteredQuery.failureCountByMonthsInService
      .map((group) => {
        let mis = group.label;
        const dataGroupFilter = group.data.filter((d) => {
          const monthYearGroup = dayjs(d.month).format("MM/YYYY");
          return category === monthYearGroup;
        });
        if (dataGroupFilter.length) return { mis, data: dataGroupFilter };
      })
      .filter((d) => !!d)
      .flat()[0];

    if (categoryData) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setClickedData({
        monthsInService: MonthsInServicesMap[categoryData.mis],
        month: categoryData.data[0].month,
      });
    }
  };

  const getAllPvinsInGraph = () => {
    setLoading(true);
    setModalView("vehiclesAffected");
    setClickedData({ month: undefined, monthsInService: undefined });
  };

  return (
    <BasicWidget
      title={
        <ClusterViewGraphHeader>
          <div>{toTitleCase("Production Date vs Fault Count")}</div>
          {zoomRange && (
            <ClusterChartButtonsContainer>
              <Button disabled={isLoading} onClick={() => getAllPvinsInGraph()}>
                View Vehicles
              </Button>
              <Button onClick={() => setZoomRange(undefined)}>Reset Graph</Button>
            </ClusterChartButtonsContainer>
          )}
        </ClusterViewGraphHeader>
      }
    >
      <BaseEChart
        option={buildChartConfig(data?.filteredQuery.failureCountByMonthsInService ?? [], deliveryDates)}
        onChartReady={(c) => {
          c.getZr().on("click", (params: any) => handleBackgroundClick(params, c));
          c.dispatchAction({
            type: "takeGlobalCursor",
            key: "dataZoomSelect",
            dataZoomSelectActive: true,
          });
        }}
        showLoading={isFetching || isLoading}
        onEvents={{
          datazoom: (zoomData: { batch: { endValue: number; startValue: number }[] }) => {
            const startValue = zoomData.batch[0]?.startValue;
            const endValue = zoomData.batch[0]?.endValue;
            const from = deliveryDates?.[startValue];
            const to = deliveryDates?.[endValue];
            if (from && to) {
              setZoomRange({ from, to });
            }
          },
        }}
      />
    </BasicWidget>
  );
};
