import { Expression } from "@lib/src/expression/types";
import styled from "styled-components";
import { PartialDeep } from "type-fest";

import { PatternFocusedNode } from "./PatternFocusedNode";

const NullNodeContainer = styled.div`
  color: ${({ theme }) => theme.colors.blueGray};
  font-style: italic;
`;

type PatternNullNodeProps = {
  node: PartialDeep<Expression>;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};
const PatternNullNode = ({ node, onChange, path, disabled }: PatternNullNodeProps) => {
  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <NullNodeContainer>NULL</NullNodeContainer>
    </PatternFocusedNode>
  );
};

export default PatternNullNode;
