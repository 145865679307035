import debounce from "lodash/debounce";
import { useCallback, useRef, useState } from "react";

/** Object for the component client inject a few of its inner behaviors. */
export interface UseRemoteFilteringParams {
  /** "Teaches" the hook what are the allowed field names. */
  fields: TFilteringFieldName[];

  /** Initializer instead of empty record for `filteringOptions` on hook result `UseRemoteFilteringResult`. */
  initialFilteringOptions?: UseRemoteFilteringResult["filteringOptions"];

  /** Initializer instead of empty record for `filteringSelecteds` on hook result `UseRemoteFilteringResult`. */
  initialFilteringSelecteds?: UseRemoteFilteringResult["filteringSelecteds"];
}

/** Control object for the component client to actively use, dispatching actions and listening to stateful variables. */
export interface UseRemoteFilteringResult {
  /** What's currently being searched -- not 1:1 in sync with field inputs, because its changes are debounced. */
  currentlySearching: { field: TFilteringFieldName; content: FilteringFieldOption["label"] } | null;
  /** After using `currentlySearching` to query filtering options, they must be set by this `setSearchedFilteringOptions` callback. */
  setSearchedFilteringOptions: (searched: { field: TFilteringFieldName; options: FilteringFieldOption[] }) => void;

  /** Stateful filter options currently available for each field, so client component display for the user to see and later select one or more. */
  filteringOptions: Record<TFilteringFieldName, FilteringFieldOption[]>;
  /** To (maybe) trigger a remote search for a filtering option ("maybe" because it's debounced). */
  handleFilterInputChange: (field: TFilteringFieldName, typing: FilteringFieldOption["label"]) => void;
  /** Same as ´handleFilterInputChange` but not debounced. Do not fire this based on human key strokes or something like that. */
  handleFilterInputChangeUndebounced: (field: TFilteringFieldName, typing: FilteringFieldOption["label"]) => void;

  /** Stateful relation for what filters are really selected and must be included on actual data query. */
  filteringSelecteds: Record<TFilteringFieldName, FilteringFieldOption["value"][]>;
  /** Trigger commands to `filteringSelecteds`, adding a new checked filter. */
  addSelectedFiltering: (field: TFilteringFieldName, selecting: FilteringFieldOption["value"]) => void;
  /** Same as `addSelectedFiltering` but for multiple selections. */
  addManySelectedFiltering: (field: TFilteringFieldName, selecting: FilteringFieldOption["value"][]) => void;
  /** Trigger commands to `filteringSelecteds`, removing a former checked filter. */
  rmSelectedFiltering: (field: TFilteringFieldName, selecteds: FilteringFieldOption["value"]) => void;
  /** Same as `rmSelectedFiltering` but for multiple selections. */
  rmManySelectedFiltering: (field: TFilteringFieldName, selecteds: FilteringFieldOption["value"][]) => void;
  /** Trigger commands to ``filteringSelecteds` so it is returned to an emptied state. */
  clearFiltering: () => void;
}

export interface FilteringFieldOption {
  /** Unique identification for the option. */
  value: string | number | undefined;
  /** Human redable form of the option. */
  label: string;
}

/** A field name is often a column name for a (database or UI) table. */
export type TFilteringFieldName = string;

/**
 * Hook made to basic control and state management of filtering data by some remote API.
 *
 * It's very agnostic to libs, so it can be used with a table, a listing, anything,
 * most of its inner behavior is dictated by the dependency injection in `params`
 * and callbacks in its result.
 *
 * Params must not change over re-renderings, consider them the initial hook setup,
 * because network optimizations depends on such assumption.
 *
 * Worth mentioning, this hook doesn't actually apply any filtering to the data,
 * (i.e., there's no network side effect here)
 * that's relied on the client component to do so, because it depends on what data query hook
 * is being used, such data query might be affected by other hooks and so many stuff out of scope.
 */
export function useRemoteFiltering(params: UseRemoteFilteringParams): UseRemoteFilteringResult {
  const initialParamsRef = useRef(params);
  const initialParams = initialParamsRef.current;
  if (initialParams.fields.length !== params.fields.length) {
    console.warn("Improper usage of `useRemoteFiltering`, initial fields are changing.");
  }

  const [currentlySearching, setCurrentlySearching] = useState<{
    field: TFilteringFieldName;
    content: FilteringFieldOption["label"];
  } | null>(null);

  const [filteringOptions, setFilteringOptions] = useState(
    makeInitialFilteringOptions(initialParams.fields, initialParams.initialFilteringOptions)
  );
  const setSearchedFilteringOptions = useCallback(
    (searched: { field: TFilteringFieldName; options: FilteringFieldOption[] }) =>
      setFilteringOptions((s) => ({ ...s, [searched.field]: searched.options })),
    []
  );

  const [filteringSelecteds, setFilteringSelecteds] = useState(
    makeInitialFilteringSelecteds(initialParams.fields, initialParams.initialFilteringSelecteds)
  );
  const clearFiltering = useCallback(
    () =>
      setFilteringSelecteds(
        makeInitialFilteringSelecteds(initialParams.fields, initialParams.initialFilteringSelecteds)
      ),
    [initialParams]
  );

  const addSelectedFiltering = useCallback(
    (field: TFilteringFieldName, selecting: FilteringFieldOption["value"]) =>
      setFilteringSelecteds((state) =>
        field in state
          ? {
              ...state,
              [field]: [...state[field], selecting],
            }
          : state
      ),
    []
  );
  const addManySelectedFiltering = useCallback(
    (field: TFilteringFieldName, selectings: FilteringFieldOption["value"][]) =>
      setFilteringSelecteds((state) =>
        field in state
          ? {
              ...state,
              [field]: [...state[field], ...selectings],
            }
          : state
      ),
    []
  );

  const rmSelectedFiltering = useCallback(
    (field: TFilteringFieldName, unselecting: FilteringFieldOption["value"]) =>
      setFilteringSelecteds((state) =>
        field in state
          ? {
              ...state,
              [field]: state[field].filter((alreadySelected: unknown) => alreadySelected !== unselecting),
            }
          : state
      ),
    []
  );
  const rmManySelectedFiltering = useCallback(
    (field: TFilteringFieldName, unselectings: FilteringFieldOption["value"][]) =>
      setFilteringSelecteds((state) =>
        field in state
          ? {
              ...state,
              [field]: state[field].filter(
                (alreadySelected: unknown) => !(unselectings as unknown[]).includes(alreadySelected)
              ),
            }
          : state
      ),
    []
  );

  const handleFilterInputChangeRef = useRef(
    debounce(
      (field: TFilteringFieldName, typing: FilteringFieldOption["label"]) =>
        setCurrentlySearching({ field, content: normalizeSearchContent(typing) }),
      500
    )
  );
  const handleFilterInputChange = handleFilterInputChangeRef.current;

  const handleFilterInputChangeUndebounced = useCallback(
    (field: TFilteringFieldName, typing: FilteringFieldOption["label"]) =>
      setCurrentlySearching({ field, content: normalizeSearchContent(typing) }),
    []
  );

  return {
    currentlySearching,
    setSearchedFilteringOptions,
    filteringOptions,
    handleFilterInputChange,
    handleFilterInputChangeUndebounced,
    filteringSelecteds,
    addSelectedFiltering,
    addManySelectedFiltering,
    rmSelectedFiltering,
    rmManySelectedFiltering,
    clearFiltering,
  };
}

function makeInitialFilteringOptions(
  fields: TFilteringFieldName[],
  initialFilteringOptions?: UseRemoteFilteringResult["filteringOptions"]
): Record<TFilteringFieldName, FilteringFieldOption[]> {
  const emptyRecord = fields.reduce((acc, field) => ({ ...acc, [field]: [] }), {});
  return initialFilteringOptions ? { ...emptyRecord, ...initialFilteringOptions } : emptyRecord;
}

function makeInitialFilteringSelecteds(
  fields: TFilteringFieldName[],
  initialFilteringSelecteds?: UseRemoteFilteringResult["filteringSelecteds"]
): Record<TFilteringFieldName, FilteringFieldOption["value"][]> {
  const emptyRecord = fields.reduce((acc, field) => ({ ...acc, [field]: [] }), {});
  return initialFilteringSelecteds ? { ...emptyRecord, ...initialFilteringSelecteds } : emptyRecord;
}

function normalizeSearchContent(content: string) {
  return content?.trim()?.toLocaleLowerCase();
}
