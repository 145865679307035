import _ from "lodash";

import { GlobalFilterTypes } from "@/contexts/GlobalFilterContext";
import { VehicleStyles } from "@/utils/types/commonDataDictionary";
import { spreadModelStyleOptions } from "@/utils/vehicleModel";

export type GlobalFilterOption = { label: string | number; value: string | number };

export const onFilterSelectChange = (
  customerStyles: Record<string, VehicleStyles>,
  localFilter: GlobalFilterTypes,
  filterEntity: keyof GlobalFilterTypes,
  filterKey: string,
  values: (string | number)[]
) => {
  if (filterKey === "modelStyle") {
    values = spreadModelStyleOptions((values || []) as string[]);
  }
  const newFilters = {
    ...localFilter,
    ...{ [filterEntity]: { ...localFilter[filterEntity], ...{ [filterKey]: values } } },
  };

  if (newFilters["vehicle"].model.length === 0) {
    newFilters["vehicle"].modelStyle = [];
  } else if (filterKey === "model") {
    const prevModels = localFilter.vehicle.model;
    const removedModels =
      prevModels.length && prevModels.length > values.length ? _.difference(prevModels, values) : [];

    if (removedModels.length) {
      // This update the model types
      values.forEach((model) => {
        const customerModelStyles = Object.keys(customerStyles[model].style ?? []);
        const currentModelStyles = newFilters["vehicle"].modelStyle;
        const filterModelStyles = currentModelStyles.filter((style) => customerModelStyles.includes(style));
        newFilters["vehicle"].modelStyle = filterModelStyles;
      });
    }
  }

  return newFilters;
};
