import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import { getSignificantNumber } from "@lib/src/numberUtils";
import dayjs from "dayjs";
import { CustomSeriesRenderItemAPI, CustomSeriesRenderItemParams, EChartsType, SeriesOption } from "echarts";
import { EChartsReactProps } from "echarts-for-react";

import { TimeSeriesField, TimeSeriesFieldFamily } from "@/api";
import {
  AggregateType,
  Fault,
  IndependentVarRangeInput,
  IntervalInput,
  RepairEventHistory,
  TimeIntervalUnit,
  TimeSeriesIndependentVar,
  VehicleCohortTimeSeriesData,
} from "@/api/customerApi";
import {
  ChartAxisGroup,
  CohortComparisonPlotOption,
  CustomChartSeries,
  LibraryChart,
} from "@/contexts/ChartLibraryStore";
import { toArray } from "@/utils/arrayUtils";
import { parseDateToStr } from "@/utils/dateUtils";
import { compactFormatNumber, formatNumber, simpleFormatNumber } from "@/utils/numberUtils";

import {
  ANOMALY_CAUTION_COLOR,
  ANOMALY_SAFE_COLOR,
  ANOMALY_WARNING_COLOR,
} from "../anomaly/singleCar/common/anomalyColors";
import { buildAnomalyMarkLines } from "../anomaly/singleCar/common/buildAnomalyMarkLines";
import {
  chartLibraryDefaultTooltipFormatter,
  chartLibraryFaultTooltipFormatter,
  chartLibraryPatternTooltipFormatter,
  chartLibraryTimeSeriesTooltipFormatter,
} from "./ChartLibraryBuildUtilsTooltips";
import { FaultSeriesValue, RepairSeriesValue } from "./types";

export const repairsSeriesName = "repairs";

export const getSignalFriendlyName = (signalId: string, fields: TimeSeriesField[]) => {
  const matchingField = fields.find((field) => field.id === signalId);
  return buildFieldChartLegend({
    fieldId: signalId,
    family: matchingField?.family && TimeSeriesFieldFamily.Signal,
    displayName: matchingField?.displayName ?? "",
  });
};

export const mapSeriesFriendlyNames = (series: CustomChartSeries[], fields: TimeSeriesField[]) => {
  return series.map((s) => {
    if (s.comparisonSeries) return s;
    const resultLegend = getSignalFriendlyName(s.id, fields);
    return { ...s, name: resultLegend };
  });
};

export const GRAPH_COLORS = [
  "#6929C5",
  "#F90",
  "#2AC18B",
  "#4290E1",
  "#265C5C",
  "#922752",
  "#E75A5C",
  "#4F0E0D",
  "#0D2D96",
  "#DD5E8A",
  "#AB882C",
  "#459B99",
  "#0C2646",
  "#803D14",
  "#9D71F7",
  "#3A404A",
  "#DF0000",
] as const;

export const getPlotColorByIndex = (index: number) => {
  const normalizedIndex = ((index % GRAPH_COLORS.length) + GRAPH_COLORS.length) % GRAPH_COLORS.length;
  return GRAPH_COLORS[normalizedIndex];
};

export const chartSeriesSorter = (a: CustomChartSeries, b: CustomChartSeries) =>
  a.id > b.id ? 1 : b.id > a.id ? -1 : 0;

export const buildFieldChartLegend = ({
  fieldId,
  displayName,
  aggregation,
  family,
}: {
  fieldId: string;
  family?: TimeSeriesFieldFamily;
  displayName?: string;
  aggregation?: AggregateType | string;
}) => {
  const idSegments = fieldId.split(".");
  const tempName = displayName ?? idSegments[idSegments.length - 1];

  const prefix = family === TimeSeriesFieldFamily.AnomalyScore ? `Anomaly ` : "";
  return `${prefix}${tempName}${aggregation ? ` - ${aggregation}` : ""}`;
};

const getStddevBaseSeriesData = (
  seriesIndex: number,
  series: CustomChartSeries,
  comparisonType?: CohortComparisonPlotOption,
  cohortTimeSeriesData?: VehicleCohortTimeSeriesData
): (number | null)[] => {
  const singleComparison = toArray(series.comparisonType).find((e) => e !== comparisonType);
  if (singleComparison === "median") {
    return cohortTimeSeriesData?.median[seriesIndex] ?? [];
  } else if (singleComparison === "mean") {
    return cohortTimeSeriesData?.mean[seriesIndex] ?? [];
  }
  return [];
};

export const buildComparisonTimeSeries = (
  timeSeries: CustomChartSeries[],
  cohortTimeSeriesData?: VehicleCohortTimeSeriesData
): CustomChartSeries[] =>
  timeSeries.flatMap((s, i) => {
    return toArray(s.comparisonType).flatMap((comparison) => {
      const stddevSeriesValues = getStddevBaseSeriesData(i, s, comparison, cohortTimeSeriesData);
      const stddevValues = cohortTimeSeriesData?.stddev[i] ?? [];
      switch (comparison) {
        case "top-bottom-quartile":
          return [
            {
              ...s,
              id: `${s.id}_Hi`,
              name: `25th ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "upper-boundary",
              comparisonLabel: "25th",
              data: cohortTimeSeriesData?.bottomQuartile[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
            {
              ...s,
              id: `${s.id}_Lo`,
              name: `75th ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "lower-boundary",
              comparisonLabel: "75th",
              data: cohortTimeSeriesData?.topQuartile[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
          ] as CustomChartSeries[];
        case "top-bottom-5perc":
          return [
            {
              ...s,
              id: `${s.id}_Hi`,
              name: `5th ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "upper-boundary",
              comparisonLabel: "5th",
              data: cohortTimeSeriesData?.top5Perc[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
            {
              ...s,
              id: `${s.id}_Lo`,
              name: `95th ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "lower-boundary",
              comparisonLabel: "95th",
              data: cohortTimeSeriesData?.bottom5Perc[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
          ] as CustomChartSeries[];
        case "stddev":
          return [
            {
              ...s,
              id: `${s.id}_Hi`,
              name: `+stddev ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "upper-boundary",
              comparisonLabel: "+dev",
              data: stddevSeriesValues.map((value, j) => {
                if (!value || !stddevValues[j]) return 0;
                return getSignificantNumber(value + stddevValues[j]!);
              }),
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
            {
              ...s,
              id: `${s.id}_Lo`,
              name: `-stddev ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "lower-boundary",
              comparisonLabel: "-dev",
              data: stddevSeriesValues.map((value, j) => {
                if (!value || !stddevValues[j]) return 0;
                return getSignificantNumber(value - stddevValues[j]!);
              }),
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
          ] as CustomChartSeries[];
        case "mean":
          return [
            {
              ...s,
              name: `mean ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "single",
              comparisonLabel: "fleet mean",
              data: cohortTimeSeriesData?.mean[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
          ] as CustomChartSeries[];
        case "median":
          return [
            {
              ...s,
              name: `median ${s.name}`,
              comparisonType: undefined,
              comparisonSeries: "single",
              comparisonLabel: "median",
              data: cohortTimeSeriesData?.median[i] || [],
              xValues: cohortTimeSeriesData?.x ?? [],
              baseName: s.name,
            },
          ] as CustomChartSeries[];
        default:
          return [];
      }
    });
  });

export const xAxisLabelFormatter = (
  value: any,
  indVar: TimeSeriesIndependentVar,
  interval: IntervalInput | null | undefined
) => {
  if (indVar === TimeSeriesIndependentVar.Mileage) {
    return compactFormatNumber(+value);
  }
  const valueToParse = value ? new Date(+value) : new Date();
  if (!interval) {
    return parseDateToStr(new Date(valueToParse));
  }
  if (
    interval.timeUnit === TimeIntervalUnit.Minute ||
    (interval.timeUnit === TimeIntervalUnit.Hour && interval.distance <= 1)
  ) {
    return parseDateToStr(new Date(valueToParse), true);
  }
  if (
    (interval.timeUnit === TimeIntervalUnit.Hour && interval.distance > 1) ||
    (interval.timeUnit === TimeIntervalUnit.Day && interval.distance <= 1)
  ) {
    return parseDateToStr(valueToParse);
  }
  // TODO: Make format with useLocaleFormat properly.
  return dayjs(valueToParse).format("MM/YYYY");
};

export const yAxisLabelFormatter = (value: any) => {
  return compactFormatNumber(+value);
};

export const tooltipXAxisValue = (value: any, indVar: TimeSeriesIndependentVar) => {
  if (indVar === TimeSeriesIndependentVar.Mileage) {
    return `${formatNumber(+value)} km`;
  }
  const valueToParse = value ? new Date(+value) : new Date();
  return parseDateToStr(new Date(valueToParse), true);
};

export const findAxisGroupIndex = (groupId: string, groups: ChartAxisGroup[]) => {
  const matchIndex = groups.findIndex((g) => g.id === groupId);
  return matchIndex >= 0 ? matchIndex : 0;
};

export const initChartZoomMode = (chart?: EChartsType) => {
  chart?.dispatchAction({
    type: "takeGlobalCursor",
    key: "dataZoomSelect",
    dataZoomSelectActive: true,
  });
};

// Builds an x-axis set of values mixing and ordering x-axis values from both mainSeries and comparison series
// If rangeInput is available it will trim the axisValues to fit inside the range
export const buildTimeSeriesXAxisValues = (
  mainSeriesAxis: number[],
  comparisonSeriesAxis: number[],
  rangeInput?: IndependentVarRangeInput
): number[] => {
  const axisValues = [...new Set([...mainSeriesAxis, ...comparisonSeriesAxis])].sort((a, b) => a - b);
  if (!rangeInput) return axisValues;

  const startIndex = axisValues.findIndex((val) => val >= rangeInput.min);
  // Array.slice's end is exclusive but we want to still include values within range
  const endIndex = axisValues.findIndex((val) => val >= rangeInput.max) + 1;

  return axisValues.slice(startIndex >= 0 ? startIndex : 0, endIndex > 0 ? endIndex : undefined);
};

const buildComparisonSeriesAreas = (
  comparisonSeries: CustomChartSeries[],
  _mainSeriesIds: string[],
  xAxisData: number[],
  axisGrops: ChartAxisGroup[],
  acquireColor: (name: string) => string
) => {
  let areasResult: SeriesOption[] = [];
  if (!comparisonSeries.length || comparisonSeries.length % 2 !== 0) {
    return areasResult;
  }
  for (let i = 0; i < comparisonSeries.length; i += 2) {
    areasResult.push({
      type: "custom",
      renderItem: (params: CustomSeriesRenderItemParams, api: CustomSeriesRenderItemAPI) => {
        if (params.context.rendered) {
          return;
        }
        params.context.rendered = true;
        const seriesToPolygonData = (s: CustomChartSeries) => {
          const isAnomalyScore = s.TimeSeriesFieldInput.family == TimeSeriesFieldFamily.AnomalyScore;
          const mapper = isAnomalyScore
            ? (d: number | null, j: number) => [s.xValues[j], isNotNullOrUndefined(d) ? d * 100 : d]
            : (y: number | null, i: number) => [s.xValues[i], y];
          return s.data.map(mapper);
        };
        const polygonData = [
          ...comparisonSeries.map(seriesToPolygonData)[i],
          ...comparisonSeries.map(seriesToPolygonData)[i + 1].reverse(),
        ];

        let points: number[][] = [];
        for (let i = 0; i < polygonData.length; i++) {
          points.push(api.coord(polygonData[i]));
        }

        const areaColor = acquireColor(comparisonSeries[i].TimeSeriesFieldInput.id);

        return {
          type: "polygon",
          transition: "shape",
          shape: {
            points: points,
          },
          style: {
            fill: areaColor,
            fillOpacity: 0.1,
          },
          emphasis: {
            style: {
              fill: areaColor,
              fillOpacity: 0.25,
            },
          },
        };
      },
      yAxisIndex: findAxisGroupIndex(comparisonSeries[i].yAxisId, axisGrops),
      clip: true,
      data: new Array(xAxisData.length),
    });
  }
  return areasResult;
};

export const buildLibraryFaultChartConfig = (
  chart: LibraryChart,
  faults: Fault[],
  indVar: TimeSeriesIndependentVar
): EChartsReactProps => {
  const groupsLength = chart.axisGroups.length;
  const haveFaults = !!faults.length;

  const faultChartData = faults.map((f) => {
    return [
      indVar === TimeSeriesIndependentVar.Mileage ? f.odometer : new Date(f.startTime!).getTime(),
      f.code ?? "",
      f,
    ] satisfies FaultSeriesValue;
  });

  const axisYLabelFormatted = (value: string) => {
    // Find the fault value by the code
    const fault = faults.find((f) => f.code === value);

    return `${fault?.code} ${fault?.troubleCode}`;
  };

  return {
    option: {
      backgroundColor: "#f9fafe",
      grid: [
        {
          top: 0,
          right: groupsLength > 1 ? (groupsLength - 1) * 75 : 50,
          bottom: 0,
          left: haveFaults ? 135 : 75,
          // Faults tooltip
          tooltip: {
            trigger: "item",
            padding: 0,
            className: "chart-library-tooltip-container",
            borderColor: "transparent",
            formatter: (params: Object | Array<Object>) => chartLibraryFaultTooltipFormatter(params, indVar),
          },
        },
      ],
      textStyle: {
        color: "#333",
        fontFamily: "Inter",
      },
      // zoom tool is there but it's not visible
      toolbox: {
        orient: "vertical",
        itemSize: 20,
        top: -275,
        itemGap: 15,
        feature: {
          dataZoom: { iconStyle: { opacity: 0 } },
          restore: {},
        },
        show: false,
      },
      dataZoom: [
        {
          type: "inside",
          zoomOnMouseWheel: "shift",
          preventDefaultMouseMove: false,
          disabled: false,
          xAxisIndex: [0, 1],
          show: false,
        },
      ],
      // Series tooltip
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        padding: 0,
        className: "chart-library-tooltip-container",
        appendToBody: true,
        position: (
          point: [number, number],
          _params: any,
          dom: any,
          _rect: any,
          size: { contentSize: [number, number]; viewSize: [number, number] }
        ) => {
          let posX = point[0];
          const chartWidth = size.viewSize[0];
          const tooltipWidth = size.contentSize[0];
          const offsetX = 10;
          if (posX + tooltipWidth + offsetX > chartWidth) {
            // tool tip will be off screen to the right
            return [posX - tooltipWidth - offsetX, point[1] - 10];
          }
          return [point[0] + 10, point[1] - 10];
        },
        formatter: (params: Object | Array<Object>) => chartLibraryFaultTooltipFormatter(params, indVar),
      },
      xAxis: [
        {
          type: indVar === TimeSeriesIndependentVar.Time ? "time" : "value",
          min: chart.axisData.data[0],
          max: chart.axisData.data.at(-1),
          height: 500,
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          gridIndex: 0,
        },
      ],
      yAxis: [
        {
          type: "category",
          name: "faultAxis",
          position: "left",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          gridIndex: 0,
          axisLabel: {
            formatter: axisYLabelFormatted,
          },
        },
      ],
      series: [
        // Fault elements
        {
          type: "scatter",
          cursor: "pointer",
          name: "faults",
          itemStyle: {
            color: "#5F5F5F",
            opacity: 0.8,
          },
          xAxisIndex: 0,
          yAxisIndex: 0,
          emphasis: {
            itemStyle: {
              cursor: "pointer",
              color: "#FFAC40",
              opacity: 1,
            },
          },
          data: faultChartData,
        },
      ],
    },
  };
};

type ChartConfigProps = {
  chart: LibraryChart;
  faults: Fault[];
  repairs: RepairEventHistory[];
  indVar: TimeSeriesIndependentVar;
  indInterval: IntervalInput | null | undefined;
  acquireColor: (name: string) => string;
  preview?: boolean;
  showXAxis?: boolean;
};

export const buildLibraryDefaultChartConfig = ({
  chart,
  faults,
  repairs,
  indVar,
  indInterval,
  acquireColor,
  preview,
  showXAxis,
}: ChartConfigProps): EChartsReactProps => {
  const mainSeries = chart.series.filter((s) => !s.comparisonSeries);
  const mainSeriesIds = mainSeries.map((s) => s.id);
  const comparisonBoundaries = chart.series.filter((s) => s.comparisonSeries && s.comparisonSeries !== "single");
  const groupsLength = chart.axisGroups.length;
  const haveFaults = !!faults.length;

  // The y-axis value is hardcoded to the axis max (1) so it shows at the top
  const repairsChartData = repairs.map((r) => {
    return [
      indVar === TimeSeriesIndependentVar.Mileage ? r.odometer || 0 : new Date(r.endDate!).getTime(),
      1,
      r,
    ] satisfies RepairSeriesValue;
  });

  return {
    option: {
      backgroundColor: "#f9fafe",
      grid: [
        {
          top: 50,
          right: groupsLength > 1 ? (groupsLength - 1) * 75 : 50,
          bottom: 50,
          left: haveFaults ? 135 : 75,
        },
      ],
      textStyle: {
        color: "#5F5F5F",
        fontFamily: "Hubot Sans",
      },
      // zoom tool is there but it's not visible
      toolbox: {
        orient: "vertical",
        itemSize: 20,
        top: -275,
        itemGap: 15,
        feature: {
          dataZoom: { iconStyle: { opacity: 0 } },
          restore: {},
        },
        show: !preview,
      },
      dataZoom: [
        {
          type: "inside",
          zoomOnMouseWheel: "shift",
          preventDefaultMouseMove: false,
          disabled: preview,
          xAxisIndex: [0, 1],
        },
      ],
      // Series tooltip
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        padding: 0,
        className: "chart-library-tooltip-container",
        appendToBody: true,
        formatter: (params: Object | Array<Object>) => {
          return chartLibraryDefaultTooltipFormatter(
            params,
            chart.series.map((s) => {
              return {
                ...s,
                data: [],
              };
            }),
            indVar
          );
        },
      },
      xAxis: [
        {
          type: indVar === TimeSeriesIndependentVar.Time ? "time" : "value",
          offset: 0,
          nameGap: 30,
          nameTextStyle: {
            fontWeight: "bold",
          },
          min: chart.axisData.data[0],
          max: chart.axisData.data.at(-1),
          axisTick: {
            show: false,
          },
          axisLine: showXAxis
            ? {
                lineStyle: {
                  color: "#5f5f5f",
                },
              }
            : {
                show: false,
              },
          axisLabel: {
            formatter: (value: any) => xAxisLabelFormatter(value, indVar, indInterval),
            interval: indVar === TimeSeriesIndependentVar.Mileage ? 20 : 30,
          },
          splitLine: {
            show: false,
          },
          gridIndex: 0,
        },
      ],
      yAxis: [
        ...chart.axisGroups.map((group, i) => {
          const groupSeriesUnits = mainSeries.filter((s) => s.yAxisId === group.id)?.map((s) => s.unit);
          // Use  the series unit only if all series in the group have the same unit
          let groupUnitForAxis =
            groupSeriesUnits && groupSeriesUnits.every((unit) => unit === groupSeriesUnits[0])
              ? groupSeriesUnits[0]
              : undefined;

          if (groupUnitForAxis === "Kmh") {
            groupUnitForAxis = "km/h";
          }

          return {
            type: "value",
            name: groupUnitForAxis ?? "",
            nameTruncate: {
              maxWidth: 50,
            },
            nameTextStyle: {
              fontWeight: "bold",
            },
            position: i > 0 ? "right" : "left",
            offset: i > 0 ? (i - 1) * 75 : 0,
            scale: true,
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ebebeb",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: (value: any) => yAxisLabelFormatter(value),
              margin: 12,
            },
            gridIndex: 0,
          };
        }),
        // Repairs Axis
        {
          type: "value",
          name: "repairAxis",
          show: false,
          max: 1,
          alignTicks: true,
          gridIndex: 0,
        },
      ],
      visualMap: chart.series.flatMap((s, i) =>
        s.anomalyThreshold
          ? [
              {
                show: false,
                seriesIndex: i,
                pieces: [
                  {
                    min: s.anomalyThreshold!.caution * 100,
                    max: s.anomalyThreshold!.warning * 100,
                    color: ANOMALY_CAUTION_COLOR,
                  },
                  {
                    min: s.anomalyThreshold!.warning * 100,
                    color: ANOMALY_WARNING_COLOR,
                  },
                ],
                outOfRange: {
                  color: ANOMALY_SAFE_COLOR,
                },
              },
            ]
          : []
      ),
      series: [
        // Standard time series sparklines
        ...chart.series.map((seriesElement) => {
          const isAnomalyScore = seriesElement.TimeSeriesFieldInput.family == TimeSeriesFieldFamily.AnomalyScore;
          const mapper = isAnomalyScore
            ? (y: number | null, i: number) => [seriesElement.xValues[i], isNotNullOrUndefined(y) ? y * 100 : y]
            : (y: number | null, i: number) => [seriesElement.xValues[i], y];
          return {
            type: "line",
            symbol: "none",
            name: seriesElement.name,
            data: seriesElement.data.map(mapper),
            xAxisIndex: 0,
            yAxisIndex: findAxisGroupIndex(seriesElement.yAxisId, chart.axisGroups),
            itemStyle: {
              color: acquireColor(seriesElement.id),
            },
            lineStyle: seriesElement.comparisonSeries
              ? {
                  width: seriesElement.comparisonSeries !== "single" ? 0 : 1,
                  type: seriesElement.comparisonSeries !== "single" ? "solid" : "dashed",
                  opacity: seriesElement.comparisonSeries !== "single" ? 1 : 0.5,
                }
              : {
                  width: 1.5,
                },
            ...(seriesElement.anomalyThreshold
              ? {
                  markLine: buildAnomalyMarkLines(
                    seriesElement.anomalyThreshold.caution * 100,
                    seriesElement.anomalyThreshold.warning * 100,
                    preview ?? false
                  ),
                }
              : {}),
          };
        }),
        // Comparison area series
        ...buildComparisonSeriesAreas(
          comparisonBoundaries,
          mainSeriesIds,
          chart.axisData.data,
          chart.axisGroups,
          acquireColor
        ),
        // Repair elements
        {
          type: "scatter",
          cursor: "pointer",
          name: repairsSeriesName,
          symbol: "triangle",
          symbolSize: 12,
          symbolRotate: 180,
          itemStyle: {
            color: "#4290E1",
            opacity: 0.75,
          },
          xAxisIndex: 0,
          yAxisIndex: chart.axisGroups.length ?? 0,
          emphasis: {
            itemStyle: {
              cursor: "pointer",
              color: "#4290E1",
              opacity: 1,
            },
          },
          data: repairsChartData,
        },
      ],
    },
  };
};

export const buildHistogramChartConfig = (
  intervalNumber: number = 0,
  min: (number | null)[] = [],
  max: (number | null)[] = [],
  pct: number[] = []
) => {
  const minWithoutNulls = min.filter((v): v is number => v !== null);

  return {
    option: {
      title: {
        textStyle: {
          color: "#5F5F5F",
          fontFamily: "Inter",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: (params: any) => chartLibraryPatternTooltipFormatter(params, min, max, pct),
      },
      grid: {
        left: "3%",
        right: "4%",
      },
      xAxis: {
        type: "category",
        axisTick: {
          interval: (index: number, _value: string) =>
            index % intervalNumber === 0 || index === minWithoutNulls.length - 1,
        },
        axisLabel: {
          interval: (index: number, _value: string) =>
            index % intervalNumber === 0 || index === minWithoutNulls.length - 1,
        },
        min: minWithoutNulls[0]?.toFixed(3),
        max: minWithoutNulls[minWithoutNulls.length - 1]?.toFixed(3),
        nameLocation: "center",
        nameGap: 23,
        position: "bottom",
        name: "Value Range",
      },
      yAxis: {
        type: "value",
        name: "% Vehicles",
      },
      series: [
        {
          name: "histogram",
          type: "bar",
          data: minWithoutNulls.map((v, i) => [v.toFixed(3), pct[i] * 100]),
          emphasis: {
            itemStyle: {
              color: "#FFAC40",
            },
          },
          itemStyle: {
            color: "#5F5F5F",
          },
        },
      ],
    },
  };
};

export const buildTimeSeriesChartConfig = (
  x: number[] = [],
  y: (number | null)[] = [],
  min: number[] = [],
  max: number[] = [],
  vehicleCount: number[] = []
) => {
  const buildComparisonsSeries = (min: number[], max: number[], x: number[]): SeriesOption => {
    return {
      type: "custom",
      renderItem: (params, api: CustomSeriesRenderItemAPI) => {
        if (params.context.rendered) {
          return;
        }
        params.context.rendered = true;
        const points = [
          ...(max.map((d, j) => [x[j], d!]) ?? []),
          ...(min.map((d, j) => [x[j], d!]) ?? []).reverse(),
        ].map((d) => api.coord(d));

        return {
          type: "polygon",
          transition: "shape",
          shape: {
            points: points,
          },
          style: {
            fill: getPlotColorByIndex(0),
            fillOpacity: 0.1,
          },
          emphasis: {
            style: {
              fill: getPlotColorByIndex(0),
              fillOpacity: 0.25,
            },
          },
        };
      },
      silent: true,
      clip: true,
      data: new Array(x.length),
    };
  };
  return {
    option: {
      title: {
        textStyle: {
          color: "#323232",
          fontFamily: "Inter",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "axis",
        className: "chart-library-tooltip-container",
        formatter: (params: { dataIndex: number }[]) => {
          const i = params[0].dataIndex;
          return chartLibraryTimeSeriesTooltipFormatter(x[i], x[i + 1], y[i] ?? 0, min[i], max[i], vehicleCount[i]);
        },
      },
      xAxis: [
        {
          type: "value",
          data: x.map((v) => Math.floor(v)),
          nameLocation: "center",
          nameGap: 25,
          position: "bottom",
          name: "kms",
          axisLabel: {
            formatter: (value: number) => {
              return formatNumber(Math.floor(value / 100) * 100);
            },
          },
          max: Math.ceil(Math.max(...x)),
        },
      ],
      grid: [
        { left: "6%", top: "40", width: "90%", height: "400" },
        { left: "5%", top: "440", width: "90%", height: "60" },
      ],
      yAxis: [
        {
          gridIndex: 0,
          type: "value",
          min: Math.floor(Math.min(...min)),
          max: Math.ceil(Math.max(...max)),
        },
      ],
      series: [
        {
          type: "line",
          xAxisIndex: 0,
          yAxisIndex: 0,
          name: "Value1",
          showSymbol: false,
          color: getPlotColorByIndex(0),
          colorBy: "series",
          data: x.map((x, i) => [x, y[i]]),
          connectNulls: false,
          lineStyle: {
            color: getPlotColorByIndex(0),
          },
        },
        buildComparisonsSeries(min, max, x),
      ],
    },
  };
};

export const buildExpressionEventChartConfig = (
  count: number[][] = [[]],
  x: number[] = [],
  y: (number | null)[][] | undefined = [[]],
  independentVar: TimeSeriesIndependentVar | undefined
) => {
  if (!count || !x || !y) return { option: {} };
  let occurrencesRate = y[0] ?? [];
  occurrencesRate = occurrencesRate.filter((v) => v).map((v, i) => (v ?? 0) / count[0][i]);

  return {
    option: {
      tooltip: {
        trigger: "axis",
        formatter: ([params]: any) => {
          const dataIndex: number = params.dataIndex;
          const value = params.value;

          const countFormatter = count[0][dataIndex];
          const xFormatter = x[dataIndex];
          const yFormatter = y[0][dataIndex];

          return `
          <div>
          <div>
            ${value}% (${yFormatter} points out of ${countFormatter})
          </div>
          <div>
            ${
              independentVar === TimeSeriesIndependentVar.Mileage
                ? `${Math.floor(xFormatter)} km`
                : dayjs(xFormatter).format("MM/DD/YY")
            }
          </div>
          </div>`;
        },
      },
      axisPointer: {
        link: [
          {
            xAxisIndex: "all",
          },
        ],
      },
      xAxis: {
        type: "category",
        data: x ?? [],
        nameLocation: "center",
        nameGap: 35,
        name: "kms",
        boundaryGap: false,
        axisLabel: {
          formatter: (value: number, index: number) => {
            return independentVar === TimeSeriesIndependentVar.Mileage
              ? `${simpleFormatNumber(Math.floor(value / 100) * 100)}`
              : dayjs(x[index]).format("MM/DD/YY");
          },
        },
        axisPointer: {
          label: {
            formatter: (params: any) =>
              independentVar === TimeSeriesIndependentVar.Mileage
                ? `${Math.floor(params.value)} km`
                : `${Math.floor(params.value)} km TIME`,
          },
        },
        position: "bottom",
      },
      yAxis: [
        {
          type: "value",
          min: 0,
          max: 100,
          axisLabel: {
            formatter: (value: number) => {
              return `${value}%`;
            },
          },
        },
      ],
      series: [
        {
          type: "line",
          name: "serie",
          connectNulls: false,
          showSymbol: false,
          yAxisIndex: 0,
          data: occurrencesRate.map((v) => Math.floor((v ?? 0) * 100)),
        },
      ],
    },
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
};
