import {
  AggregationExpression,
  AggregationType,
  CaseExpression,
  Expression,
  ExpressionType,
  NumericLiteralType,
} from "@lib/src/expression/types";
import { isEmpty, isEqual } from "lodash";
import { PartialDeep } from "type-fest";

export const setFocusedNode = (
  node: PartialDeep<Expression>,
  focused: boolean,
  typeahead: boolean,
  path: string[],
  mostRecentPath: string[] | undefined,
  setMostRecentPath: (p: string[] | undefined) => void,
  focusRootNode?: boolean
) => {
  // if the current node is focused or the typeahead is active we dont need to look for
  // another node to focus
  if (focused || typeahead || focusRootNode) return false;
  // if the last focused path is this nodes path we try and figure which of its values to focus
  if (isEqual(mostRecentPath, path)) {
    // For an entity set it equal to its parent
    if (
      (node.type === "Signal" ||
        node.type === NumericLiteralType.LiteralNumeric ||
        node.type === ExpressionType.Null) &&
      path.length
    ) {
      return setFocusedPathToParent(path, setMostRecentPath);
    } else if ("values" in node) {
      // values type
      // Try and see if any of its values are empty
      const emptyIndex = node.values?.findIndex((v) => isEmpty(v)) ?? -1;
      if (emptyIndex > -1) {
        // if any values are empty then set current focused node to the first empty value
        setMostRecentPath([...path, "values", `${emptyIndex}`]);
        return true;
      } else {
        if (node.type === "Aggregation") {
          // aggregation has its own special inputs that are not in the values key
          //   they have their own logic
          return handleAggregationFocusing(node, path, setMostRecentPath);
        } else if (path.length) {
          return setFocusedPathToParent(path, setMostRecentPath);
        }
      }
    } else if ("when" in node) {
      // case nodes have multiple child nodes need own logic to figure out the next one
      return handleCaseFocusing(node, path, setMostRecentPath);
    } else if ("value" in node && node.value === 0) {
      setMostRecentPath([...path, "value"]);
      return true;
    }
  }
  return false;
};

const setFocusedPathToParent = (path: string[], setMostRecentPath: (p: string[] | undefined) => void) => {
  const newPath = [...path];
  if (path[path.length - 1] === "else") {
    newPath.pop();
  } else {
    newPath.pop();
    newPath.pop();
  }
  setMostRecentPath(newPath);
  return true;
};

const handleAggregationFocusing = (
  node: PartialDeep<AggregationExpression>,
  path: string[],
  setMostRecentPath: (p: string[] | undefined) => void
) => {
  const newPath =
    node.aggregationType === AggregationType.Percentile && !node.percentile
      ? "percentile"
      : !node.intervalDuration
      ? "duration"
      : !node.intervalType
      ? "type"
      : undefined;
  if (newPath) {
    setMostRecentPath([...path, newPath]);
    return true;
  }
  return false;
};

const handleCaseFocusing = (
  node: PartialDeep<CaseExpression>,
  path: string[],
  setMostRecentPath: (p: string[] | undefined) => void
) => {
  const emptyIndex = node.when?.findIndex((w, i) => isEmpty(w) || isEmpty(node.then?.[i])) ?? -1;
  const emptyElse = isEmpty(node.else);
  const pathToFocus =
    emptyIndex > -1
      ? [...path, isEmpty(node.when?.[emptyIndex]) ? "when" : "then", `${emptyIndex}`]
      : emptyElse
      ? [...path, "else"]
      : undefined;
  if (pathToFocus) {
    setMostRecentPath(pathToFocus);
    return true;
  } else if (path.length) {
    return setFocusedPathToParent(path, setMostRecentPath);
  }
  return false;
};
