import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link } from "@tanstack/react-router";
import { Button } from "antd";
import React, { Dispatch } from "react";

import { FeatureId } from "@/api";
import { VehicleIdentifier } from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import { useFeatureFlags } from "@/utils/features";

import { ClusterModalView } from "./ClusterModal";

type AffectedVehiclesProps = {
  pvins: VehicleIdentifier[];
  setView: Dispatch<React.SetStateAction<ClusterModalView>>;
};

type AffectedVehicleFooterProps = {
  onCancel: () => void;
  createNewCluster: () => void;
};

export const AffectedVehiclesFooter = ({ onCancel, createNewCluster }: AffectedVehicleFooterProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FeatureId.Clusters) ? (
    <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end" }}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={createNewCluster}>
        Create New Cluster
      </Button>
    </div>
  ) : null;
};

const renderLink = (_: any, row: { pvin: string; vin: string }) => {
  return (
    <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
      {row.vin}
    </Link>
  );
};

export const AffectedVehicles = ({ pvins }: AffectedVehiclesProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return (
    <>
      <BasicTable
        columns={
          isFeatureEnabled(FeatureId.Vins)
            ? [
                {
                  key: "vin",
                  title: "VIN",
                  dataIndex: "vin",
                  render: renderLink,
                },
              ]
            : [
                {
                  key: "pvin",
                  title: "PVIN",
                  dataIndex: "pvin",
                  render: renderLink,
                },
              ]
        }
        rowKey={"pvin"}
        dataSource={pvins.map((v) => ({ vin: isFeatureEnabled(FeatureId.Vins) ? v.vin! : v.id, pvin: v.id })) ?? []}
        pagination={
          (pvins.length ?? 0) > DEFAULT_PAGE_SIZE
            ? { pageSize: DEFAULT_PAGE_SIZE, style: { padding: "5px" }, showSizeChanger: false }
            : false
        }
      />
    </>
  );
};
