import { Expression, TimeSeriesFieldExpression } from "@lib/src/expression/types";
import { Tooltip } from "antd";
import { useRef } from "react";
import { PartialDeep } from "type-fest";

import { EntityLabel } from "./PatternEntityNode.styled";
import { PatternFocusedNode } from "./PatternFocusedNode";
import { PatternNodeContainer } from "./PatternNode.styled";
import { getTimeSeriesIconByType } from "./TypeaheadUtils";
import { useFriendlyEntityLabel } from "./useFriendlyEntityLabel";

type PatternLiteralNodeProps = {
  node: TimeSeriesFieldExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const PatternEntityNode = ({ node, onChange, path, disabled }: PatternLiteralNodeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);

  const icType = node.type.toLowerCase();
  const nodeClassname = `pattern-node ${icType}`;
  const labelClassname = `${icType} overflows`;
  const labelString = useFriendlyEntityLabel(node);

  const labelHasEllipsis = (labelRef.current?.scrollWidth || 0) > (labelRef.current?.clientWidth || 0);

  const tooltipTitle = (
    <EntityLabel className={`${icType}`}>
      {getTimeSeriesIconByType(node.type)} {labelString}
    </EntityLabel>
  );

  const labelElement = labelHasEllipsis ? (
    <Tooltip title={tooltipTitle} rootClassName="pattern-entity-tooltip">
      <EntityLabel className={labelClassname} ref={labelRef}>
        {labelString}
      </EntityLabel>
    </Tooltip>
  ) : (
    <EntityLabel className={labelClassname} ref={labelRef}>
      {labelString}
    </EntityLabel>
  );

  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <PatternNodeContainer className={nodeClassname}>{labelElement}</PatternNodeContainer>
    </PatternFocusedNode>
  );
};

export default PatternEntityNode;
