import styled from "styled-components";

export const PatternNodeContainer = styled.div`
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
  &.conditional-statement {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &.case-statement {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    .case-statement-controls > * {
      margin: 0.2em;
    }
  }
  &.literalnumeric {
    width: 4rem;
  }
  .inline-node-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
  .indented-node-section {
    margin-left: 1.5rem;
    flex-basis: 100%;
    justify-items: flex-start;
  }
  &.divide {
    flex-direction: column;
  }
  .operator-symbol {
    width: 100%;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.accentBlue};
    border-color: ${({ theme }) => theme.colors.accentBlue};
    border-width: 0.5px;
    margin: 0 0.5rem;
    hr {
      margin: 0;
    }
  }
  .bracket-symbol {
    width: 100%;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.mediumTeal};
    border-color: ${({ theme }) => theme.colors.mediumTeal};
    hr {
      margin: 0;
    }
    &.highlighted {
      color: ${({ theme }) => theme.colors.warning};
    }
  }
  .fn-name-label {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.mediumTeal};
  }
  .logical-operator-label {
    font-size: 0.75rem;
  }
  .centered-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
`;
