import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import {
  AnomalyDetectorType,
  SingleVehicleAnomalyDetectorQuery,
  SingleVehicleAnomalyDetectorQueryVariables,
  useSingleVehicleAnomalyDetectorQuery,
} from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedSingleVehicleAnomalyDetectorQuery = (
  variables: SingleVehicleAnomalyDetectorQueryVariables,
  options?: UseQueryOptions<SingleVehicleAnomalyDetectorQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useSingleVehicleAnomalyDetectorQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.TelemetryComponent) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.TelemetryComponent)) {
    return results;
  }

  return {
    data: fakeDetectors[variables.id] ?? {},
    isLoading: false,
    isError: false,
    error: undefined,
    isSuccess: true,
  };
};

const fakeDetectors: Record<string, SingleVehicleAnomalyDetectorQuery> = {
  "amaya.seed.anomaly.CellTempDiff": {
    singleVehicleAnomalyDetector: {
      detector: {
        cautionThreshold: 0.6,
        creator: null,
        created: "2024-08-19T15:41:34.741Z",
        lastModified: "2024-08-19T15:41:34.741Z",
        description: null,
        id: "amaya.seed.anomaly.CellTempDiff",
        isCustomerMLModel: null,
        type: AnomalyDetectorType.Pattern,
        name: "Cell Temp Diff",
        nameKey: "CELL_TEMP_DIFF",
        version: 1,
        warningThreshold: 0.7,
        timeSeriesFieldId: "c:1-amaya.seed.expression.CellTemperatureDiff-256016527",
        inputIds: [
          "c:1-amaya.seed.expression.CellTemperatureDiff-256016527",
          "t:hv_battery_cell_temp_highest",
          "t:hv_battery_cell_temp_lowest",
        ],
        expression: {
          id: "amaya.seed.expression.CellTemperatureDiff",
          name: "Cell Temperature Diff",
        },
      },
      vehicleData: {
        alertsCount: 25,
        anomalousDaysCount: 217,
        firstDetected: "2021-10-07T07:00:00.000Z",
        fieldsWeights: null,
        history: {
          count: null,
          high: null,
          independentVarInterval: null,
          low: null,
          x: [
            1632985200000, 1633590000000, 1634194800000, 1634799600000, 1635404400000, 1636009200000, 1636617600000,
            1637222400000, 1637827200000, 1638432000000, 1639036800000, 1639641600000, 1640246400000, 1640851200000,
            1641456000000, 1642060800000, 1642665600000, 1643270400000, 1643875200000, 1644480000000, 1645084800000,
            1645689600000, 1646294400000, 1646899200000, 1647500400000, 1648105200000, 1648710000000, 1649314800000,
            1649919600000, 1650524400000, 1651129200000, 1651734000000, 1652338800000, 1652943600000, 1653548400000,
            1654153200000, 1654758000000, 1655362800000, 1655967600000, 1656572400000, 1657177200000, 1657782000000,
            1658386800000, 1658991600000, 1659596400000, 1660201200000, 1660806000000, 1661410800000, 1662015600000,
            1662620400000, 1663225200000, 1663830000000, 1664434800000, 1665039600000, 1665644400000, 1666249200000,
            1666854000000, 1667458800000, 1668067200000, 1668672000000, 1669276800000, 1669881600000, 1670486400000,
            1671091200000, 1671696000000, 1672300800000, 1672905600000, 1673510400000, 1674115200000, 1674720000000,
            1675324800000, 1675929600000, 1676534400000, 1677139200000, 1677744000000, 1678348800000, 1678950000000,
            1679554800000, 1680159600000, 1680764400000, 1681369200000, 1681974000000, 1682578800000, 1683183600000,
            1683788400000, 1684393200000, 1684998000000, 1685602800000, 1686207600000, 1686812400000, 1687417200000,
            1688022000000, 1688626800000, 1689231600000, 1689836400000, 1690441200000, 1691046000000, 1691650800000,
            1692255600000, 1692860400000, 1693465200000, 1694070000000, 1694674800000, 1695279600000, 1695884400000,
            1696489200000, 1697094000000, 1697698800000, 1698303600000, 1698908400000, 1699516800000, 1700121600000,
            1700726400000, 1701331200000, 1701936000000, 1702540800000, 1703145600000, 1703750400000, 1704355200000,
            1704960000000, 1705564800000, 1706169600000, 1706774400000, 1707379200000, 1707984000000, 1708588800000,
            1709193600000, 1709798400000, 1710399600000, 1711004400000, 1711609200000, 1712214000000, 1712818800000,
            1713423600000, 1714028400000, 1714633200000, 1715238000000, 1715842800000, 1716447600000, 1717052400000,
            1717657200000, 1718262000000, 1718866800000, 1719471600000, 1720076400000, 1720681200000, 1721286000000,
            1721890800000,
          ],
          y: [
            [
              0.778, 0.788, 0.457, 0.838, 0.987, 0.686, 0.683, 0.424, 0.6, 0.945, 0.842, 0.794, 0.406, 0.632, 0.344,
              0.477, 0.872, 0.619, 0.649, 0.462, 0.638, 0.917, 0.947, 0.485, 0.727, 0.438, 0.695, 0.627, 0.761, 0.924,
              0.784, 0.786, 0.654, 0.371, 0.943, 0.664, 0.762, 0.646, 0.77, 0.668, 0.626, 0.957, 0.823, 0.445, 0.97,
              0.542, 0.842, 0.857, 0.645, 0.432, 0.615, 0.624, 0.42, 0.616, 0.929, 0.901, 0.725, 0.996, 0.858, 0.647,
              0.484, 0.697, 0.616, 0.978, 0.411, 0.912, 0.982, 0.943, 0.711, 0.98, 0.939, 0.492, 0.418, 0.643, 0.777,
              0.847, 0.953, 0.69, 0.638, 0.749, 0.994, 0.442, 0.321, 0.989, 0.793, 0.411, 0.879, 0.961, 0.704, 0.913,
              0.626, 0.953, 0.968, 0.621, 0.957, 0.794, 0.692, 0.977, 0.428, 0.692, 0.966, 0.973, 0.674, 0.966, 0.414,
              0.823, 0.696, 0.638, 0.477, 0.484, 0.935, 0.488, 0.656, 0.733, 0.609, 0.435, 0.735, 0.493, 1, 1, 0.66,
              0.643, 0.72, 0.39, 0.631, 0.741, 0.734, 0.627, 0.997, 0.605, 0.365, 0.683, 0.823, 0.492, 0.44, 0.918,
              0.58, 0.951, 0.61, 0.428, 0.934, 0.688, 0.484, 0.619, 0.939, 0.949, 0.391, 0.92,
            ],
          ],
        },
        score: 0.92,
      },
    },
  },
  CellVoltageAnomalyDetector: {
    singleVehicleAnomalyDetector: {
      detector: {
        cautionThreshold: 0.5,
        creator: {
          isMe: false,
          name: "Pull Systems",
        },
        created: "2024-03-15T23:20:50.520Z",
        lastModified: "2024-03-15T23:20:50.520Z",
        description: null,
        id: "Cell Voltage Anomaly Detector",
        isCustomerMLModel: false,
        type: AnomalyDetectorType.Ml,
        name: "Cell Voltage Anomaly Detector",
        nameKey: "TRANSLATION_KEY_HERE",
        version: 1,
        warningThreshold: 0.5844534483892943,
        timeSeriesFieldId: "m:CellVoltageAnomalyDetector_v1",
        inputIds: [
          "t:hv_battery_capacity",
          "t:hv_battery_cell_voltage_highest",
          "t:hv_battery_cell_voltage_lowest",
          "c:1-amaya.seed.expression.cellvoltagediff-1484322513",
          "t:hv_battery_cell_temp_highest",
          "t:hv_battery_cell_temp_lowest",
          "c:1-amaya.seed.expression.celltemperaturediff-256016527",
        ],
        expression: null,
      },
      vehicleData: {
        alertsCount: 1,
        anomalousDaysCount: 7,
        firstDetected: "2023-04-27T07:00:00.000Z",
        fieldsWeights: [
          {
            id: "hv_battery_capacity",
            values: [
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801, 12.801,
              12.801, 12.801, 12.801, 12.801, 12.801,
            ],
          },
          {
            id: "hv_battery_cell_voltage_highest",
            values: [
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
              1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77, 1.77,
            ],
          },
          {
            id: "hv_battery_cell_voltage_lowest",
            values: [
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516, -10.516,
              -10.516, -10.516, -10.516, -10.516, -10.516,
            ],
          },
          {
            id: "amaya.seed.expression.cellvoltagediff",
            values: [
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432, 20.432,
              20.432, 20.432, 20.432, 20.432, 20.432,
            ],
          },
          {
            id: "hv_battery_cell_temp_highest",
            values: [
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809, -21.809,
              -21.809, -21.809, -21.809, -21.809, -21.809,
            ],
          },
          {
            id: "hv_battery_cell_temp_lowest",
            values: [
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183, -20.183,
              -20.183, -20.183, -20.183, -20.183, -20.183,
            ],
          },
          {
            id: "amaya.seed.expression.celltemperaturediff",
            values: [
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489, -12.489,
              -12.489, -12.489, -12.489, -12.489, -12.489,
            ],
          },
        ],
        history: {
          count: null,
          high: null,
          independentVarInterval: null,
          low: null,
          x: [
            1632985200000, 1633590000000, 1634194800000, 1634799600000, 1635404400000, 1636009200000, 1636617600000,
            1637222400000, 1637827200000, 1638432000000, 1639036800000, 1639641600000, 1640246400000, 1640851200000,
            1641456000000, 1642060800000, 1642665600000, 1643270400000, 1643875200000, 1644480000000, 1645084800000,
            1645689600000, 1646294400000, 1646899200000, 1647500400000, 1648105200000, 1648710000000, 1649314800000,
            1649919600000, 1650524400000, 1651129200000, 1651734000000, 1652338800000, 1652943600000, 1653548400000,
            1654153200000, 1654758000000, 1655362800000, 1655967600000, 1656572400000, 1657177200000, 1657782000000,
            1658386800000, 1658991600000, 1659596400000, 1660201200000, 1660806000000, 1661410800000, 1662015600000,
            1662620400000, 1663225200000, 1663830000000, 1664434800000, 1665039600000, 1665644400000, 1666249200000,
            1666854000000, 1667458800000, 1668067200000, 1668672000000, 1669276800000, 1669881600000, 1670486400000,
            1671091200000, 1671696000000, 1672300800000, 1672905600000, 1673510400000, 1674115200000, 1674720000000,
            1675324800000, 1675929600000, 1676534400000, 1677139200000, 1677744000000, 1678348800000, 1678950000000,
            1679554800000, 1680159600000, 1680764400000, 1681369200000, 1681974000000, 1682578800000, 1683183600000,
            1683788400000, 1684393200000, 1684998000000, 1685602800000, 1686207600000, 1686812400000, 1687417200000,
            1688022000000, 1688626800000, 1689231600000, 1689836400000, 1690441200000, 1691046000000, 1691650800000,
            1692255600000, 1692860400000, 1693465200000, 1694070000000, 1694674800000, 1695279600000, 1695884400000,
            1696489200000, 1697094000000, 1697698800000, 1698303600000, 1698908400000, 1699516800000, 1700121600000,
            1700726400000, 1701331200000, 1701936000000, 1702540800000, 1703145600000, 1703750400000, 1704355200000,
            1704960000000, 1705564800000, 1706169600000, 1706774400000, 1707379200000, 1707984000000, 1708588800000,
            1709193600000, 1709798400000, 1710399600000, 1711004400000, 1711609200000, 1712214000000, 1712818800000,
            1713423600000, 1714028400000, 1714633200000, 1715238000000, 1715842800000, 1716447600000, 1717052400000,
            1717657200000, 1718262000000, 1718866800000, 1719471600000, 1720076400000, 1720681200000, 1721286000000,
            1721890800000,
          ],
          y: [
            [
              0.399, 0.465, 0.398, 0.487, 0.434, 0.445, 0.391, 0.924, 0.384, 0.401, 0.93, 0.443, 0.478, 0.37, 0.966,
              0.489, 0.481, 0.389, 0.385, 0.423, 0.397, 0.388, 0.39, 0.424, 0.416, 0.398, 0.402, 0.484, 0.491, 0.386,
              0.487, 0.432, 0.398, 0.486, 0.391, 0.391, 0.417, 0.388, 0.392, 0.421, 0.414, 0.389, 0.454, 0.44, 0.414,
              0.384, 0.472, 0.463, 0.432, 0.439, 0.407, 0.395, 0.434, 0.39, 0.462, 0.493, 0.398, 0.392, 0.477, 0.392,
              0.437, 0.381, 0.43, 0.387, 0.426, 0.39, 0.453, 0.407, 0.437, 0.401, 0.454, 0.429, 0.397, 0.38, 0.447,
              0.441, 0.409, 0.42, 0.374, 0.46, 0.428, 0.419, 0.997, 0.407, 0.432, 0.403, 0.933, 0.376, 0.384, 0.465,
              0.44, 0.394, 0.381, 0.39, 0.436, 0.403, 0.421, 0.397, 0.398, 0.438, 0.416, 0.913, 0.449, 0.939, 0.486,
              0.451, 0.937, 0.382, 0.402, 0.394, 0.382, 0.403, 0.451, 0.387, 0.409, 0.434, 0.391, 0.408, 0.466, 0.398,
              0.4, 0.399, 0.452, 0.449, 0.38, 0.431, 0.457, 0.386, 0.394, 0.446, 0.458, 0.387, 0.43, 0.429, 0.473,
              0.405, 0.394, 0.388, 0.438, 0.432, 0.391, 0.903, 0.442, 0.395, 0.397, 0.375, 0.56, 0.56,
            ],
          ],
        },
        score: 0.56,
      },
    },
  },
  "amaya.seed.anomaly.CellVoltageDiff": {
    singleVehicleAnomalyDetector: {
      detector: {
        cautionThreshold: 0.25,
        creator: null,
        created: "2024-08-19T15:41:34.741Z",
        lastModified: "2024-08-19T15:41:34.741Z",
        description: null,
        id: "amaya.seed.anomaly.CellVoltageDiff",
        isCustomerMLModel: null,
        type: AnomalyDetectorType.Pattern,
        name: "Cell Voltage Diff",
        nameKey: "CELL_VOLTAGE_DIFF",
        version: 1,
        warningThreshold: 0.7,
        timeSeriesFieldId: "c:1-amaya.seed.expression.CellVoltageDiff-1484322513",
        inputIds: [
          "c:1-amaya.seed.expression.CellVoltageDiff-1484322513",
          "t:hv_battery_cell_voltage_highest",
          "t:hv_battery_cell_voltage_lowest",
        ],
        expression: {
          id: "amaya.seed.expression.CellVoltageDiff",
          name: "Cell Voltage Diff",
        },
      },
      vehicleData: {
        alertsCount: 0,
        anomalousDaysCount: 0,
        firstDetected: null,
        fieldsWeights: null,
        history: {
          count: null,
          high: null,
          independentVarInterval: null,
          low: null,
          x: [
            1632985200000, 1633590000000, 1634194800000, 1634799600000, 1635404400000, 1636009200000, 1636617600000,
            1637222400000, 1637827200000, 1638432000000, 1639036800000, 1639641600000, 1640246400000, 1640851200000,
            1641456000000, 1642060800000, 1642665600000, 1643270400000, 1643875200000, 1644480000000, 1645084800000,
            1645689600000, 1646294400000, 1646899200000, 1647500400000, 1648105200000, 1648710000000, 1649314800000,
            1649919600000, 1650524400000, 1651129200000, 1651734000000, 1652338800000, 1652943600000, 1653548400000,
            1654153200000, 1654758000000, 1655362800000, 1655967600000, 1656572400000, 1657177200000, 1657782000000,
            1658386800000, 1658991600000, 1659596400000, 1660201200000, 1660806000000, 1661410800000, 1662015600000,
            1662620400000, 1663225200000, 1663830000000, 1664434800000, 1665039600000, 1665644400000, 1666249200000,
            1666854000000, 1667458800000, 1668067200000, 1668672000000, 1669276800000, 1669881600000, 1670486400000,
            1671091200000, 1671696000000, 1672300800000, 1672905600000, 1673510400000, 1674115200000, 1674720000000,
            1675324800000, 1675929600000, 1676534400000, 1677139200000, 1677744000000, 1678348800000, 1678950000000,
            1679554800000, 1680159600000, 1680764400000, 1681369200000, 1681974000000, 1682578800000, 1683183600000,
            1683788400000, 1684393200000, 1684998000000, 1685602800000, 1686207600000, 1686812400000, 1687417200000,
            1688022000000, 1688626800000, 1689231600000, 1689836400000, 1690441200000, 1691046000000, 1691650800000,
            1692255600000, 1692860400000, 1693465200000, 1694070000000, 1694674800000, 1695279600000, 1695884400000,
            1696489200000, 1697094000000, 1697698800000, 1698303600000, 1698908400000, 1699516800000, 1700121600000,
            1700726400000, 1701331200000, 1701936000000, 1702540800000, 1703145600000, 1703750400000, 1704355200000,
            1704960000000, 1705564800000, 1706169600000, 1706774400000, 1707379200000, 1707984000000, 1708588800000,
            1709193600000, 1709798400000, 1710399600000, 1711004400000, 1711609200000, 1712214000000, 1712818800000,
            1713423600000, 1714028400000, 1714633200000, 1715238000000, 1715842800000, 1716447600000, 1717052400000,
            1717657200000, 1718262000000, 1718866800000, 1719471600000, 1720076400000, 1720681200000, 1721286000000,
            1721890800000,
          ],
          y: [
            [
              0.156, 0.0914, 0.0968, 0.187, 0.155, 0.157, 0.127, 0.0806, 0.157, 0.121, 0.198, 0.131, 0.0637, 0.124,
              0.141, 0.0921, 0.135, 0.11, 0.109, 0.0829, 0.137, 0.133, 0.138, 0.128, 0.155, 0.121, 0.14, 0.0975, 0.0783,
              0.121, 0.203, 0.138, 0.162, 0.0637, 0.143, 0.102, 0.108, 0.137, 0.122, 0.134, 0.0968, 0.126, 0.121, 0.111,
              0.0875, 0.143, 0.132, 0.175, 0.161, 0.076, 0.107, 0.154, 0.115, 0.126, 0.143, 0.16, 0.137, 0.111, 0.131,
              0.146, 0.0875, 0.131, 0.127, 0.13, 0.108, 0.114, 0.169, 0.131, 0.147, 0.0875, 0.171, 0.125, 0.114, 0.126,
              0.176, 0.163, 0.103, 0.111, 0.128, 0.102, 0.0791, 0.0991, 0.142, 0.148, 0.131, 0.135, 0.167, 0.124, 0.151,
              0.0676, 0.174, 0.116, 0.116, 0.162, 0.103, 0.147, 0.138, 0.114, 0.136, 0.104, 0.0968, 0.0614, 0.18, 0.105,
              0.0722, 0.16, 0.178, 0.15, 0.109, 0.0921, 0.114, 0.123, 0.101, 0.146, 0.105, 0.0998, 0.115, 0.0952, 0.107,
              0.162, 0.155, 0.143, 0.175, 0.134, 0.12, 0.108, 0.135, 0.144, 0.161, 0.159, 0.137, 0.137, 0.129, 0.125,
              0.0845, 0.123, 0.109, 0.105, 0.17, 0.131, 0.142, 0.208, 0.133, 0.107, 0.15, 0.131, 0.0653, 0.152,
            ],
          ],
        },
        score: 0.152,
      },
    },
  },
};
