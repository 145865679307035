import { createFileRoute } from "@tanstack/react-router";

import { MaintenanceLogs } from "@/features/maintenanceLogs/MaintenanceLogs";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type MaintenanceLogsSearch = {
  maintenanceLogsPage?: number;
};
export const Route = createFileRoute("/maintenance-logs/")({
  component: () => (
    <AppMainLayout>
      <MaintenanceLogs />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Maintenance Logs"),
  validateSearch: (search: Record<string, unknown>): MaintenanceLogsSearch => ({
    maintenanceLogsPage: (search.maintenanceLogsPage as number) || undefined,
  }),
});
