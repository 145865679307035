import { TimeSeriesExpressionType } from "@lib/src/expression/types";
import React from "react";

import { OptionGroupHeaderContainer } from "./TypeaheadOptionGroupHeader.styled";

type TypeaheadOptionGroupHeaderProps = {
  label: string;
  icon: React.JSX.Element;
  timeSeriesType?: TimeSeriesExpressionType;
};

const TypeaheadOptionGroupHeader = ({ label, icon, timeSeriesType }: TypeaheadOptionGroupHeaderProps) => {
  const headerClassname = `typeahead-option-group-header${timeSeriesType ? ` ${timeSeriesType.toLowerCase()}` : ""}`;

  return (
    <OptionGroupHeaderContainer className={headerClassname}>
      <h4 className="body-medium group-header-label">{label}</h4>
      <span className="header-icon">{icon}</span>
    </OptionGroupHeaderContainer>
  );
};

export default TypeaheadOptionGroupHeader;
