import { BooleanExpression, Expression } from "@lib/src/expression/types";
import { isEmpty, isEqual } from "lodash";
import { KeyboardEvent, useContext } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import NodeWrapper from "./NodeWrapper";
import { PatternFocusedNode } from "./PatternFocusedNode";
import PatternNode from "./PatternNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternFunctionNodeProps = {
  node: PartialDeep<BooleanExpression>;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const PatternFunctionNode = ({ node, path, onChange, disabled }: PatternFunctionNodeProps) => {
  const { mostRecentPath } = useContext(PatternFocusContext);
  const values = "values" in node ? node.values : [{}];

  const handleDelete = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Backspace" && (e?.target as HTMLInputElement).value === "" && "values" in node) {
      const remainingValues = (node.values ?? []).filter((v) => Object.keys(v ?? {}).length !== 0);
      if (remainingValues.length === 0) {
        onChange({}, path);
      } else if (remainingValues.length === 1) {
        onChange(remainingValues[0] as Expression, path);
      }
    }
  };
  const areFunctionValuesInvalid = (valueIndex: number) => {
    const isMostRecentPathInFamily = isEqual(mostRecentPath, [...path, "values", "0"]) || isEqual(mostRecentPath, path);
    if (isMostRecentPathInFamily) {
      return;
    } else {
      return isEmpty(node.values?.[valueIndex] ?? {}) ? "error" : undefined;
    }
  };
  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <PatternNodeContainer>
        <span className="fn-name-label">{node?.type?.toUpperCase()}</span>
        <NodeWrapper>
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "values", "0"]}
            node={values?.[0] ?? {}}
            deleteFunction={handleDelete}
            disabled={disabled}
            status={areFunctionValuesInvalid(0)}
          />
        </NodeWrapper>
      </PatternNodeContainer>
    </PatternFocusedNode>
  );
};

export default PatternFunctionNode;
