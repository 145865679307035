import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Modal, TableColumnType } from "antd";
import i18next from "i18next";
import { ReactNode } from "react";

import { FeatureId } from "@/api";
import { Fault, useVehicleDetailsQuery } from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import FriendlyCountryLabel from "@/components/ui/FriendlyCountryLabel";
import { QUERY_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";
import { FaultField } from "@/utils/types/commonDataDictionary";
import { toModelDescription } from "@/utils/vehicleModel";

import { FaultDetailsIcon } from "./FaultDetailsModal.styled";

type FaultAttribute = {
  attribute: string;
  value?: string | number | ReactNode;
};

type FaultSource = {
  dataSource: string;
  key: string;
};

type FaultDetailsModalProps = {
  fault?: Fault;
  onClose: () => void;
};

const FaultDetailsModal = ({ fault, onClose }: FaultDetailsModalProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { customerIdentifier } = useCustomerIdentifier();
  const { data: vehicleData, isLoading } = useVehicleDetailsQuery(
    { id: fault?.vehicleId || "" },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !!fault?.vehicleId }
  );

  const columns: TableColumnType<FaultAttribute>[] = [
    {
      key: "attribute",
      title: "Atribute",
      dataIndex: "attribute",
      ellipsis: true,
    },
    {
      key: "value",
      title: "Value",
      dataIndex: "value",
      ellipsis: true,
    },
  ];

  const vehicleDetails = vehicleData?.vehicleDetails[0];

  const modelTypeLabel =
    toModelDescription(
      {
        model: vehicleDetails?.model ?? "",
        modelStyle: vehicleDetails?.modelStyle ?? "",
      },
      customerIdentifier.models
    ) ?? "-";

  const dataSourceColumns: TableColumnType<FaultSource>[] = [
    { title: "Data Source", dataIndex: "dataSource", key: "dataSource" },
  ];
  const dataSourceAttributes = [
    { dataSource: "Fault Details", key: "faultDetails" },
    { dataSource: "Vehicle Details", key: "vehicleDetails" },
    { dataSource: i18next.t("tableTitle.DIAGNOSTIC_CHECK"), key: "diagnosticCheck" },
    { dataSource: `${i18next.t("tableTitle.SNAPSHOT_FIELD")}s`, key: "snapshotFields" },
  ];

  const snapshotFields =
    (isFeatureEnabled(FeatureId.TelemetryComponent)
      ? fault?.fields?.map((field) => {
          const customLabel = customerIdentifier?.faultFields?.find((f: FaultField) => f.id === field.id)?.name;
          return { attribute: customLabel ?? field.id, value: field.value ?? "-" };
        })
      : []
    )?.filter((f) => !["Date/Time", "Odometer"].includes(f.attribute)) ?? [];

  const categorizedData = {
    faultDetails: [
      {
        attribute: "ECU",
        value: fault?.source,
      },
      {
        attribute: "Trouble Code",
        value: fault?.troubleCode ? fault.troubleCode : "-",
      },
      {
        attribute: "Timestamp",
        value: fault?.startTime ? new Date(fault.startTime).toLocaleString() : "-",
      },
      {
        attribute: "Odometer",
        value: fault?.odometer ? formatNumber(fault.odometer) : "-",
      },
      {
        attribute: "Software version",
        value: fault?.softwareVersion ? fault.softwareVersion : "-",
      },
      {
        attribute: "Hardware version",
        value: fault?.hardwareVersion ? fault.hardwareVersion : "-",
      },
      {
        attribute: "Software part number",
        value: fault?.softwarePartNumber ? fault.softwarePartNumber : "-",
      },
      {
        attribute: "Hardware part number",
        value: fault?.hardwarePartNumber ? fault.hardwarePartNumber : "-",
      },
    ],
    vehicleDetails: [
      {
        attribute: "Model Year",
        value: vehicleDetails?.year ? vehicleDetails.year : "-",
      },
      {
        attribute: "Model Type",
        value: modelTypeLabel,
      },
      {
        attribute: "Delivery Country",
        value: vehicleDetails?.country ? <FriendlyCountryLabel countryCode={vehicleDetails.country} /> : "-",
      },
      {
        attribute: "Production date",
        value: vehicleDetails?.productionDate ? new Date(vehicleDetails?.productionDate).toLocaleString() : "-",
      },
      {
        attribute: "Importer",
        value: vehicleDetails?.importer ? vehicleDetails.importer : "-",
      },
    ],
    diagnosticCheck: [
      { attribute: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"), value: fault?.diagnositcCheckId },
      { attribute: "Dealer Number", value: fault?.dealerNumber },
      { attribute: i18next.t("columnTitle.DIAGNOSTIC_CHECK_DATE"), value: fault?.diagnosticDate },
    ],
    snapshotFields,
  };

  const expandedRowRender = (record: FaultSource, _index: number, _indent: number) => {
    return (
      <BasicTable
        columns={columns}
        dataSource={categorizedData[record.key as "faultDetails"]}
        rowKey="attribute"
        pagination={false}
        style={{ paddingBottom: "0.5rem" }}
        loading={isLoading}
      />
    );
  };

  const filterTelemtryDataAttributes = (data: typeof dataSourceAttributes) => {
    return data.filter((d) => {
      if (isFeatureEnabled(FeatureId.TelemetryComponent)) {
        return true;
      } else if (d.key == "snapshotFields") {
        return false;
      }
      return true;
    });
  };

  return (
    <Modal open={!!fault} onCancel={onClose} title={fault?.code} footer={null} className="no-padding-body" width={800}>
      <BasicTable
        columns={dataSourceColumns}
        dataSource={filterTelemtryDataAttributes(dataSourceAttributes)}
        pagination={false}
        scroll={{ y: "50vh" }}
        style={{ paddingBottom: "0.5rem" }}
        loading={isLoading}
        rowClassName={"ant-table-fault-history"}
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) => {
            const className = `fault-details-icon ${expanded ? "open" : "close"}`;
            return (
              <FaultDetailsIcon className={className} onClick={(e) => onExpand(record, e)}>
                {expanded ? <UpOutlined /> : <DownOutlined />}
              </FaultDetailsIcon>
            );
          },
        }}
        showHeader={false}
      />
    </Modal>
  );
};
export default FaultDetailsModal;
