import { Link } from "@tanstack/react-router";
import { List } from "antd";
import styled from "styled-components";

export const DependencyList = styled(List)`
  margin: 0.5rem 0;
`;

export const DependencyLink = styled(Link)`
  width: 100%;
  font-weight: 500;
  .dependency-name {
    flex: 3;
  }
  .dependency-type {
    flex: 1;
    text-align: right;
    color: ${({ theme }) => theme.colors.blueGray};
  }
`;

export const DefinitionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  .form-name-input {
    min-width: 450px;
    flex-grow: 1;
  }
  .form-description-input {
    min-width: 750px;
    flex-grow: 3;
  }
`;
