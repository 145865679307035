import { createFileRoute } from "@tanstack/react-router";

import { EventAnalysis } from "@/features/eventAnalysis/EventAnalysis";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type EventsHomeSearch = {
  eventsPage?: number;
};

export type EventAnalysisSearch = {
  xEventAnalysisChart?: string;
  eventOccurrencesPage?: number;
  relatedEventsPage?: number;
};

export const Route = createFileRoute("/event-analysis")({
  component: () => (
    <AppMainLayout>
      <EventAnalysis />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Events"),
  validateSearch: (search: Record<string, unknown>): EventsHomeSearch => {
    return {
      eventsPage: +(search.eventsPage as number) || undefined,
    };
  },
});
