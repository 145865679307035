import { createFileRoute } from "@tanstack/react-router";

import EditForecast from "@/features/forecast/EditForecast";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/forecast/$forecastId")({
  component: () => (
    <AppMainLayout>
      <EditForecast />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Forecast"),
});
