export const isValidClusterEntry = (value: string) => {
  if (!value) return Promise.reject(new Error("A Cluster needs at least one entry"));
  const rows = value.split(/[\n]+/);
  const hasValidDate = rows.every((row: string) => {
    const parts = row.split(/[\s,]+/); // Split by spaces, commas, or tabs
    return parts.length > 1 && parts[1].trim() !== ""; // Ensure there's at least one date after the pvin
  });

  return hasValidDate ? Promise.resolve() : Promise.reject(new Error("Each row must include a start date."));
};
