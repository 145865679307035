import { Button } from "antd";
import { useState } from "react";

import { ClusterRedshiftCreateInput } from "@/api";
import { BasicTable } from "@/components/tables/BasicTable";
import { useVinLabel } from "@/hooks/useVinLabel";

import { ClusterModalMissingPvins } from "../clusterHome/ClusterHome.styled";

type MissingPvinsProps = {
  pvins: string[];
  handleDelete: (pvinsToDelete: ClusterRedshiftCreateInput[]) => void;
};
export const ViewMissingPvins = ({ pvins, handleDelete }: MissingPvinsProps) => {
  const [selectedPvins, setSelectedPvins] = useState<{ pvin: string }[]>([]);
  const [isDeletingPvins, setIsDeletingPvins] = useState(false);
  const vinLabel = useVinLabel();
  return (
    <>
      <h3>These {vinLabel}s are part of the cluster but are not found in our database</h3>
      <ClusterModalMissingPvins>
        <BasicTable<{ pvin: string }>
          rowSelection={{
            type: "checkbox",
            onChange: (_selectedKeys, selectedRows) => {
              setSelectedPvins(selectedRows);
            },
          }}
          columns={[{ key: "pvin", title: "PVIN", dataIndex: "pvin" }]}
          dataSource={pvins.map((pvin) => ({ pvin }))}
          rowKey={(row) => row.pvin}
        />
      </ClusterModalMissingPvins>
      <div style={{ padding: "5px" }}>
        <Button
          danger
          type="text"
          disabled={!selectedPvins.length || isDeletingPvins}
          loading={isDeletingPvins}
          onClick={() => {
            handleDelete(selectedPvins);
            setIsDeletingPvins(true);
          }}
        >
          Remove PVINs
        </Button>
      </div>
    </>
  );
};
