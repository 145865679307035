import { EChartsInstance } from "echarts-for-react";
import React, { Dispatch, useContext, useEffect, useState } from "react";

import {
  FailureCountByCountry,
  useCountryFailureCountPvinsQuery,
  useCountryFailureCountQuery,
  VehicleIdentifier,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { ClusterViewGraphHeader } from "@/features/cluster/clusterView/ClusterView.styled";
import { Route } from "@/routes/cluster.$clusterId";
import { toTitleCase } from "@/utils/toTitleCase";

import { getClusterChartLayoutConfig } from "./clusterChartHelpers";

type CountryFailureCountProps = {
  setAffectedPvins: Dispatch<React.SetStateAction<VehicleIdentifier[] | undefined>>;
  setModalView: Dispatch<
    React.SetStateAction<"vehiclesAffected" | "addVehiclesToCluster" | "viewMissingPvins" | "copyCluster" | undefined>
  >;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
};
export const ClusterCountryVsFailureCount = ({
  setAffectedPvins,
  setModalView,
  setLoading,
}: CountryFailureCountProps) => {
  const { clusterId } = Route.useParams();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const { data, isLoading, isFetching } = useCountryFailureCountQuery(
    {
      clusterId: clusterId,
      filter: globalFilter,
    },
    {
      enabled: !!clusterId,
    }
  );

  const { data: pvins } = useCountryFailureCountPvinsQuery(
    {
      clusterId: clusterId ?? "",
      filter: globalFilter,
      country: selectedCountry ?? "",
    },
    {
      enabled: !!clusterId && !!selectedCountry,
    }
  );

  useEffect(() => {
    if (pvins) {
      setSelectedCountry(undefined);
      setLoading(false);
      setAffectedPvins(pvins.filteredQuery.countryVsFailureCountPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const { xNameGap, yNameGap, grid, position } = getClusterChartLayoutConfig();

  const buildChartConfig = (data: FailureCountByCountry[]) => {
    return {
      animation: false,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        position,
        formatter: (params: [{ dataIndex: number }]) => {
          const dataInfo = data[params[0].dataIndex];
          if (dataInfo) {
            const misHeader = `<th style='color: rgb(41.2%, 16.1%, 77.3%'); padding: 8px;'>Country</th>`;
            const failureCountHeader = "<th style='padding: 8px'>Fault Count</th>";
            const pvinCountHeader = "<th style='padding: 8px'>Vehicle Count</th>";
            const tableHead = `<tr>${misHeader}${failureCountHeader}${pvinCountHeader}</tr>`;
            const tableRows = `
          <tr style="text-align: center">
            <td style='padding: 8px'>${dataInfo.deliveryCountry}</td>
            <td style='padding: 8px'>${dataInfo.failureCount}</td>
            <td style='padding: 8px'>${dataInfo.pvinCount}</td>
          </tr>`;
            return `<table>${tableHead}${tableRows}</table>`;
          }
        },
      },
      grid,
      xAxis: {
        name: "Delivery Country",
        nameLocation: "middle",
        type: "category",
        data: data.map((d) => d.deliveryCountry),
        nameGap: xNameGap,
        axisLabel: {
          show: true,
          margin: 5,
        },
      },
      yAxis: {
        type: "value",
        name: "Fault Count",
        nameLocation: "middle",
        nameGap: yNameGap,
        axisLabel: {
          show: true,
        },
      },
      series: {
        type: "bar",
        data: data.map((d) => d.failureCount),
      },
    };
  };

  const handleBackgroundClick = (params: any, c: EChartsInstance) => {
    let pointInPixel = [params.offsetX, params.offsetY];
    let pointInGrid = c.convertFromPixel("grid", pointInPixel);
    let country = c.getModel().get("xAxis")[0].data[pointInGrid[0]];
    const countryData = data?.filteredQuery.countryVsFailureCount.find((d) => d.deliveryCountry === country);
    if (countryData) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setSelectedCountry(countryData.deliveryCountry);
    }
  };
  return (
    <BasicWidget
      title={
        <ClusterViewGraphHeader>
          <div>{toTitleCase("Delivery Country vs Fault Count")}</div>
        </ClusterViewGraphHeader>
      }
    >
      <BaseEChart
        onChartReady={(c) => {
          c.getZr().on("click", (params: any) => handleBackgroundClick(params, c));
        }}
        option={buildChartConfig(data?.filteredQuery.countryVsFailureCount ?? [])}
        showLoading={isFetching || isLoading}
        onEvents={{
          click: (e: { name: string }) => {
            setLoading(true);
            setModalView("vehiclesAffected");
            setSelectedCountry(e.name);
          },
        }}
      />
    </BasicWidget>
  );
};
