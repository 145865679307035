import { createFileRoute } from "@tanstack/react-router";

import { ApiSandbox } from "@/features/apiSandbox/ApiSandbox";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/customer-api")({
  component: () => <ApiSandbox isCustomer />,
  beforeLoad: () => setTitleBeforeLoad("Customer API"),
});
