import { useNavigate, useParams } from "@tanstack/react-router";

import { HistoryProvider } from "@/contexts/HistoryProvider";

import { AnomalyDetectorEditor } from "./AnomalyDetectorEditor";

export const AnomalyDetectorEditorPage = () => {
  const { detectorId } = useParams({ strict: false });
  const navigate = useNavigate();

  return (
    <HistoryProvider>
      <AnomalyDetectorEditor
        detectorId={detectorId}
        afterCreate={(detector) =>
          navigate({
            to: "/anomaly-detector/$detectorId",
            params: { detectorId: detector.id },
          })
        }
      />
    </HistoryProvider>
  );
};
