import styled from "styled-components";

export const PatternEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  &.is-read-only {
    cursor: not-allowed;
  }
  .editor-board-container {
    overflow: auto;
    height: 100%;
    min-height: 220px;
    max-height: 650px;
    background-color: ${({ theme }) => theme.colors.defaultWhite};
    padding: 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .ant-card-head {
      display: none;
    }
  }
  .editor-content {
    padding: 1rem;
  }
`;
