import { createFileRoute } from "@tanstack/react-router";

import { HealthCheck } from "@/features/healthCheck/HealthCheck";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type HealthCheckSearch = {
  pvin?: string;
  patternId?: string;
  anomalyDetectorId?: string;
};

export const Route = createFileRoute("/health-check")({
  component: () => (
    <AppMainLayout>
      <HealthCheck />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Health Check"),
  validateSearch: (search: Record<string, unknown>): HealthCheckSearch => {
    // validate and parse the search params into a typed state
    return {
      pvin: (search.pvin as string) || "TEST_VIN001",
      patternId: (search.patternId as string) || "TEST_PATTERN001",
      anomalyDetectorId: (search.anomalyDetectorId as string) || "TEST_DETECTOR001",
    };
  },
});
