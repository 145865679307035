import { VehicleModels } from "@/utils/types/commonDataDictionary";

/**
 * Converts vehicle model and style information into a human-readable description.
 *
 * This function takes a vehicle object containing model and style information and returns
 * a descriptive string based on the provided models dictionary. It follows these rules:
 * - If both model and style exist, returns the style name
 * - If only model exists, returns the model's default name
 * - If only style exists, returns the style name from default mapping
 * - Falls back to the provided model/style value if no matching data is found
 *
 * @param vehicle - An object containing model and style information
 * @param vehicle.model - The vehicle model identifier
 * @param vehicle.modelStyle - The vehicle style identifier
 * @param models - A VehicleModels object containing the model/style mapping dictionary
 * @returns A human-readable description string, or undefined if no description could be generated
 */
export function toModelDescription(
  vehicle?: { model?: string | null; modelStyle?: string | null } | null,
  models?: VehicleModels
): string | undefined {
  const styleDictionary = (vehicle?.model && models?.model[vehicle?.model]) || models?.default;
  if (styleDictionary) {
    const styleName = (vehicle?.modelStyle && styleDictionary.style[vehicle?.modelStyle]) ?? styleDictionary.default;
    if (styleName) return styleName;
    return `${vehicle?.model}`;
  }
  return "Unknown";
}

interface ModelStyleOption {
  label: string;
  value: string | undefined;
}

/**
 * Consolidates duplicate model style options into a single option with combined values.
 *
 * This function takes an array of model style options and consolidates duplicate options
 * based on their display label. It groups the options by their label and combines the values
 * of duplicate options into a single option with a comma-separated list of values.
 *
 * @param options - An array of model style options to be consolidated.
 * @param models - An optional VehicleModels object containing model and style information.
 * @returns If models are provided, an  array of consolidated model style options with unique labels and combined values.
 *          If models are not provided, the original options array is returned unchanged.
 */
export const consolidateModelStyleOptions = (
  options: ModelStyleOption[],
  models?: VehicleModels
): ModelStyleOption[] => {
  if (!models) return options;
  // Group options by their display label
  const groupedByLabel = options.reduce((acc, option) => {
    const label = toModelDescription({ modelStyle: option.value }, models) ?? option.label ?? "-";
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push(option.value);
    return acc;
  }, {} as Record<string, (string | undefined)[]>);

  // Create deduplicated options with all associated values
  return Object.entries(groupedByLabel).map(([label, values]) => ({
    label,
    value: values.join(","),
  }));
};

/**
 * Flattens an array of model style options by splitting each option by commas.
 *
 * @param values - An array of model style options to be flattened.
 * @returns An array of flattened model style options.
 */
export const spreadModelStyleOptions = (values: string[]): string[] => {
  return values?.flatMap((v) => v?.split(",") ?? []) ?? [];
};
