import { createFileRoute } from "@tanstack/react-router";

import { ApiSandbox } from "@/features/apiSandbox/ApiSandbox";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/api")({
  component: () => <ApiSandbox />,
  beforeLoad: () => setTitleBeforeLoad("API"),
});
