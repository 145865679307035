import { useEffect } from "react";

const RESET_KEY_CODE = "KeyZ";

/**
 * Custom hook to execute callback whenever the keypress event is Ctrl+Shift+Z or Cmd+Shift+Z
 * @param callback function to be executed when reset is detected
 */
const useRedoHotKey = (callback: () => void) => {
  const handleResetEvent = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.code === RESET_KEY_CODE) {
      event.stopPropagation();
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleResetEvent);

    return () => {
      document.removeEventListener("keydown", handleResetEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);
};

export default useRedoHotKey;
