import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import {
  AnomalyDetectorType,
  AnomalySeverity,
  SingleVehicleAnomalyDetectorsQuery,
  SingleVehicleAnomalyDetectorsQueryVariables,
  useSingleVehicleAnomalyDetectorsQuery,
} from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedSingleVehicleAnomalyDetectorsQuery = (
  variables: SingleVehicleAnomalyDetectorsQueryVariables,
  options?: UseQueryOptions<SingleVehicleAnomalyDetectorsQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useSingleVehicleAnomalyDetectorsQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.TelemetryComponent) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.TelemetryComponent)) {
    return results;
  }

  return { data: fakeDetectors, isLoading: false, isError: false, error: undefined, isSuccess: true };
};

const fakeDetectors: SingleVehicleAnomalyDetectorsQuery = {
  singleVehicleAnomalyDetectors: {
    detectors: [
      {
        description: null,
        id: "amaya.seed.anomaly.CellTempDiff",
        name: "Cell Temp Diff",
        score: 0.92,
        severity: AnomalySeverity.Warning,
        type: AnomalyDetectorType.Pattern,
      },
      {
        description: null,
        id: "CellVoltageAnomalyDetector",
        name: "Cell Voltage Anomaly Detector",
        score: 0.56,
        severity: AnomalySeverity.Caution,
        type: AnomalyDetectorType.Ml,
      },
      {
        description: null,
        id: "amaya.seed.anomaly.CellVoltageDiff",
        name: "Cell Voltage Diff",
        score: 0.152041035721964,
        severity: AnomalySeverity.Normal,
        type: AnomalyDetectorType.Pattern,
      },
    ],
    pagination: { currentPage: 1, pageSize: 10, totalCount: 3, totalPages: 1 },
    severityCounts: { all: 3, warning: 1, caution: 1, normal: 1 },
  },
};
