import { QuestionCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { Card, Tooltip, Typography } from "antd";
import type { TableProps } from "antd/es/table";
import { ColumnsType } from "antd/lib/table";
import { useContext, useState } from "react";
import { useTheme } from "styled-components";

import { CalculatedStatus, FeatureId, Pagination } from "@/api";
import {
  AnomalyAffectedVehicle,
  AnomalyAffectedVehiclesSortInput,
  SortOrder,
  useAnomalyAffectedVehiclesQuery,
} from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { toArray } from "@/utils/arrayUtils";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber, humanFormatNumber } from "@/utils/numberUtils";
import { humanFriendlyStatuses } from "@/utils/patterns";

import { AnomalyDetectorTitleContainer, AnomalyVehicleListContainer } from "./AnomalyVehicleListContainer.styled";

type OccurrencesFilter = {
  sort?: AnomalyAffectedVehiclesSortInput;
};

interface AnomalyOccurrencesProps {
  id: string;
  status: CalculatedStatus | undefined | null;
  showStatus: boolean;
}

const AnomalyVehicleList = ({ id, status = CalculatedStatus.Draft, showStatus = true }: AnomalyOccurrencesProps) => {
  const theme = useTheme();
  const { globalFilter } = useContext(GlobalFilterContext);

  const [occurrencesFilter, setOccurrencesFilter] = useState<OccurrencesFilter>({
    sort: { score: SortOrder.Descend },
  });

  const currentPage = useSearch({ strict: false, select: (search) => search.anomalyDetectionsPage });
  const navigate = useNavigate();
  const features = useFeatureFlags();

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const { customerIdentifier } = useCustomerIdentifier();

  const statusDescription = status ? humanFriendlyStatuses(status) : "-";

  const isCalculatingStatus = status === CalculatedStatus.Calculating || status === CalculatedStatus.Draft;

  const columns: ColumnsType<AnomalyAffectedVehicle> = [
    features.isFeatureEnabled(FeatureId.Vins)
      ? {
          key: "vin",
          title: "VIN",
          dataIndex: "vin",
          render: (vin, vehicle) => (
            <Link to="/vehicle/$pvin" params={{ pvin: vehicle.pvin }}>
              {vin}
            </Link>
          ),
        }
      : {
          key: "pvin",
          title: "PVIN",
          dataIndex: "pvin",
          render: (pvin: string) => (
            <Link to="/vehicle/$pvin" params={{ pvin }}>
              {pvin}
            </Link>
          ),
        },
    {
      key: "model",
      title: "Model Name",
      dataIndex: "model",
      width: 256,
      render: (model: string) => {
        return <div>{customerIdentifier.models?.model[model]?.default ?? model}</div>;
      },
    },
    {
      key: "modelStyle",
      title: "Model Style",
      dataIndex: "modelStyle",
      width: 128,
    },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
      width: 128,
    },
    {
      key: "score",
      title: "Score",
      dataIndex: "score",
      render: humanFormatNumber,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      sorter: { multiple: 2 },
      width: 96,
    },
    {
      key: "lastDetected",
      title: "Score Age",
      dataIndex: "lastDetected",
      render: (lastDetected: string) => <FromNowFormatter value={new Date(lastDetected)} />,
      sortDirections: ["descend", "ascend"],
      sorter: { multiple: 1 },
      width: 128,
    },
  ];

  const { data, isLoading, isFetching } = useAnomalyAffectedVehiclesQuery(
    {
      anomalyId: id,
      anomalyStatus: status,
      pagination,
      sorting: occurrencesFilter?.sort,
      filter: globalFilter,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, keepPreviousData: false }
  );

  const vehicles = data?.filteredQuery?.anomalyAffectedVehicles?.data;
  const totalCount = data?.filteredQuery?.anomalyAffectedVehicles?.pagination.totalCount;

  // TODO a more elegant way to build AnomalyAffectedVehiclesSortInput
  const handleChange: TableProps<AnomalyAffectedVehicle>["onChange"] = async (pagination, _filters, sorter) => {
    let sortList = toArray(sorter);
    let sortInput: AnomalyAffectedVehiclesSortInput = {};
    sortList.forEach((s) => {
      if (s.columnKey === "lastDetected") {
        sortInput["lastDetected"] = s.order as SortOrder;
      }
      if (s.columnKey === "score") {
        sortInput["score"] = s.order as SortOrder;
      }
    });
    await navigate({ search: (prev) => ({ ...prev, anomalyDetectionsPage: pagination.current }) });
    setOccurrencesFilter(() => ({
      sort: Object.keys(sortInput).length > 0 ? sortInput : undefined,
    }));
  };

  return (
    <Card
      styles={{
        body: {
          padding: 0,
        },
        header: {
          padding: 0,
        },
      }}
      title={
        <AnomalyDetectorTitleContainer>
          <Typography.Title level={4} className="heading-large title">
            <b>{isCalculatingStatus ? statusDescription : formatNumber(totalCount)} Currently Anomalous Vehicles</b>
            <Tooltip
              title="List of vehicles with an anomaly score currently above Caution (yellow) or Warning (red) thresholds."
              placement="topLeft"
            >
              <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: "1rem" }} />
            </Tooltip>
          </Typography.Title>
          {showStatus && (
            <Typography.Text type="secondary">
              Status: <strong>{statusDescription}</strong>
            </Typography.Text>
          )}
        </AnomalyDetectorTitleContainer>
      }
      actions={[]}
    >
      <AnomalyVehicleListContainer>
        <BasicTable
          dataSource={vehicles?.map((v) => ({
            ...v,
            score: isNotNullOrUndefined(v.score) ? v.score * 100 : undefined,
          }))}
          columns={columns}
          rowKey="pvin"
          loading={isLoading || isFetching}
          showSorterTooltip
          pagination={{
            current: pagination.currentPage,
            pageSize: pagination.pageSize,
            total: totalCount,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          onChange={handleChange}
          noDataText={status === CalculatedStatus.Calculating ? "Calculating Anomaly Detector Details…" : undefined}
        />
      </AnomalyVehicleListContainer>
    </Card>
  );
};

export default AnomalyVehicleList;
