import { Expression, ExpressionType } from "@lib/src/expression/types";
import { PartialDeep } from "type-fest";

export type PatternOperatorType = keyof typeof ExpressionType;

export const expressionBuilderMap: { [key in PatternOperatorType]: PartialDeep<Expression> } = {
  /***                     ***/
  /*** Numeric expressions ***/
  /***                     ***/
  [ExpressionType.LiteralNumeric]: {
    type: ExpressionType.LiteralNumeric,
    value: 0,
  },
  [ExpressionType.Add]: {
    type: ExpressionType.Add,
    values: [{}, {}],
  },
  [ExpressionType.Subtract]: {
    type: ExpressionType.Subtract,
    values: [{}, {}],
  },
  [ExpressionType.Multiply]: {
    type: ExpressionType.Multiply,
    values: [{}, {}],
  },
  [ExpressionType.Divide]: {
    type: ExpressionType.Divide,
    values: [{}, {}],
  },
  [ExpressionType.Abs]: {
    type: ExpressionType.Abs,
    values: [{}],
  },
  /***                     ***/
  /*** Aggregation expressions ***/
  /***                     ***/
  [ExpressionType.Aggregation]: {
    type: ExpressionType.Aggregation,
    values: [{}],
  },
  // [ExpressionType.Max]: {
  //   type: ExpressionType.Max,
  //   values: [{}],
  // },
  // [ExpressionType.Min]: {
  //   type: ExpressionType.Min,
  //   values: [{}],
  // },
  // [ExpressionType.Count]: {
  //   type: ExpressionType.Count,
  //   values: [{}],
  // },
  // [ExpressionType.Std]: {
  //   type: ExpressionType.Std,
  //   values: [{}],
  // },
  // [ExpressionType.Percentile]: {
  //   type: ExpressionType.Percentile,
  //   values: [{}],
  // },
  // [ExpressionType.Average]: {
  //   type: ExpressionType.Average,
  //   values: [{}],
  // },
  // [ExpressionType.Median]: {
  //   type: ExpressionType.Median,
  //   values: [{}],
  // },
  /***                     ***/
  /*** Boolean expressions ***/
  /***                     ***/
  [ExpressionType.Gt]: {
    type: ExpressionType.Gt,
    values: [{}, {}],
  },

  [ExpressionType.Gte]: {
    type: ExpressionType.Gte,
    values: [{}, {}],
  },

  [ExpressionType.Lt]: {
    type: ExpressionType.Lt,
    values: [{}, {}],
  },

  [ExpressionType.Lte]: {
    type: ExpressionType.Lte,
    values: [{}, {}],
  },

  [ExpressionType.Eq]: {
    type: ExpressionType.Eq,
    values: [{}, {}],
  },

  [ExpressionType.Neq]: {
    type: ExpressionType.Neq,
    values: [{}, {}],
  },

  [ExpressionType.And]: {
    type: ExpressionType.And,
    values: [{}, {}],
  },

  [ExpressionType.Or]: {
    type: ExpressionType.Or,
    values: [{}, {}],
  },

  [ExpressionType.IsNull]: {
    type: ExpressionType.IsNull,
    values: [{}],
  },

  [ExpressionType.IsNotNull]: {
    type: ExpressionType.IsNotNull,
    values: [{}],
  },

  [ExpressionType.Not]: {
    type: ExpressionType.Not,
    values: [{}],
  },

  /***                     ***/
  /*** Any expressions     ***/
  /***                     ***/
  [ExpressionType.If]: {
    type: ExpressionType.If,
    values: [{}, {}, {}],
  },

  [ExpressionType.Case]: {
    type: ExpressionType.Case,
    when: [{}],
    then: [{}],
    else: {},
  },
  [ExpressionType.Null]: {
    type: ExpressionType.Null,
    value: null,
  },

  /***                                 ***/
  /*** TimeSeriesField expressions     ***/
  /***                                 ***/

  [ExpressionType.Signal]: {
    type: ExpressionType.Signal,
    value: "",
  },
  [ExpressionType.AnomalyScore]: {
    type: ExpressionType.AnomalyScore,
    value: "",
  },
  [ExpressionType.Pattern]: {
    type: ExpressionType.Pattern,
    value: "",
  },
};
