import { Button } from "antd";
import { EChartsInstance } from "echarts-for-react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import {
  FailureCountByDistance,
  OdometerBounds,
  useDistanceVersusFailureCountPvinsQuery,
  useDistanceVersusFailureCountQuery,
  VehicleIdentifier,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { Route } from "@/routes/cluster.$clusterId";
import { formatNumber } from "@/utils/numberUtils";
import { toTitleCase } from "@/utils/toTitleCase";

import { ClusterViewGraphHeader } from "../../../features/cluster/clusterView/ClusterView.styled";
import { ClusterChartButtonsContainer } from "./ClusterChart.styled";
import { getClusterChartLayoutConfig } from "./clusterChartHelpers";

type ClusterDistanceVsFailureCountProps = {
  setAffectedPvins: Dispatch<React.SetStateAction<VehicleIdentifier[] | undefined>>;
  setModalView: Dispatch<
    SetStateAction<"vehiclesAffected" | "addVehiclesToCluster" | "viewMissingPvins" | "copyCluster" | undefined>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const buildChartConfig = (data: FailureCountByDistance[]) => {
  const { xNameGap, yNameGap, toolbox, dataZoom, grid, position } = getClusterChartLayoutConfig();

  return {
    animation: false,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      position,
      formatter: (params: [{ dataIndex: number }]) => {
        const dataInfo = data[params[0].dataIndex];
        if (dataInfo) {
          return `<div>
          <div>
          From ${formatNumber(dataInfo.distanceStart)} km to ${formatNumber(dataInfo.distanceEnd)} km
          </div>
          <div style='border-bottom: 1px solid #dedede'></div>
        <br/>
          Fault Count: ${dataInfo.failureCount}
        </div>`;
        }
      },
    },
    toolbox,
    dataZoom,
    grid,
    xAxis: {
      nameGap: xNameGap,
      name: "km",
      nameLocation: "middle",
      type: "category",
      data: data.map((d) => d.distanceStart),
      axisLabel: {
        show: true,
        margin: 5,
        formatter: (value: number) => formatNumber(value),
      },
    },
    yAxis: {
      type: "value",
      name: "Fault Count",
      nameLocation: "middle",
      nameGap: yNameGap,
      axisLabel: {
        show: true,
      },
    },
    series: {
      type: "bar",
      data: data.map((d) => d.failureCount),
    },
  };
};

export const ClusterDistanceVsFailureCount = ({
  setAffectedPvins,
  setModalView,
  setLoading,
}: ClusterDistanceVsFailureCountProps) => {
  const { clusterId } = Route.useParams();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [zoomRange, setZoomRange] = useState<{ min: number; max: number }>();
  const [clickedRange, setClickedRange] = useState<{ min: number | undefined; max: number | undefined }>();
  const { data, isLoading, isFetching } = useDistanceVersusFailureCountQuery(
    {
      clusterId,
      filter: globalFilter,
      odometerBounds: zoomRange,
    },
    { enabled: !!clusterId }
  );

  const { data: pvins } = useDistanceVersusFailureCountPvinsQuery(
    {
      clusterId,
      filter: globalFilter,
      odometerBounds: clickedRange?.max && clickedRange?.min ? (clickedRange as OdometerBounds) : undefined,
    },
    { enabled: !!clusterId && !!clickedRange }
  );

  useEffect(() => {
    if (pvins) {
      setClickedRange(undefined);
      setLoading(false);
      setAffectedPvins(pvins.filteredQuery.distanceVersusFailureCountPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const handleBackgroundClick = (params: any, c: EChartsInstance) => {
    let pointInPixel = [params.offsetX, params.offsetY];
    let pointInGrid = c.convertFromPixel("grid", pointInPixel);
    const clickedData = data?.filteredQuery.distanceVersusFailureCount[pointInGrid[0]];

    if (clickedData) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setClickedRange({ min: clickedData?.distanceStart, max: clickedData?.distanceEnd });
    }
  };

  const getAllPvinsInGraph = () => {
    const dataArr = data?.filteredQuery.distanceVersusFailureCount;
    if (dataArr) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setClickedRange({ min: dataArr[0].distanceStart, max: dataArr[dataArr.length - 1].distanceEnd });
    }
  };

  return (
    <BasicWidget
      title={
        <ClusterViewGraphHeader>
          <div>{toTitleCase("Mileage vs Fault Count")}</div>
          {zoomRange && (
            <ClusterChartButtonsContainer>
              <Button disabled={isLoading} onClick={() => getAllPvinsInGraph()}>
                View Vehicles
              </Button>
              <Button onClick={() => setZoomRange(undefined)}>Reset Graph</Button>
            </ClusterChartButtonsContainer>
          )}
        </ClusterViewGraphHeader>
      }
    >
      <BaseEChart
        option={buildChartConfig(data?.filteredQuery.distanceVersusFailureCount ?? [])}
        onChartReady={(c) => {
          c.getZr().on("click", (params: any) => handleBackgroundClick(params, c));
          c.dispatchAction({
            type: "takeGlobalCursor",
            key: "dataZoomSelect",
            dataZoomSelectActive: true,
          });
        }}
        showLoading={isFetching || isLoading}
        onEvents={{
          datazoom: (zoomData: { batch: { endValue: number; startValue: number }[] }) => {
            const startValue = zoomData.batch[0]?.startValue;
            const endValue = zoomData.batch[0]?.endValue;
            const countData = data?.filteredQuery.distanceVersusFailureCount;
            const min = countData?.[startValue].distanceStart;
            const max = countData?.[endValue].distanceEnd;
            if (min && max) {
              setZoomRange({ min, max });
            }
          },
        }}
      />
    </BasicWidget>
  );
};
