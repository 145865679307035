import styled from "styled-components";

export const MuteWrapper = styled.div`
  cursor: not-allowed;
  user-select: none;
  position: relative;
  .mute-overlay {
    position: absolute;
    border-radius: 0.75rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.blueGray};
    opacity: 0.5;
    background-image: linear-gradient(
      -45deg,
      #00000019 25%,
      transparent 25%,
      transparent 50%,
      #00000019 50%,
      #00000019 75%,
      transparent 75%,
      transparent
    );
    background-size: 4rem 4rem; /* Adjust size to make bars thinner or thicker */
    z-index: 99;
    animation: animatedMutingBars 5s linear infinite; /* Add animation */
    .echarts-for-react,
    svg,
    canvas,
    button,
    a {
      pointer-events: none;
    }
  }
  &.with-padding {
    padding: 0 1.5rem;
  }
`;
