import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { AnomalyDetectorEditorPage } from "@/features/anomaly/editor/AnomalyDetectorEditorPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/anomaly-detector/$detectorId/edit")({
  component: () => {
    return (
      <ProtectedRoute featureId={FeatureId.Anomalies}>
        <AppMainLayout>
          <AnomalyDetectorEditorPage />
        </AppMainLayout>
      </ProtectedRoute>
    );
  },
  beforeLoad: () => setTitleBeforeLoad("Edit Detector"),
});
