import { createFileRoute } from "@tanstack/react-router";

import ForecastHome from "@/features/forecast/ForecastHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/forecast/")({
  component: () => (
    <AppMainLayout>
      <ForecastHome />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Forecasts"),
});
