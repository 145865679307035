import styled from "styled-components";

export const AnomalyVehicleListContainer = styled.div`
  .anomaly-occurrences-paginator {
    text-align: center;
    padding-bottom: 20px;
  }
`;

export const AnomalyDetectorTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.simpleGray};
  .title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const AnomalyDetectorPatternDescription = styled.div`
  display: flex;
  a {
    margin-left: 5px;
  }
`;
