import styled from "styled-components";

export const PatternFocusedNodeContainer = styled.div`
  outline: none;
  padding: 0.5rem;
  &.node-is-focused {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colors.hoverRow};
    margin-right: -1px;
    animation: borderAsCursor 1s step-end infinite;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @keyframes borderAsCursor {
    0%,
    100% {
      border-right: 1px solid ${({ theme }) => theme.colors.blueGray};
      border-radius: 0.5rem 0 0 0.5rem;
    }
    50% {
      border-right-color: transparent;
      border-radius: 0.5rem;
    }
  }
`;
