import { Link } from "@tanstack/react-router";
import { ColumnType } from "antd/lib/table";
import { useContext, useMemo } from "react";

import { FeatureId, VisitedVehicle } from "@/api";
import { useHomeRecentVehicleDetailsQuery, Vehicle } from "@/api/customerApi";
import FriendlyCountryLabel from "@/components/ui/FriendlyCountryLabel";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import { BasicTable } from "./BasicTable";
import { compareNumber, compareStrTimestamps, compareText } from "./sorting";

type RecentlyVisitedVehicle = Vehicle & { timestamp: string };

interface VehicleTableProps {
  data?: VisitedVehicle[];
  isLoading: boolean;
}

// TODO sorting for last view
export default function VehicleTable({ data, isLoading: homeLoading }: VehicleTableProps) {
  const { customerIdentifier } = useCustomerIdentifier();
  const { globalFilter } = useContext(GlobalFilterContext);
  const { sortersInput, onChange } = useTablePagination<RecentlyVisitedVehicle>();
  const features = useFeatureFlags();
  const { isLoading, data: vehiclesData } = useHomeRecentVehicleDetailsQuery(
    {
      ids: data?.map((visited) => visited.vehicleId) || [],
      filter: globalFilter,
    },
    {
      staleTime: QUERY_SETTINGS.SHORT_STALE_TIME,
    }
  );

  const vehicles = useMemo(() => {
    if (vehiclesData) {
      return vehiclesData?.filteredQuery?.filteredVehicleDetails.map((vehicle) => ({
        ...vehicle,
        model: toModelDescription(vehicle, customerIdentifier.models),
        timestamp: data?.find((homeVisitedVehicle) => homeVisitedVehicle.vehicleId === vehicle.id)?.timestamp ?? "",
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesData, data, sortersInput]);

  const columns: ColumnType<RecentlyVisitedVehicle>[] = [
    {
      title: "Model Name",
      dataIndex: "model",
      sorter: (a, b) => compareText(a.model ?? "", b.model ?? ""),
      render: (_, vehicle) => vehicle.model,
      width: 192,
    },
    {
      title: "Year",
      sorter: (a, b) => compareNumber(a.year ?? 0, b.year ?? 0),
      render: (_, vehicle) => vehicle.year ?? "-",
    },
    {
      title: "Country",
      sorter: (a, b) => compareText(a.country ?? "", b.country ?? ""),
      render: (_, vehicle) => (vehicle.country ? <FriendlyCountryLabel countryCode={vehicle.country} /> : "-"),
    },
    {
      title: "Odometer (km)",
      dataIndex: "odometer",
      sorter: (a, b) => compareNumber(a.odometer ?? 0, b.odometer ?? 0),
      render: (odometer) => (odometer ? formatNumber(odometer) : "-"),
    },
    {
      title: features.isFeatureEnabled(FeatureId.Vins) ? "VIN" : "PVIN",
      width: 192,
      dataIndex: "id",
      sorter: (a, b) => compareText(a.vin ?? "", b.vin ?? ""),
      render: (_, vehicle) => (
        <Link to="/vehicle/$pvin" params={{ pvin: vehicle.id }}>
          {features.isFeatureEnabled(FeatureId.Vins) ? vehicle.vin : vehicle.id}
        </Link>
      ),
    },
    {
      title: "Last View",
      key: "timestamp",
      sorter: (a, b) => compareStrTimestamps(a.timestamp, b.timestamp),
      defaultSortOrder: "descend",
      render: (_, vehicle) => <FromNowFormatter value={new Date(vehicle.timestamp)} />,
    },
  ];

  return (
    <BasicTable
      onChange={onChange}
      columns={columns}
      dataSource={vehicles}
      rowKey={(vehicle) => vehicle.id}
      pagination={false}
      loading={homeLoading || isLoading}
    />
  );
}
