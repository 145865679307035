import { PropsWithChildren } from "react";

import { MuteWrapper } from "./MuteContent.styled";

type MuteContentProps = {
  withPadding?: boolean;
};

const MuteContent = ({ children, withPadding }: PropsWithChildren<MuteContentProps>) => {
  const wrapperclassName = `muted-content${withPadding ? " with-padding" : ""}`;

  return (
    <MuteWrapper className={wrapperclassName}>
      {children}
      <div className="mute-overlay" />
    </MuteWrapper>
  );
};

export default MuteContent;
