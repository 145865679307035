import { createFileRoute } from "@tanstack/react-router";

import RepairConceptHome from "@/features/repairConcept/RepairConceptHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/repair-concept/")({
  component: () => (
    <AppMainLayout>
      <RepairConceptHome />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Repair Concepts"),
});
