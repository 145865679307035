import { FeatureId } from "@/api";
import { EventType, RepairEventType } from "@/api/customerApi";
import { EventAnalyzerContainer } from "@/features/event/EventAnalyzer.styled";
import { useFeatureFlags } from "@/utils/features";

import { NewPatternButton } from "../anomaly/editor/NewPatternButton";
import EventAnalyzerStateWrapper from "./EventAnalyzerStateWrapper";

export type EventAnalyzerProps = {
  eventType: EventType;
  repairType?: RepairEventType;
};

export default function EventAnalyzer(props: EventAnalyzerProps) {
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <EventAnalyzerContainer>
      {isFeatureEnabled(FeatureId.Anomalies) && <NewPatternButton />}
      <EventAnalyzerStateWrapper {...props} />
    </EventAnalyzerContainer>
  );
}
