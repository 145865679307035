import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import AnomalyDetailsPage from "@/features/anomaly/details/AnomalyDetailsPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type AnomalyDetailSearch = {
  anomalyDetectionsPage?: number;
};

export const Route = createFileRoute("/anomaly-detector/$detectorId")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Anomalies}>
      <AppMainLayout>
        <AnomalyDetailsPage />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Anomaly Detector"),
  validateSearch: (search: Record<string, unknown>): AnomalyDetailSearch => {
    return {
      anomalyDetectionsPage: +(search.anomalyDetectionsPage as number) || undefined,
    };
  },
});
