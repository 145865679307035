import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import { FeatureId, TimeSeriesField, useTimeSeriesFieldsQuery } from "@/api";
import { SectionModal } from "@/components/sectionModal/SectionModal";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS, SCV_CHART } from "@/constants";
import { ChartColoringPoolProvider } from "@/contexts/ChartColoringPoolProvider";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import {
  getColoringPoolRecord,
  LibraryChart,
  selectActiveChartId,
  selectCharts,
  selectEssentialLibraryState,
  selectSeriesIds,
} from "@/contexts/ChartLibraryStore";
import LibraryActiveChart from "@/features/chartElementLibrary/LibraryActiveChart";
import LibraryChartPreviewList from "@/features/chartElementLibrary/LibraryChartPreviewList";
import useStateToExternalSource from "@/hooks/useStateToExternalSource";
import { Route } from "@/routes/vehicle.$pvin";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";

import { ChartLibraryContainer, ChartLibraryGeneralHeaderContainer } from "./ChartLibrary.styled";
import { mapSeriesFriendlyNames } from "./ChartLibraryBuildUtils";

const LibraryHeader = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleAddChart = () => {
    dispatch(ChartLibraryActions.createEmptyChart({ autoselect: true }));
  };

  return (
    <ChartLibraryGeneralHeaderContainer>
      <div className="container">
        <div className="title">
          <h2 className="heading-small">Vehicle History</h2>
          <Tooltip title="Displayed time-series data has been aggregated at 1-minute intervals" placement="topLeft">
            <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: "1rem" }} />
          </Tooltip>
        </div>
        <p className="body-medium">Unified vehicle data metrics</p>
      </div>
      <Button type="primary" onClick={handleAddChart}>
        Add New Graph
      </Button>
    </ChartLibraryGeneralHeaderContainer>
  );
};

const ChartLibrary = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { activeChartId } = useSelector(selectActiveChartId);
  const { charts: allCharts } = useSelector(selectCharts);

  const scvNavigate = Route.useNavigate();

  const handleChartLibraryStateNavigation = (serializedState: string) => {
    scvNavigate({ search: (prev) => ({ ...prev, chartLibrary: serializedState }), replace: true });
  };

  useStateToExternalSource({
    stateKey: SCV_CHART,
    selector: selectEssentialLibraryState,
    onStateChange: handleChartLibraryStateNavigation,
  });

  const dispatch = useDispatch();

  const usedSeriesIds = useSelector(selectSeriesIds);
  const statefulColoringPoolRecord = useSelector(getColoringPoolRecord);
  const statefullyUpdatePoolChange = (payload: { record: Record<string, string> }) => {
    dispatch(ChartLibraryActions.updateColoringPool(payload));
  };

  const { data: timeSeriesFieldsData } = useTimeSeriesFieldsQuery(
    { customerId: getCurrentCustomerId() ?? "" },
    { staleTime: QUERY_SETTINGS.LONG_STALE_TIME, enabled: isFeatureEnabled(FeatureId.TelemetryComponent) }
  );

  const availableFields: TimeSeriesField[] = timeSeriesFieldsData?.timeSeriesFields ?? [];

  const chartsWithFriendlyLabels: LibraryChart[] = allCharts.map((chart) => {
    return { ...chart, series: mapSeriesFriendlyNames(chart.series, availableFields) };
  });

  const activeChartIndex = chartsWithFriendlyLabels.findIndex((chart) => chart.chartId === activeChartId);
  const activeChart = activeChartIndex >= 0 ? chartsWithFriendlyLabels[activeChartIndex] : undefined;

  const containerClassname = `chart-library-container${activeChartId ? " active-chart" : ""}`;
  const containerId = "chart-library-container-id";

  return (
    <ChartColoringPoolProvider
      statefulColoringPoolRecord={statefulColoringPoolRecord}
      usedSeriesIds={usedSeriesIds}
      statefullyUpdatePoolChange={statefullyUpdatePoolChange}
    >
      <ChartLibraryContainer className={containerClassname} id={containerId}>
        <BasicWidget title={<LibraryHeader />} noHeaderStyling>
          <LibraryChartPreviewList charts={chartsWithFriendlyLabels} />
        </BasicWidget>
        {activeChart && (
          <SectionModal component={<LibraryActiveChart activeChart={activeChart} />} parentId={containerId} />
        )}
      </ChartLibraryContainer>
    </ChartColoringPoolProvider>
  );
};

export default ChartLibrary;
