import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import { AnomalySummaryTable } from "@/features/anomaly/list/AnomalySummaryTable";

import { NewPatternButton } from "../editor/NewPatternButton";

export default function AnomalyDetectorHome() {
  return (
    <>
      <TopHeadingWithButtons
        title="Anomaly Detectors"
        description="Detect unusual patterns or behaviors in your data with advanced anomaly detection."
        buttons={<NewPatternButton />}
      />
      <AnomalySummaryTable />
    </>
  );
}
