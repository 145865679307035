import { createFileRoute } from "@tanstack/react-router";

import VehicleHome from "@/features/vehicleDetails/VehicleHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/vehicle/")({
  component: () => (
    <AppMainLayout>
      <VehicleHome />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Vehicles"),
});
