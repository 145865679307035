import { UseQueryOptions } from "@tanstack/react-query";

import { FeatureId } from "@/api";
import {
  useVehicleTemperatureStatsQuery,
  VehicleTemperatureStatsQuery,
  VehicleTemperatureStatsQueryVariables,
} from "@/api/customerApi";
import { useFeatureFlags } from "@/utils/features";

export const useTelematicsProtectedVehicleTemperatureStatsQuery = (
  variables: VehicleTemperatureStatsQueryVariables,
  options?: UseQueryOptions<VehicleTemperatureStatsQuery>
) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const results = useVehicleTemperatureStatsQuery(variables, {
    ...options,
    enabled: isFeatureEnabled(FeatureId.TelemetryComponent) ? options?.enabled : false,
  });

  if (isFeatureEnabled(FeatureId.TelemetryComponent)) {
    return results;
  }

  return { data: fakeTempStats, isLoading: false, isError: false, error: undefined, isSuccess: true };
};

const fakeTempStats: VehicleTemperatureStatsQuery = {
  filteredQuery: {
    vehicleTemperatureStats: {
      vehicle: { p5: 21.74, p25: 45.6575, median: 69.025, p75: 92.3925, p95: 118.873 },
      fleet: {
        p5: 19.5125,
        p25: 41.75,
        median: 64.455,
        p75: 88.8225,
        p95: 122.305,
      },
    },
  },
};
