import EditIcon from "@amayaIcons/icon-edit.svg";
import TrashIcon from "@amayaIcons/icon-trash.svg";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Button, Card, Modal, notification, Popconfirm, Spin, Tooltip } from "antd";

import { useDeleteExpressionAnomalyMutation } from "@/api";
import { useAnomalySummaryQuery } from "@/api/customerApi";
import Loading from "@/components/loading";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import { PatternLink } from "@/components/pattern/PatternLink";
import AnomalyVehicleList from "@/features/anomaly/details/AnomalyVehicleList";
import { Route } from "@/routes/anomaly-detector.$detectorId";

import { useAnomalySummaryQueryParams } from "../hooks/useAnomalySummaryQueryparams";
import { AnomalyDetectorPatternDescription } from "./AnomalyVehicleListContainer.styled";
import { handleDeleteAnomaly } from "./handleDeleteAnomaly";

const AnomalyDetailsPage = () => {
  const [modal, contextHolder] = Modal.useModal();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { detectorId } = Route.useParams();
  //TODO: use something better
  const detectorIdTypeSpecific = detectorId?.startsWith("ml-") ? detectorId.substring(3) : detectorId;

  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const { filter, pagination } = useAnomalySummaryQueryParams();

  const { data, isFetching } = useAnomalySummaryQuery({
    filter: filter,
    pagination: pagination,
    id: detectorIdTypeSpecific,
  });

  const deleteAnomaly = useDeleteExpressionAnomalyMutation();

  const onDelete = () => {
    try {
      if (!anomalyData) return;
      const {
        detector: { id, version },
      } = anomalyData;
      handleDeleteAnomaly(modal, notificationApi, id, version, deleteAnomaly, navigate, queryClient);
    } catch (error) {
      notificationApi.error({ message: "Invalid Anomaly Detector" });
    }
  };

  if (isFetching)
    return (
      <Card className="card-pullsystems-shadow">
        <Loading height={256} />
      </Card>
    );

  const anomalyData = data?.filteredQuery.anomaliesWithVehicleCount?.items?.find(
    (a) => a.detector.id === detectorIdTypeSpecific
  );

  const expression = anomalyData?.detector.expression;
  const isDeletable = expression && anomalyData?.detector.creator?.isMe;
  const isEditIconShown = expression;

  const isDeleting = deleteAnomaly.isLoading;

  const buttons = (
    <>
      <Button
        type="text"
        onClick={() => navigate({ to: "/anomaly-detector", search: (prev) => ({ anomaliesPage: prev.anomaliesPage }) })}
      >
        Back to list
      </Button>
      {isDeletable && (
        <Popconfirm
          title="Are you sure you want to delete this detector?"
          okText="Yes"
          cancelText="No"
          onConfirm={onDelete}
        >
          <>
            <Tooltip title="Delete Detector">
              <Button icon={<TrashIcon />} type="text" disabled={isDeleting} />
            </Tooltip>
          </>
        </Popconfirm>
      )}
      {isEditIconShown && (
        <Tooltip title="Edit Detector Definition">
          <>
            <Button
              icon={<EditIcon />}
              type="text"
              onClick={() => navigate({ to: "/anomaly-detector/$detectorId/edit", params: { detectorId } })}
              disabled={isDeleting}
            />
          </>
        </Tooltip>
      )}
    </>
  );

  const description = expression ? (
    <div>
      <div>{anomalyData?.detector.description}</div>
      <AnomalyDetectorPatternDescription>
        <div>{"Pattern: "}</div>
        <PatternLink name={expression.name} id={expression.id} />
      </AnomalyDetectorPatternDescription>
    </div>
  ) : (
    anomalyData?.detector.description
  );

  return (
    <>
      {contextHolder}
      {notificationContextHolder}
      {isDeleting && <Spin fullscreen />}
      <TopHeadingWithButtons title={anomalyData?.detector.name} buttons={buttons} description={description} />
      <AnomalyVehicleList id={detectorId || ""} status={anomalyData?.detector.status} showStatus={!!expression} />
    </>
  );
};

export default AnomalyDetailsPage;
