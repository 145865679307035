import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { SavedExpressionHome } from "@/features/savedExpression/SavedExpressionHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type PatternsHomeSearch = {
  patternsPage?: number;
};

export const Route = createFileRoute("/pattern/")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Patterns}>
      <AppMainLayout>
        <SavedExpressionHome />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Patterns"),
  validateSearch: (search: Record<string, unknown>): PatternsHomeSearch => {
    return {
      patternsPage: +(search.patternsPage as number) || undefined,
    };
  },
});
