import { AutoComplete, Input } from "antd";
import styled from "styled-components";

export const StyledBorderlessTypeahead = styled(AutoComplete).attrs({
  variant: "borderless" /* switch to filled for better visual debug experience */,
  defaultActiveFirstOption: true,
})`
  min-width: 220px;
  max-width: 350px;
  width: 100%;
  &.ant-select-status-warning {
    margin-bottom: -2px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.warning};
  }
  &.ant-select-status-error {
    margin-bottom: -2px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.error};
  }
  &.aggregation-dropdown {
    min-width: 5rem;
    max-width: 6rem;
  }
  input {
    text-align: center;
  }
  .ant-select-selection-placeholder {
    text-align: center;
  }
`;

export const StyledBorderlessInput = styled(Input).attrs({ variant: "borderless" })`
  min-width: 220px;
  max-width: 350px;
  width: 100%;
  text-align: center;
  &.ant-select-status-warning {
    margin-bottom: -2px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.warning};
  }
  &.ant-select-status-error {
    margin-bottom: -2px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.error};
  }
  &.aggregation-input {
    min-width: 4rem;
    max-width: 5rem;
  }
  &.no-min-width {
    min-width: unset;
  }
  &:focus {
    background: ${({ theme }) => theme.colors.hoverRow};
  }
  .ant-select-selection-placeholder {
    text-align: center;
  }
`;
