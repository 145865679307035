import { Expression } from "@lib/src/expression/types";
import { useContext } from "react";
import { PartialDeep } from "type-fest";

import { useExpressionTreeBuilder } from "@/components/savedExpression/useExpressionTreeBuilder";
import { PatternFocusContext, PatternFocusProvider } from "@/contexts/PatternFocusContext";
import useRedoHotKey from "@/hooks/useRedoHotKey";
import useResetHotkey from "@/hooks/useResetHotkey";

import { PatternEditorContainer } from "./PatternEditor.styled";
import PatternNode from "./PatternNode";

type PatternEditorBoardProps = {
  disabled: boolean;
};

type PatternEditorCanvasProps = {
  node: PartialDeep<Expression>;
  onNodeChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  path: string[];
  disabled: boolean;
};

const PatternEditorCanvas = ({ node, onNodeChange, path, disabled }: PatternEditorCanvasProps) => {
  const { setFocusRootNode, setMostRecentPath } = useContext(PatternFocusContext);

  return (
    <PatternEditorContainer
      className={disabled ? "is-read-only" : ""}
      onClick={(e) => {
        if ((e.target as HTMLDivElement)?.className?.includes("editor-board-container")) {
          setFocusRootNode(true);
          setMostRecentPath([]);
        }
      }}
    >
      <div className="editor-board-container card">
        <PatternNode onNodeChange={onNodeChange} node={node} path={path} disabled={disabled} />
      </div>
    </PatternEditorContainer>
  );
};

const PatternEditorBoard = ({ disabled }: PatternEditorBoardProps) => {
  const { rootExpression, editExpression, undo, redo } = useExpressionTreeBuilder();

  useResetHotkey(undo);
  useRedoHotKey(redo);

  const handleNodeChange = (newValue: PartialDeep<Expression>, path: string[]) => {
    editExpression(newValue, path);
  };

  return (
    <PatternFocusProvider>
      <PatternEditorCanvas onNodeChange={handleNodeChange} node={rootExpression} path={[]} disabled={disabled} />
    </PatternFocusProvider>
  );
};

export default PatternEditorBoard;
