import { createFileRoute } from "@tanstack/react-router";

import VehicleDetails from "@/features/vehicleDetails/VehicleDetails";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type VehicleSearch = {
  chartLibrary?: string;
  faultHistoryPage?: number;
};

export const Route = createFileRoute("/vehicle/$pvin")({
  component: () => (
    <AppMainLayout>
      <VehicleDetails />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Vehicle Detail"),
  validateSearch: (search: Record<string, unknown>): VehicleSearch => ({
    chartLibrary: (search.chartLibrary as string) || undefined,
    faultHistoryPage: (search.faultHistoryPage as number) || undefined,
  }),
});
