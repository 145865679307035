import { TimeSeriesExpressionType } from "@lib/src/expression/types";

type TypeaheadOptionProps = { label: string; timeSeriesType?: TimeSeriesExpressionType };

const TypeaheadOption = ({ label, timeSeriesType }: TypeaheadOptionProps) => {
  const optionClassname = `typeahead-timeseries-option${timeSeriesType ? ` ${timeSeriesType.toLowerCase()}` : ""}`;

  return <span className={optionClassname}>{label}</span>;
};

export default TypeaheadOption;
