import { createRootRoute, Outlet, ScrollRestoration } from "@tanstack/react-router";
import { ThemeProvider } from "styled-components";

import RequireAuth from "@/components/auth/RequireAuth";
import SupportChatWidget from "@/components/support/SupportChatWidget";
import CustomerProvider from "@/contexts/CustomerProvider";
import { GlobalFilterProvider } from "@/contexts/GlobalFilterContext";
import { AntdConfigProvider } from "@/integrations/antd";
import Page404 from "@/pages/404";
import { defaultTheme } from "@/styles/Global.styled";

export const APP_TITLE = "Pull Workbench";

export const setTitleBeforeLoad = (title: string, withoutAppPrefix?: boolean) => {
  document.title = withoutAppPrefix ? title : `${APP_TITLE} - ${title}`;
};

const RootComponent = () => {
  return (
    <RequireAuth component={CustomerProvider}>
      <GlobalFilterProvider>
        <AntdConfigProvider>
          <ThemeProvider theme={defaultTheme}>
            <ScrollRestoration getKey={(location) => location.pathname} />
            <Outlet />
            <SupportChatWidget />
          </ThemeProvider>
        </AntdConfigProvider>
      </GlobalFilterProvider>
    </RequireAuth>
  );
};

export const Route = createRootRoute({
  component: RootComponent,
  notFoundComponent: Page404,
});
