import { CommonDataDictionary } from "../types/commonDataDictionary";

export const getFaultDescription = (
  customerIdentifier: CommonDataDictionary,
  code: string,
  ecu: string,
  troubleCode: string
) => {
  return customerIdentifier.faultCodes?.find(
    (faultCode) => faultCode.faultCode === code && ecu === faultCode.ecu && troubleCode === faultCode.troubleCode
  );
};
