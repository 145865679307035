import { TimeSeriesExpressionType, TimeSeriesFieldExpression } from "@lib/src/expression/types";

import { useTimeSeriesForTypeahead } from "./useTimeSeriesForTypeahead";

export const useFriendlyEntityLabel = (node: TimeSeriesFieldExpression) => {
  const { type, value } = node;
  const { availableSignals, availablePatterns, availableAnomalies } = useTimeSeriesForTypeahead();

  const getEntityName = (nodeType: TimeSeriesExpressionType, nodeValue: string) => {
    if (nodeType === TimeSeriesExpressionType.Signal) {
      const signal = availableSignals.find((s) => s.id === nodeValue);
      return signal?.displayName || nodeValue;
    } else if (nodeType === TimeSeriesExpressionType.Pattern) {
      const pattern = availablePatterns.find((p) => p.id === nodeValue || p.entityId === nodeValue);
      return pattern?.displayName || nodeValue;
    } else if (nodeType === TimeSeriesExpressionType.AnomalyScore) {
      const anomaly = availableAnomalies.find((a) => a.id === nodeValue || a.entityId === nodeValue);
      return anomaly?.displayName || nodeValue;
    }
  };

  return getEntityName(type, value);
};
