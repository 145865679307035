import styled from "styled-components";

export const NodeWrapperContainer = styled.section.attrs({ className: "node-wrapper" })`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* actual wrapping styling below */
  border-left: 2px solid;
  border-right: 2px solid;
  border-color: ${({ theme }) => theme.colors.mediumTeal};
  padding: 0.1rem 0.5rem;
  margin: 0.1rem 0.5rem;
  border-radius: 0.75rem;
  &:hover {
    border-left: 2.5px solid;
    border-right: 2.5px solid;
    border-color: ${({ theme }) => theme.colors.warning};
  }
`;
