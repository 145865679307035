import { createContext, ReactNode, useState } from "react";

interface PatternFocusContextProps {
  children: ReactNode;
}
interface PatternFocusContextType {
  mostRecentPath: string[] | undefined;
  setMostRecentPath: (s: string[] | undefined) => void;
  focusRootNode: boolean;
  setFocusRootNode: (f: boolean) => void;
}
export const PatternFocusContext = createContext<PatternFocusContextType>({
  mostRecentPath: undefined,
  setMostRecentPath: (_s: string[] | undefined) => {},
  focusRootNode: false,
  setFocusRootNode: (_f: boolean) => {},
});

export const PatternFocusProvider = ({ children }: PatternFocusContextProps) => {
  const [mostRecentPath, setMostRecentPath] = useState<string[]>();
  const [focusRootNode, setFocusRootNode] = useState(false);

  return (
    <PatternFocusContext.Provider value={{ mostRecentPath, setMostRecentPath, setFocusRootNode, focusRootNode }}>
      {children}
    </PatternFocusContext.Provider>
  );
};
