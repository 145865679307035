import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { Modal } from "antd";
import { ColumnType } from "antd/es/table";
import { SorterResult } from "antd/lib/table/interface";
import { camelCase, uniqBy } from "lodash";
import { useContext, useEffect, useState } from "react";

import { FeatureId } from "@/api";
import {
  MaintenanceLog,
  MaintenanceLogClusterMatch,
  MaintenanceLogsColumns,
  MaintenanceLogsInclusionsInput,
  Pagination,
  useMaintenanceLogsClusterMatchesQuery,
  useMaintenanceLogsColumnByUserInputQuery,
  useMaintenanceLogsQuery,
} from "@/api/customerApi";
import Loading from "@/components/loading";
import { ModalSubheading } from "@/components/modal/modalSubheading.styled";
import { BasicTable } from "@/components/tables/BasicTable";
import { TableFilterInclusions } from "@/components/tables/filter/TableFilterInclusions";
import { compareText } from "@/components/tables/sorting";
import { useRemoteFiltering } from "@/components/tables/useRemoteFiltering";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import i18next from "@/i18n";
import { parseDateToStr } from "@/utils/dateUtils";
import { useFeatureFlags } from "@/utils/features";
import { crcHashString } from "@/utils/stringUtils";
import { consolidateModelStyleOptions, spreadModelStyleOptions, toModelDescription } from "@/utils/vehicleModel";

import { ClusterMatchesTable } from "../cluster/clusterMatches/ClusterMatchesTable";

const fields = Object.values(MaintenanceLogsColumns);

export const MaintenanceLogs = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const areVinsEnabled = isFeatureEnabled(FeatureId.Vins);

  const [selectedClusterMatches, setSelectedClusterMatches] = useState<MaintenanceLogClusterMatch[]>();
  const { customerIdentifier } = useCustomerIdentifier();
  const {
    currentlySearching,
    setSearchedFilteringOptions,
    filteringOptions,
    handleFilterInputChangeUndebounced,
    filteringSelecteds,
  } = useRemoteFiltering({ fields });

  const [inclusions, setInclusions] = useState<MaintenanceLogsInclusionsInput>({
    ...(selectedColumnToFilteringInput(filteringSelecteds) as MaintenanceLogsInclusionsInput),
  });

  const { globalFilter } = useContext(GlobalFilterContext);

  const { sortersInput, onChange } = useTablePagination<Omit<MaintenanceLog, "id">>();

  const currentPage = useSearch({ strict: false, select: (search) => search.maintenanceLogsPage });
  const navigate = useNavigate();

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, maintenanceLogsPage: page }) });
  };

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const { data, isLoading } = useMaintenanceLogsQuery(
    { filter: globalFilter, pagination, sort: sortersInput, inclusions: inclusions },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const { data: currentFilteringOptionsData, isFetching: isFetchingFilteringOptions } =
    useMaintenanceLogsColumnByUserInputQuery(
      {
        filter: globalFilter,
        input: currentlySearching?.content ?? "",
        column: currentlySearching?.field as MaintenanceLogsColumns,
        inclusions,
      },
      { enabled: !!currentlySearching?.field }
    );

  const maintenanceLogIds: string[] = [...(data?.filteredQuery.maintenanceLogs.data ?? []).map((d) => d.id)].filter(
    (id): id is string => id !== null && id !== undefined
  );

  const { data: clusterMatches, isLoading: isClusterMatchesLoading } = useMaintenanceLogsClusterMatchesQuery(
    {
      filter: globalFilter,
      maintenanceLogIds: maintenanceLogIds,
    },
    { enabled: !isLoading }
  );

  useEffect(() => {
    if (!currentlySearching) {
      return;
    }
    const newOptions = (
      (currentFilteringOptionsData?.filteredQuery.maintenanceLogColumnByUserInput as (string | undefined)[]) ?? []
    ).map((option) => ({
      value: option,
      label: option ?? "-",
    }));
    setSearchedFilteringOptions({ field: currentlySearching.field, options: newOptions });
  }, [setSearchedFilteringOptions, currentlySearching, currentFilteringOptionsData]);

  const getFilteringOptions = (fieldName: MaintenanceLogsColumns) => {
    const availableOptions = filteringOptions[fieldName] as { value: string; label: string }[];
    const previouslyUnselectedValues = inclusions[camelCase(fieldName) as keyof MaintenanceLogsInclusionsInput] ?? [];
    const previouslyUnselectedOptions = previouslyUnselectedValues.map((value) => ({
      value: value,
      label: value || "-",
    }));
    const options = [...previouslyUnselectedOptions, ...availableOptions].sort((a, b) =>
      compareText(a.label ?? "-", b.label)
    );
    const uniqueOptions = uniqBy(options, "value");
    return uniqueOptions as { label: string; value: string | undefined }[];
  };

  const handleFilterDropdownOpenChangeFn = (fieldName: MaintenanceLogsColumns) => (open: boolean) => {
    if (open) {
      handleFilterInputChangeUndebounced(fieldName, "");
    }
  };

  const handleInclusionsApplied = (fieldName: MaintenanceLogsColumns, values: (string | undefined)[]) => {
    setInclusions({ ...inclusions, [camelCase(fieldName)]: values });
    navigate({ search: (prev) => ({ ...prev, maintenanceLogsPage: 1 }) });
  };

  const columns: ColumnType<MaintenanceLog>[] = [
    {
      key: "id",
      title: "Maintenance Log ID",
      dataIndex: "id",
      sorter: true,
      filtered: !!inclusions?.["id"]?.length,
      onCell: () => ({
        style: {
          maxWidth: "min-content",
          minWidth: "min-content",
        },
      }),
      render: (_v: string, row) => row.id ?? "-",
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.Id),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Maintenance Log ID"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.Id}
            options={getFilteringOptions(MaintenanceLogsColumns.Id)}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    areVinsEnabled
      ? {
          key: "vin",
          title: "VIN",
          dataIndex: "vin",
          sorter: true,
          filtered: !!inclusions?.["vin"]?.length,
          render: (_v, record) => (
            <Link to="/vehicle/$pvin" params={{ pvin: record.pvin ?? "" }}>
              {record.vin ?? "-"}
            </Link>
          ),
          onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.Vin),
          filterDropdown({ confirm }) {
            return (
              <TableFilterInclusions
                label={"VIN"}
                isLoading={isFetchingFilteringOptions}
                fieldName={MaintenanceLogsColumns.Vin}
                options={getFilteringOptions(MaintenanceLogsColumns.Vin)}
                onApplyInclusions={(fieldName, values) => {
                  handleInclusionsApplied(fieldName, values);
                  confirm();
                }}
              />
            );
          },
        }
      : {
          key: "pvin",
          title: "PVIN",
          dataIndex: "pvin",
          sorter: true,
          filtered: !!inclusions?.["pvin"]?.length,
          render: (_v, record) => (
            <Link to="/vehicle/$pvin" params={{ pvin: record.pvin ?? record.vin ?? "" }}>
              {record.pvin}
            </Link>
          ),
          onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.Pvin),
          filterDropdown({ confirm }) {
            return (
              <TableFilterInclusions
                label={"PVIN"}
                isLoading={isFetchingFilteringOptions}
                fieldName={MaintenanceLogsColumns.Pvin}
                options={getFilteringOptions(MaintenanceLogsColumns.Pvin)}
                onApplyInclusions={(fieldName, values) => {
                  handleInclusionsApplied(fieldName, values);
                  confirm();
                }}
              />
            );
          },
        },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
      sorter: true,
      filtered: !!inclusions?.["modelYear"]?.length,
      render: (_v: string, row) => row.modelYear ?? "-",
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ModelYear),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Model Year"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ModelYear}
            options={getFilteringOptions(MaintenanceLogsColumns.ModelYear)}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "modelStyle",
      title: "Model",
      dataIndex: "modelStyle",
      sorter: true,
      filtered: !!inclusions?.["modelStyle"]?.length,
      render: (_v: string, row) => {
        const md = toModelDescription({ modelStyle: row.model }, customerIdentifier.models);
        return md !== "undefined" ? md : "-";
      },
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ModelStyle),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Model"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ModelStyle}
            options={consolidateModelStyleOptions(
              getFilteringOptions(MaintenanceLogsColumns.ModelStyle),
              customerIdentifier.models
            )}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, spreadModelStyleOptions((values || []) as string[]));
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "diagnosticCheckId",
      title: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"),
      dataIndex: "diagnosticCheckId",
      sorter: true,
      render: (_v, row) => {
        return row.diagnosticCheckId ? row.diagnosticCheckId : "-";
      },
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.DiagnosticCheckId),
      filtered: !!inclusions?.["diagnosticCheckId"]?.length,
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.DiagnosticCheckId}
            options={getFilteringOptions(MaintenanceLogsColumns.DiagnosticCheckId).map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "startDate",
      title: "Repair Start Date",
      dataIndex: "repairStartDate",
      sorter: true,
      render: (_v, row) => (row.repairStartDate ? parseDateToStr(new Date(row.repairStartDate), false, true) : "-"),
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.MaintenanceStart),
      filtered: !!inclusions?.["maintenanceStart"]?.length,
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Repair Start Date"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.MaintenanceStart}
            options={getFilteringOptions(MaintenanceLogsColumns.MaintenanceStart).map((option) => ({
              value: option.value,
              label: option.value ? parseDateToStr(new Date(option.value), false, true) : "-",
            }))}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "endDate",
      title: "Repair End Date",
      dataIndex: "repairEndDate",
      sorter: true,
      filtered: !!inclusions?.["maintenanceEnd"]?.length,
      render: (_v, row) => (row.repairEndDate ? parseDateToStr(new Date(row.repairEndDate), false, true) : "-"),
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.MaintenanceEnd),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Repair End Date"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.MaintenanceEnd}
            options={getFilteringOptions(MaintenanceLogsColumns.MaintenanceEnd).map((option) => ({
              value: option.value,
              label: option.value ? parseDateToStr(new Date(option.value), false, true) : "-",
            }))}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "componentLocationId",
      title: `${i18next.t("columnTitle.COMPONENT_LOCATION_ID")}`,
      dataIndex: "componentLocationId",
      sorter: true,
      filtered: !!inclusions?.["componentLocationId"]?.length,
      render: (_value, row) => row.componentLocationId ?? "-",
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ComponentLocationId),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Failure Location"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ComponentLocationId}
            options={getFilteringOptions(MaintenanceLogsColumns.ComponentLocationId)}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "clusterId",
      title: "Cluster ID",
      dataIndex: "clusterId",
      sorter: true,
      filtered: !!inclusions?.["clusterId"]?.length,
      render: (_value, row) => row.clusterId ?? "-",
      onFilterDropdownOpenChange: handleFilterDropdownOpenChangeFn(MaintenanceLogsColumns.ClusterId),
      filterDropdown({ confirm }) {
        return (
          <TableFilterInclusions
            label={"Cluster Id"}
            isLoading={isFetchingFilteringOptions}
            fieldName={MaintenanceLogsColumns.ClusterId}
            options={getFilteringOptions(MaintenanceLogsColumns.ClusterId).map((c) => ({
              label: c.label,
              value: c.value,
            }))}
            onApplyInclusions={(fieldName, values) => {
              handleInclusionsApplied(fieldName, values);
              confirm();
            }}
          />
        );
      },
    },
    {
      key: "clusterMatches",
      title: "Cluster Matches",
      render: (_, row) => {
        if (isClusterMatchesLoading)
          return <Loading size={"small"} height={16} label="Finding Matches" logoPosition="left" aiLoading={true} />;
        const clusters =
          clusterMatches?.filteredQuery.maintenanceLogsClustertMatches.filter(
            (m) => m?.repairEventId === row.id && m.componentLocationId === row.componentLocationId
          ) ?? [];
        if (clusters.length < 1) return "No Matches";
        return (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setSelectedClusterMatches(clusters);
            }}
          >
            {clusters.length === 1 ? "1 Match" : `${clusters.length} Matches`}
          </Link>
        );
      },
    },
  ];

  return (
    <BasicWidget className="table" title="Maintenance Logs">
      <BasicTable
        tableLayout="auto"
        columns={columns.filter((c) => {
          if (isFeatureEnabled(FeatureId.AssignedMaintenanceLogs)) {
            return true;
          }
          return c.key !== "clusterId";
        })}
        dataSource={data?.filteredQuery.maintenanceLogs.data}
        loading={isLoading}
        rowKey={(r) => crcHashString(JSON.stringify(r))}
        onChange={(pagination, _filter, sorter) => {
          onChange(pagination, {}, sorter as SorterResult<Omit<MaintenanceLog, "id">>);
          handlePaginationChange(pagination.current || 1);
        }}
        pagination={{
          current: pagination.currentPage,
          total: data?.filteredQuery.maintenanceLogs.pagination?.totalCount,
          showSizeChanger: false,
        }}
      />
      <Modal
        open={!!selectedClusterMatches}
        onCancel={() => setSelectedClusterMatches(undefined)}
        footer={null}
        width={1000}
        title={<h4 className="heading-x-small">Cluster Recommendations</h4>}
      >
        <ModalSubheading>
          <p className="body-medium dark">
            Recommendations are generated based on factors such as failure components and fault codes, with matches
            indicating that the given workshop log resembles those in the target cluster.
          </p>
        </ModalSubheading>
        <ClusterMatchesTable clusters={selectedClusterMatches ?? []} />
      </Modal>
    </BasicWidget>
  );
};

function selectedColumnToFilteringInput(selection: Record<string, (string | number | undefined)[]>) {
  return Object.entries(selection).reduce((acc, [field, selecteds]) => ({ ...acc, [camelCase(field)]: selecteds }), {});
}
