import { createFileRoute } from "@tanstack/react-router";

import EditRepairConceptWrapper from "@/features/repairConcept/EditRepairConceptWrapper";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const Route = createFileRoute("/repair-concept/new")({
  component: () => (
    <AppMainLayout>
      <EditRepairConceptWrapper />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Repair Concepts"),
});
