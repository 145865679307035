import styled from "styled-components";

export const EntityLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.blueGray};
  &.overflows {
    padding: 0.5rem;
    display: initial;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.signal {
    color: #4290e1;
  }
  &.pattern {
    color: #922752;
  }
  &.anomalyscore {
    color: #6929c5;
  }
  svg {
    width: 1.5rem;
  }
`;
