import { useParams } from "@tanstack/react-router";
import { useState } from "react";

import { useEventAnalysisSearch } from "@/hooks/useEventAnalysisSearch";

/**
 *
 * Detects a change in the params if the event type is the same. Allows for the page to rerender
 * when clicking on links of the same event type in event analysis
 */

export const useEventAnalysisParams = () => {
  const { faultCodeId, patternEventId } = useParams({ strict: false });
  const search = useEventAnalysisSearch();

  const [params, setParams] = useState<Record<string, string | undefined | null>>({});

  const hasQueryParamsChanged = Object.entries(search).some(([key, value]) => params[key] !== value);

  if (patternEventId !== params.patternEventId || faultCodeId !== params.faultCodeId || hasQueryParamsChanged) {
    setParams((prevParams) => ({
      ...prevParams,
      ...search,
      faultCodeId,
      patternEventId,
    }));
  }

  return params;
};
