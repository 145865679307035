import ReactECharts, { EChartsInstance } from "echarts-for-react";
import { Dispatch, MutableRefObject, SetStateAction, useRef } from "react";

import { AnomalyDetectorType, SingleVehicleAnomalyDetector } from "@/api/customerApi";

import { ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "../../../common/anomalyColors";
import { buildAnomalyChartConfig } from "../../../common/buildAnomalyChartConfig";
import { handleAnomalyChartMouseOut } from "../../../common/handleAnomalyChartMouseOut";
import { handleAnomalyChartMouseOver } from "../../../common/handleAnomalyChartMouseOver";
import { Coord } from "../inputs/Coord";
import { StyledAnomalyPlotButton } from "../StyledAnomalyPlotButton";
import { get90DayHistory } from "../summary/get90DayHistory";
import { getLast90DaysIndexFromMostRecentDate } from "../summary/getLast90DaysIndex";
import { AnomalyHistoryHeader } from "./AnomalyHistoryHeader";
import { AnomalyHistoryKeyContainer } from "./AnomalyHistoryKeyContainer";

export type AnomalyHistoryChartProps = {
  onHandlePlotScore: (fieldId: string) => void;
  anomaly: SingleVehicleAnomalyDetector;
  childRef: MutableRefObject<
    | {
        setHoverCoord: Dispatch<SetStateAction<Coord | undefined>>;
      }
    | undefined
  >;
};

export const AnomalyHistoryChart = ({ onHandlePlotScore, anomaly, childRef }: AnomalyHistoryChartProps) => {
  const anomalyChartRef = useRef<EChartsInstance>();
  const setHoverCoord = childRef.current?.setHoverCoord;
  const ninetyDayHistory = get90DayHistory(anomaly, getLast90DaysIndexFromMostRecentDate(anomaly));
  const scoreHistoryChartOptions = buildAnomalyChartConfig(
    ninetyDayHistory,
    anomaly.detector.cautionThreshold,
    anomaly.detector.warningThreshold,
    false,
    setHoverCoord
  );

  // When the detector is a pattern based then timeSeriesFieldId is the fieldId of the pattern instead of the detector
  const detectorFieldId =
    anomaly.detector.type === AnomalyDetectorType.Ml ? anomaly.detector.timeSeriesFieldId : anomaly.detector.id;

  return (
    <div className="anomaly-history-container">
      <AnomalyHistoryHeader>
        <h2 className="heading-x-small">Last 90 Days Anomaly Score</h2>
        <StyledAnomalyPlotButton onClick={() => onHandlePlotScore(detectorFieldId)}>
          Plot full history
        </StyledAnomalyPlotButton>
      </AnomalyHistoryHeader>
      <div className="body-small">
        <AnomalyHistoryKeyContainer>
          <div>
            <span style={{ backgroundColor: ANOMALY_WARNING_COLOR }} />
            Score Outside Threshold
          </div>
          <div>
            <span style={{ backgroundColor: ANOMALY_SAFE_COLOR }} />
            Score In Threshold
          </div>
          <div>
            <span style={{ borderTop: "3px dashed #dedede" }} />
            Threshold
          </div>
        </AnomalyHistoryKeyContainer>
      </div>
      <ReactECharts
        className="anomaly-chart"
        {...scoreHistoryChartOptions}
        notMerge={true}
        onChartReady={(c) => (anomalyChartRef.current = c)}
        onEvents={{
          mousemove: (e: any) => {
            handleAnomalyChartMouseOver(e, ninetyDayHistory, anomaly, anomalyChartRef);
          },
          mouseout: () => handleAnomalyChartMouseOut(anomalyChartRef.current, setHoverCoord),
        }}
      />
    </div>
  );
};
