import { FeatureId } from "@/api";
import { useFeatureFlags } from "@/utils/features";

export function useVinLabel(): string {
  const { isFeatureEnabled } = useFeatureFlags();

  const isVinEnabled = isFeatureEnabled(FeatureId.Vins);

  return isVinEnabled ? "VIN" : "PVIN";
}
