import { Flex, SpinProps, Typography } from "antd";

import CustomerIcon from "../customerIcon/CustomerIcon";
import AiSparkle from "./../icons/AiSparkle.svg";
import { Container } from "./Loading.styled";

type LoadingProps = {
  size?: SpinProps["size"];
  label?: string;
  fullHeight?: boolean;
  height?: number;
  showLogo?: boolean;
  logoPosition?: "top" | "left";
  aiLoading?: boolean;
};

function Loading({
  fullHeight,
  label,
  height,
  logoPosition = "top",
  aiLoading = false,
  showLogo = true,
}: LoadingProps) {
  return (
    <Container
      className={fullHeight ? "full-height" : ""}
      height={height}
      justifycontent={logoPosition === "top" ? "center" : "start"}
    >
      <Flex className="loading-indicator" gap={4} vertical={logoPosition === "top"} justify="center" align="center">
        {showLogo && (
          <div className="loading-logo">
            {aiLoading ? <AiSparkle /> : <CustomerIcon size={32} alt="Pull Systems" />}
          </div>
        )}
        <Typography.Text className="loading-tip" type="secondary">
          {label || "Loading"}
        </Typography.Text>
      </Flex>
    </Container>
  );
}

export default Loading;
