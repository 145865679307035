import styled from "styled-components";

export const OptionGroupHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  .group-header-label {
    font-size: 0.8;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.backgroundHover};
  }
  .header-icon {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.backgroundHover};
  }
  &.signal {
    .group-header-label,
    .header-icon {
      color: #4290e1;
    }
  }
  &.pattern {
    .group-header-label,
    .header-icon {
      color: #922752;
    }
  }
  &.anomalyscore {
    .group-header-label,
    .header-icon {
      color: #6929c5;
    }
  }
`;
