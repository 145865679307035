import { Card, Col, Flex, Pagination, Radio, Row, Typography } from "antd";
import { PropsWithChildren, useState } from "react";

import { FeatureId } from "@/api";
import { AnomalySeverity, CalculatedStatus } from "@/api/customerApi";
import Loading from "@/components/loading/Loading";
import MuteContent from "@/components/ui/MuteContent";
import { QUERY_SETTINGS } from "@/constants";
import { useTelematicsProtectedSingleVehicleAnomalyDetectorsQuery } from "@/hooks/telematicsProtectedHooks/useTelematicsProtectedSingleVehicleAnomalyDetectorsQuery";
import { useFeatureFlags } from "@/utils/features";

import { defaultPagination } from "../../../../components/tables/defaultPagination";
import { AnomalyDetectorPreviewCards } from "../card/preview/AnomalyDetectorPreviewCards";
import { AnomalyDetectorSectionProps } from "./AnomalyDetectorSectionProps";

const defaultAnomalySeverity = AnomalySeverity.All;
const defaultAnomalyStatusToShow = CalculatedStatus.Available;

const flexSectionSettings = {
  vertical: true,
  gap: "1.5rem",
  style: {
    padding: "1.5rem 0",
  },
};

const ADSectionContainer = ({ children }: PropsWithChildren) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const telematicsDisabled = !isFeatureEnabled(FeatureId.TelemetryComponent);

  if (telematicsDisabled) {
    return (
      <MuteContent withPadding>
        <Flex {...flexSectionSettings}>{children}</Flex>
      </MuteContent>
    );
  }

  return <Flex {...flexSectionSettings}>{children}</Flex>;
};

export const AnomalyDetectorSection = ({ pvin, onAnomalySelected, onFieldSelected }: AnomalyDetectorSectionProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [pagination, setPagination] = useState(defaultPagination);
  const [severity, setSeverity] = useState(defaultAnomalySeverity);
  const status = defaultAnomalyStatusToShow;

  const { data, isLoading } = useTelematicsProtectedSingleVehicleAnomalyDetectorsQuery(
    {
      pagination,
      pvin,
      severity,
      status,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: isFeatureEnabled(FeatureId.TelemetryComponent) }
  );

  const severityCounts = data?.singleVehicleAnomalyDetectors.severityCounts;
  const paginationResult = data?.singleVehicleAnomalyDetectors.pagination;
  return (
    <ADSectionContainer>
      <Row justify="space-between" style={{ width: "100%" }} align="middle">
        <Col>
          <Typography.Title level={4}>Anomaly Detectors</Typography.Title>
        </Col>
        <Col>
          <Radio.Group
            defaultValue="a"
            buttonStyle="solid"
            value={severity || defaultAnomalySeverity}
            onChange={(e) => setSeverity(e.target.value)}
          >
            <Radio value={AnomalySeverity.All}>All ({severityCounts?.all ?? "-"})</Radio>
            <Radio value={AnomalySeverity.Warning}>Warning ({severityCounts?.warning ?? "-"})</Radio>
            <Radio value={AnomalySeverity.Caution}>Caution ({severityCounts?.caution ?? "-"})</Radio>
            <Radio value={AnomalySeverity.Normal}>Normal ({severityCounts?.normal ?? "-"})</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {isLoading ? (
        <Card className="card-pullsystems-shadow">
          <Loading height={256} />
        </Card>
      ) : (
        <Flex vertical gap={24} id="AnomalyCardsAndPagination-id">
          <AnomalyDetectorPreviewCards
            detectors={data?.singleVehicleAnomalyDetectors.detectors ?? []}
            pvin={pvin}
            onAnomalySelected={onAnomalySelected}
            onFieldSelected={onFieldSelected}
          />
          <Flex justify="center">
            <Pagination
              onChange={(currentPage, pageSize) => {
                setPagination({ currentPage, pageSize });
              }}
              current={paginationResult?.currentPage ?? defaultPagination.currentPage}
              pageSize={paginationResult?.pageSize ?? defaultPagination.pageSize}
              style={{ alignContent: "center", backgroundColor: "transparent" }}
              total={paginationResult?.totalCount}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              defaultPageSize={defaultPagination.pageSize}
              defaultCurrent={defaultPagination.currentPage}
              hideOnSinglePage
            />
          </Flex>
        </Flex>
      )}
    </ADSectionContainer>
  );
};
