import { useSearch } from "@tanstack/react-router";

export type FaultEventAnalysisSearch = {
  ecu?: string;
  troubleCode?: string;
  softwareVersion?: string;
  hardwareVersion?: string;
  softwarePartNumber?: string;
  hardwarePartNumber?: string;
  clusterId?: string;
};

export const useEventAnalysisSearch = (): FaultEventAnalysisSearch =>
  useSearch({
    strict: false,
    select: ({
      ecu,
      troubleCode,
      softwareVersion,
      hardwareVersion,
      softwarePartNumber,
      hardwarePartNumber,
      clusterId,
    }) => ({
      ecu,
      troubleCode,
      softwareVersion,
      hardwareVersion,
      softwarePartNumber,
      hardwarePartNumber,
      clusterId,
    }),
  });
