import { createFileRoute } from "@tanstack/react-router";

import { FeatureId } from "@/api";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import AnomalyDetectorHome from "@/features/anomaly/list/AnomalyDetectorHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type AnomalyHomeSearch = {
  anomaliesPage?: number;
};

export const Route = createFileRoute("/anomaly-detector/")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Anomalies}>
      <AppMainLayout>
        <AnomalyDetectorHome />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Anomaly Detectors"),
  validateSearch: (search: Record<string, unknown>): AnomalyHomeSearch => {
    return {
      anomaliesPage: +(search.anomaliesPage as number) || undefined,
    };
  },
});
