import { ErrorBoundary } from "@sentry/react";
import { Card } from "antd";
import { SetStateAction } from "react";

import { SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { ErrorFallback } from "@/components/error/ErrorFallBack";

import { AnomalyCardTitle } from "../../common/AnomalyCardTitle";
import { AnomalyDetectorExpanded } from "../expanded/AnomalyDetectorExpanded";
import { AnomalyModalContainer, AnomalyModalStyle } from "./AnomalyDetectorPreviewCards.styled";

type SelectedAnomalyProps = {
  anomalyHeight: number | undefined;
  onAnomalySelected: (anomaly: SingleVehicleAnomalyDetector) => void;
  onFieldSelected: (fieldId: string) => void;
  selectedAnomalyDetector: SingleVehicleAnomalyDetector;
  setSelectedAnomalyDetector: (value: SetStateAction<SingleVehicleAnomalyDetector | undefined>) => void;
};

export const SelectedAnomaly = ({
  selectedAnomalyDetector,
  setSelectedAnomalyDetector,
  onAnomalySelected,
  onFieldSelected,
  anomalyHeight,
}: SelectedAnomalyProps) => {
  return (
    <AnomalyModalContainer style={{ height: anomalyHeight }}>
      <AnomalyModalStyle onClick={() => setSelectedAnomalyDetector(undefined)}>
        <Card
          title={
            <AnomalyCardTitle anomaly={selectedAnomalyDetector} onClose={() => setSelectedAnomalyDetector(undefined)} />
          }
          onClick={(e) => e.stopPropagation()}
          className="ad-modal-card"
        >
          <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
            <AnomalyDetectorExpanded
              anomaly={selectedAnomalyDetector}
              onPlot={onAnomalySelected}
              onSignalSelected={onFieldSelected}
            />
          </ErrorBoundary>
        </Card>
      </AnomalyModalStyle>
    </AnomalyModalContainer>
  );
};
