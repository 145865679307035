import { useState } from "react";

import { TimeSeriesField, TimeSeriesFieldFamily } from "@/api";
import { AggregateType } from "@/api/customerApi";
import { compareText } from "@/components/tables/sorting";
import BlockingLoading from "@/components/ui/BlockLoading";
import useTimeSeriesOptions from "@/hooks/useTimeSeriesOptionsForCharting";

import { buildFieldChartLegend } from "./ChartLibraryBuildUtils";
import { PlottedSignalData } from "./ChartLibraryTabs";
import { plotterOptionsDraft } from "./FieldPlotterHelper";
import LibraryFieldPlotter from "./LibraryFieldPlotter";
import { LibraryItem } from "./LibraryItem";
import LibraryListWithSearch from "./LibraryListWithSearch";

export const searchLabelsMap = new Map<TimeSeriesFieldFamily, string>([
  [TimeSeriesFieldFamily.Signal, "Search for signals"],
  [TimeSeriesFieldFamily.Expression, "Search for expressions"],
  [TimeSeriesFieldFamily.AnomalyScore, "Search for anomalies"],
]);

interface LibrarySignalListSelectorProps {
  signalFamily?: TimeSeriesFieldFamily;
  onlyPlotted?: boolean;
  allowSearch?: boolean;
  plottedSignals: PlottedSignalData[];
  onPlotOptionsChange: (signalsData: PlottedSignalData[]) => void;
}

const LibrarySignalListSelector = ({
  signalFamily,
  onlyPlotted,
  allowSearch,
  plottedSignals,
  onPlotOptionsChange,
}: LibrarySignalListSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const searchPlaceholder = signalFamily ? searchLabelsMap.get(signalFamily) ?? "Search" : "Search";

  const { timeSeriesOptions: data, isLoading } = useTimeSeriesOptions({ typeFilter: signalFamily });

  const plottedIds = plottedSignals.map((s) => s.id);

  const availableFields = ((data || []) as TimeSeriesField[]).filter((field) =>
    signalFamily ? field.family === signalFamily : true
  );

  const plottableFields: TimeSeriesField[] = availableFields
    // Only allow fields that can be aggregated
    .filter((f) => f.aggregationTypes?.length)
    // onlyPlotted filtering
    .filter((f) => (onlyPlotted ? plottedIds.includes(f.id) : true))
    // searchTerm filtering
    .filter((f) => f.displayName?.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleFieldToggle = (active: boolean, field: TimeSeriesField, plottedData?: PlottedSignalData) => {
    const fieldPlotterOptionsDraft = plotterOptionsDraft.get(field.id);
    onPlotOptionsChange([
      {
        id: field.id,
        isPlotted: active,
        fieldInput: fieldPlotterOptionsDraft?.fieldInput ?? {
          id: field.id,
          aggregateType: field.aggregationTypes?.find((a) => a) ?? AggregateType.Last,
          family: field.family,
          entityId: field.entityId ?? undefined,
        },
        comparison: fieldPlotterOptionsDraft?.comparison ?? plottedData?.comparison,
        unit: field.unit ?? "",
      },
    ]);
    plotterOptionsDraft.delete(field.id);
  };

  if (isLoading) return <BlockingLoading className="line with-margin" />;

  return (
    <LibraryListWithSearch searchPlaceholder={searchPlaceholder} onSearch={allowSearch ? handleSearch : undefined}>
      {plottableFields
        .filter((field) => field.aggregationTypes)
        .sort((a, b) => compareText(a.displayName ?? "", b.displayName ?? ""))
        .map((field) => {
          const fieldName = buildFieldChartLegend({
            fieldId: field.id,
            displayName: field.displayName ?? "",
          });
          const plottedIndex = plottedSignals.findIndex((s) => s.id === field.id);
          const plottedData = plottedIndex >= 0 ? plottedSignals[plottedIndex] : undefined;
          return (
            <LibraryItem
              label={fieldName}
              key={field.id}
              fieldId={field.id}
              onToggle={(active) => handleFieldToggle(active, field, plottedData)}
              plottedIndex={plottedIndex}
              isSelected={!!plottedData}
            >
              <LibraryFieldPlotter
                fieldId={field.id}
                aggregateTypes={field.aggregationTypes!}
                plottedData={plottedData}
                family={field.family}
                onPlotOptionsChange={onPlotOptionsChange}
              />
            </LibraryItem>
          );
        })}
    </LibraryListWithSearch>
  );
};

export default LibrarySignalListSelector;
