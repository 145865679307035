import { Divider } from "antd";

import { ClusterRedshift, FeatureId } from "@/api";
import { useFeatureFlags } from "@/utils/features";

import { ClusterModalMissingPvins } from "../clusterHome/ClusterHome.styled";

export type MissingPvinsProps = {
  missingPvins?: string[];
  repeatedCombinations: ClusterRedshift[];
};

export const MissingPvins = ({ missingPvins, repeatedCombinations }: MissingPvinsProps) => {
  const { isFeatureEnabled } = useFeatureFlags();

  const isUsingVins = isFeatureEnabled(FeatureId.Vins);
  return (
    <>
      {missingPvins && !!missingPvins.length && (
        <div>
          <h2 className="heading-x-small error">
            {missingPvins.length} {isUsingVins ? "VINs" : "PVINs"} Not Found
          </h2>
          <Divider type="horizontal" />
          <ClusterModalMissingPvins>
            {missingPvins.map((pvin) => {
              return <div key={pvin}>{pvin}</div>;
            })}
          </ClusterModalMissingPvins>
        </div>
      )}
      {!!repeatedCombinations.length && (
        <div>
          <h2 className="heading-x-small error">{repeatedCombinations.length} Repeated combinations </h2>
          <Divider type="horizontal" />
          <ClusterModalMissingPvins>
            {repeatedCombinations.map(({ startDate, pvin, endDate }) => {
              const combination: string = `${pvin} - ${startDate}`;
              if (endDate) {
                combination + ` - ${endDate}`;
              }
              return <div key={combination}>{combination}</div>;
            })}
          </ClusterModalMissingPvins>
        </div>
      )}
    </>
  );
};
