import EditIcon from "@amayaIcons/icon-edit.svg";
import { DownOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { TimeSeriesFieldFamily, TimeSeriesIndependentVar } from "@/api";
import { PatternLink } from "@/components/pattern/PatternLink";
import DropdownWidget from "@/components/ui/DropdownWidget";
import { useChartColoringPool } from "@/contexts/ChartColoringPoolContext";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import {
  CustomChartSeries,
  selectFaultFilters,
  selectIndependentVariable,
  selectLibrarySpan,
} from "@/contexts/ChartLibraryStore";

import { getPlotColorByIndex } from "./ChartLibraryBuildUtils";
import {
  ChartSerieslegendContainer,
  ChartToolboxContainer,
  PlottedElementIcon,
  PlottedSignalIconContainer,
} from "./LibraryChartToolbox.styled";

const independentVars = [
  { key: TimeSeriesIndependentVar.Mileage, label: "Odometer" },
  { key: TimeSeriesIndependentVar.Time, label: "Timestamp" },
];

interface PlottedSignalIconProps {
  seriesIndex?: number;
  color?: string;
}

export const PlottedSignalIcon = ({ seriesIndex = 0, color }: PlottedSignalIconProps) => {
  const seriesColor = color || getPlotColorByIndex(seriesIndex);
  return (
    <PlottedSignalIconContainer>
      <div className="signal-icon" style={{ backgroundColor: seriesColor }}></div>
    </PlottedSignalIconContainer>
  );
};

interface ChartSerieslegendProps {
  plottedSeries: CustomChartSeries[];
}

export const ChartSerieslegend = ({ plottedSeries }: ChartSerieslegendProps) => {
  const coloringPoolCtx = useChartColoringPool();
  return (
    <ChartSerieslegendContainer>
      {plottedSeries.map((s) => {
        const name =
          s.TimeSeriesFieldInput.family === TimeSeriesFieldFamily.Expression && s.TimeSeriesFieldInput.entityId ? (
            <PatternLink name={s.name} id={s.TimeSeriesFieldInput.entityId} />
          ) : (
            s.name
          );
        return (
          // TODO element select (?)
          <div key={s.id} className="signal-element">
            <PlottedSignalIcon color={coloringPoolCtx?.acquireColor(s.id)} />
            <p className="signal-name body-x-small dark">{name}</p>
          </div>
        );
      })}
    </ChartSerieslegendContainer>
  );
};

interface ChartToolboxProps {
  series: CustomChartSeries[];
  toggleDrawer: () => void;
}

const LibraryChartToolbox = ({ series, toggleDrawer }: ChartToolboxProps) => {
  const dispatch = useDispatch();
  const librarySpan = useSelector(selectLibrarySpan);
  const { faultFilters } = useSelector(selectFaultFilters);
  const { independentVariable: currentVariable } = useSelector(selectIndependentVariable);

  const resetZoomEnabled = Object.values(librarySpan.rangeInput || {}).length > 0;

  const mainSeries = series.filter((s) => !(s as CustomChartSeries).comparisonSeries);

  const onZoomReset = () => {
    dispatch(ChartLibraryActions.updateLibrarySpan({}));
  };

  const handleVariableSelected = (selectedKey: string) => {
    const selectedVariable = independentVars.find((e) => e.key === selectedKey) || independentVars[0];
    dispatch(ChartLibraryActions.setIndependentVariable({ variable: selectedVariable.key }));
  };

  const handleToggleActiveChart = () => {
    dispatch(ChartLibraryActions.setActiveChart({ chartId: "" }));
  };

  const selectedVariableOption = independentVars.find((e) => e.key === currentVariable);

  return (
    <ChartToolboxContainer>
      <div className="plotted-elements-container">
        <ChartSerieslegend plottedSeries={mainSeries} />
        {!!faultFilters.length && (
          <div key="fault-legend" className="signal-element">
            <PlottedElementIcon className="fault-icon" />
            <p className="signal-name body-x-small dark">Faults</p>
          </div>
        )}
        <div key="repair-legend" className="signal-element">
          <PlottedElementIcon className="repair-icon" />
          <p className="signal-name body-x-small dark">Repairs</p>
        </div>
      </div>
      <div className="chart-tools-container">
        <Button type="text" className="text-button" size="small" onClick={onZoomReset} disabled={!resetZoomEnabled}>
          Reset Zoom
        </Button>
        <DropdownWidget
          elements={independentVars}
          onElementSelected={handleVariableSelected}
          label={
            <>
              {`X-Axis: ${selectedVariableOption?.label}${
                selectedVariableOption?.key === TimeSeriesIndependentVar.Mileage ? " (kms)" : ""
              }`}
            </>
          }
          buttonSize="small"
          icon={<DownOutlined />}
          preselectedKey={currentVariable || null}
        />
        <Button type="text" className="text-button" size="small" onClick={() => toggleDrawer()}>
          <EditIcon />
        </Button>
        <Button type="text" className="text-button" size="small" onClick={handleToggleActiveChart}>
          <ShrinkOutlined />
        </Button>
      </div>
    </ChartToolboxContainer>
  );
};

export default LibraryChartToolbox;
